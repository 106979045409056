<template>
  <div style="z-index: 4">
    <AiGenerator
      ref="aiGeneratorRef"
      :position-y="y"
      :position-x="x"
      absolute
      offset-y
      :feature="feature"
      :agent-name="agentName"
      :target-text-value="targetTextValue"
      :variables="variables"
      :force-chat-uid="elementToChatUid[currentElementId]"
      :dropdownState="dropdownMenuOpen"
      @promptClicked="trackEvent('ai-generator-prompt-clicked', {
        feature,
        title: $event.title,
      })"
      @onDropdownOpen="onDropdownOpen"
      @dropdownStateChange="dropDownStateChange"
      @startStream="startStream"
      @onStream="onStream"
      @endStream="endStream"
      @onChatInit="onChatInit"
    />
  </div>
</template>

<script>
import eventListenerService from '@/utils/eventCommunicationUtils/eventListenerService';
import outgoingPostMessageUtils from '@/utils/eventCommunicationUtils/outgoingPostMessageUtils';
import AiGenerator from '@/modules/aiContentGenerator/components/AiGenerator.vue';
import { mapMutations } from 'vuex';

export default {
  name: 'AiGeneratorAngularAdapter',
  components: {
    AiGenerator,
  },
  data() {
    return {
      // coordinates of the angular button location
      // this is where the AiGenerator dropdown will be positioned based on "position-x" and "position-y" props
      // along-side with the "absolute" props
      x: 0,
      y: 0,
      // elementId of the button that was clicked
      currentElementId: null,
      // // shouldClick is a flag that toggles the AiGenerator component.
      // // it simulates the AiGenerator activator button click event (but it's invisible in the angular singleton)
      // // there might be a better way to do this, but this is the best I could come up with now
      // shouldClick: false,
      // init data from angular component
      agentName: '',
      feature: '',
      targetTextValue: '',
      variables: {},
      // element to chat uid mapping
      elementToChatUid: {},
      dropdownMenuOpen: false,
      eventName: 'ai-generator-button-clicked',
    };
  },
  created() {
    // eslint-disable-next-line max-len
    eventListenerService.addListener(this.eventName, this.clickHandler);
    this.$router.beforeEach((to, from, next) => {
      // close the dropdown menu when navigating to a different route only if it's open
      // since the used overlay is shared among other components
      if (this.dropdownMenuOpen) {
        this.dropDownStateChange(false);
      }
      next();
    });
  },
  destroyed() {
    eventListenerService.removeListener(this.eventName, this.clickHandler);
  },
  methods: {
    ...mapMutations('AngularStore', ['setIsOverlayDisplay']),
    trackEvent(event, data) {
      this.$track(event, data);
    },
    dropDownStateChange(state) {
      this.dropdownMenuOpen = state;
      this.setIsOverlayDisplay(state);
    },
    onDropdownOpen(data) {
      this.trackEvent('ai-generator-button-clicked', {
        feature: data.feature,
        isInvalid: data.missingRequiredVariables?.length > 0,
      });
    },
    onChatInit(chatUid) {
      this.elementToChatUid[this.currentElementId] = chatUid;
    },
    startStream() {
      this.postMessage('startStream', true);
    },
    onStream(token) {
      this.postMessage('onStream', token);
    },
    endStream(msg) {
      this.postMessage('endStream', msg);
    },
    postMessage(event, data) {
      if (data) {
        outgoingPostMessageUtils.post('ai-generator', {
          type: event,
          elementId: this.currentElementId,
          value: data,
        });
      }
    },
    clickHandler(data) {
      if (data.event === 'ai-generator-button-clicked') {
        const {
          agent: agentName, feature, targetTextValue, variables,
        } = data.data.initData;
        this.agentName = agentName;
        this.feature = feature;
        this.targetTextValue = targetTextValue;
        this.variables = variables;

        this.currentElementId = data.data.elementId;

        // Positioning
        this.x = data.data.rect.left;
        this.y = data.data.rect.bottom + 8;

        this.dropDownStateChange(true);
      }
    },
  },
};
</script>

<style scoped lang="scss">

</style>
