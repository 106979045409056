import AiError from '@/modules/aiContentGenerator/utils/errors/aiError';

export default class AiStreamError extends AiError {
  constructor() {
    super({
      header: 'ai_content_generator.errors.stream_incomplete.header',
      text: 'ai_content_generator.errors.stream_incomplete.text',
    });
  }
}
