const setAttributesToElement = (element, id, attributesObject) => {
  element.setAttribute('id', id);
  if (Object.keys(attributesObject).length !== 0) {
    Object.keys(attributesObject).forEach((key) => {
      element.setAttribute(key, attributesObject[key]);
    });
  }
  return element;
};

const addScript = (id, script, attributesObject = {}) => {
  if (document.getElementById(id)) return;
  let element = document.createElement('script');
  element.innerHTML = script;
  element = setAttributesToElement(element, id, attributesObject);
  document.getElementsByTagName('head')[0].appendChild(element);
};

const addScriptWithSrc = (id, src, attributesObject = {}, onload = null) => {
  if (document.getElementById(id)) return;
  let element = document.createElement('script');
  element.src = src;
  if (onload) {
    element.onload = onload;
  }
  element = setAttributesToElement(element, id, attributesObject);
  document.getElementsByTagName('head')[0].appendChild(element);
};

export default {
  addScript,
  addScriptWithSrc,
};
