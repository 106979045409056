import formattingUtil from '@/utils/formattingUtil';

export const TAX_MODES = {
  include: 'include',
  exclude: 'exclude',
};

function calcPriceBreakdown(taxMode, priceAmount, taxes) {
  const price = Number(priceAmount);
  let netTotal = price;
  if (taxMode === TAX_MODES.include) {
    const taxTotalRate = taxes.reduce((acc, tax) => acc + tax.rate, 0);
    netTotal = price / (1 + taxTotalRate / 100);
  }

  taxes.forEach((tax) => {
    // eslint-disable-next-line no-param-reassign
    tax.tax_total = formattingUtil.roundNumber(netTotal * (tax.rate / 100));
  });

  const taxTotal = taxes.reduce((acc, tax) => acc + tax.tax_total, 0);

  let grandTotal = price;
  if (taxMode === TAX_MODES.exclude) {
    grandTotal = price + taxTotal;
  } else {
    netTotal = price - taxTotal;
  }

  return {
    displayPrice: price,
    netTotal: formattingUtil.roundNumber(netTotal),
    taxTotal: formattingUtil.roundNumber(taxTotal),
    grandTotal: formattingUtil.roundNumber(grandTotal),
  };
}

function getPriceByTaxMode(taxMode, netTotal, grandTotal) {
  if (taxMode === TAX_MODES.exclude) {
    return netTotal;
  }
  return grandTotal;
}

function calcPriceBreakdownWithPartialPayment(priceBreakdown, taxes, paidAmount, taxMode) {
  const paidBreakdown = calcPriceBreakdown(TAX_MODES.include, paidAmount, taxes);

  const breakdown = {
    netTotal: formattingUtil.roundNumber(priceBreakdown.netTotal - paidBreakdown.netTotal),
    taxTotal: formattingUtil.roundNumber(priceBreakdown.taxTotal - paidBreakdown.taxTotal),
    grandTotal: formattingUtil.roundNumber(priceBreakdown.grandTotal - paidBreakdown.grandTotal),
  };

  breakdown.displayPrice = getPriceByTaxMode(taxMode, breakdown.netTotal, breakdown.grandTotal);
  return breakdown;
}

function generateLocalID() {
  return Math.floor(Math.random() * (100000 - 1000 + 1)) + 1000;
}

export default {
  TAX_MODES,
  calcPriceBreakdown,
  getPriceByTaxMode,
  calcPriceBreakdownWithPartialPayment,
  generateLocalID,
};
