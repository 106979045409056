import clientPackageService from '@/modules/clientPackage/service/clientPackageService';

const state = {
  clientPackages: {},
};

const getters = {
  getClientPackageByUid: (state) => (uid) => state.clientPackages[uid],
};

const mutations = {
  setClientPackages(state, clientPackages) {
    clientPackages.forEach((clientPackage) => {
      state.clientPackages = { ...state.clientPackages, [clientPackage.id]: clientPackage };
    });
  },
};

const actions = {
  // eslint-disable-next-line no-shadow
  async fetchClientPackages({ commit, getters }, { uids, forceFetch = true }) {
    const clientPackagesToFetch = forceFetch ? uids : uids.filter((uid) => !getters.getClientPackageByUid(uid));
    if (clientPackagesToFetch.length) {
      const clientPackages = await Promise.all(
        clientPackagesToFetch.map((uid) => clientPackageService.fetchClientPackage(uid)),
      );
      commit('setClientPackages', clientPackages);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
