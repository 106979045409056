import rolesAndPermissionsService from '@/modules/rolesAndPermissions/services/rolesAndPermissionsService';

function mapPermissions(allPermissions) {
  const categories = {};
  allPermissions.forEach((permission) => {
    const categoryName = `${permission.unique_code.split('.')[0]}.manage`;
    if (!categories[categoryName]) {
      categories[categoryName] = { permissions: [] };
    }
    const isCategory = permission.unique_code === categoryName;
    if (isCategory) {
      categories[categoryName] = { ...categories[categoryName], ...permission };
    } else {
      categories[categoryName].permissions.push(permission);
    }
  });

  const categoriesArray = Object.values(categories);
  categoriesArray.sort((a, b) => a.position - b.position);
  return categoriesArray;
}

function mapBusinessRole(businessRole) {
  const mappedPermissions = businessRole.permissions.reduce((acc, { key, allow }) => {
    acc[key] = allow;
    return acc;
  }, {});
  return { ...businessRole, permissions: mappedPermissions };
}

const state = {
  businessRoles: [],
  serverBusinessRoles: [],
  businessRole: null,
  permissions: [],
  allStaffBusinessRoles: [],
  staffRole: null,
};

const getters = {
  businessRoles: (state) => state.businessRoles,
  serverBusinessRoles: (state) => state.serverBusinessRoles,
  permissions: (state) => state.permissions,
  allStaffBusinessRoles: (state) => state.allStaffBusinessRoles,
  businessRole: (state) => state.businessRole,
  staffRole: (state) => state.staffRole,
};

const mutations = {
  setBusinessRoles(state, businessRoles) {
    state.businessRoles = businessRoles;
  },
  setServerBusinessRoles(state, serverBusinessRoles) {
    state.serverBusinessRoles = serverBusinessRoles;
  },
  setPermissions(state, permissions) {
    state.permissions = permissions;
  },
  setBusinessRole(state, businessRole) {
    state.businessRole = businessRole;
  },
  setAllStaffBusinessRoles(state, allStaffBusinessRoles) {
    state.allStaffBusinessRoles = allStaffBusinessRoles;
  },
  setStaffRole(state, staffRole) {
    state.staffRole = staffRole;
  },
};

const actions = {
  async fetchBusinessRoles({ commit }) {
    const result = await rolesAndPermissionsService.getAllBusinessRoles();
    if (result.success) {
      commit('setServerBusinessRoles', result.data?.business_roles);
    }
  },
  async fetchAllStaffBusinessRoles({ commit }) {
    const result = await rolesAndPermissionsService.getAllStaffBusinessRoles();
    if (result.success) {
      commit('setAllStaffBusinessRoles', result.data?.staff_business_roles);
    }
  },
  async fetchFullBusinessRoles({ commit, dispatch, state }) {
    await dispatch('fetchBusinessRoles');
    await dispatch('fetchAllStaffBusinessRoles');

    const staffCountMap = {};

    state.allStaffBusinessRoles.forEach((staff) => {
      const roleUid = staff.business_role_uid;
      staffCountMap[roleUid] = (staffCountMap[roleUid] || 0) + 1;
    });

    let updatedBusinessRoles = state.serverBusinessRoles.map((role) => ({
      ...role,
      staff_count: staffCountMap[role.uid] || 0,
    }));

    // Move the non-editable items to the beginning of the list
    updatedBusinessRoles = updatedBusinessRoles.sort((a, b) => {
      if (a.is_editable === b.is_editable) {
        return a.name.localeCompare(b.name);
      }
      return a.is_editable ? 1 : -1;
    });

    commit('setBusinessRoles', updatedBusinessRoles);
  },
  async fetchBusinessRole({ commit, state }, roleUid) {
    let businessRole = state.businessRoles?.find((role) => role.uid === roleUid);
    if (!businessRole) {
      const result = await rolesAndPermissionsService.getBusinessRole(roleUid);
      if (result.success) {
        businessRole = result.data;
      } else {
        return { code: 'error' };
      }
    }
    const mappedBusinessRole = mapBusinessRole(businessRole);
    commit('setBusinessRole', mappedBusinessRole);
    return mappedBusinessRole;
  },
  async createNewBusinessRole({ state }, { name, description, baseRoleUid }) {
    const baseRole = state.businessRoles?.find((role) => role.uid === baseRoleUid);
    const permissions = baseRole?.permissions;
    const result = await rolesAndPermissionsService.createBusinessRole({ name, description, permissions });
    if (result.success) {
      state.businessRoles.push(result.data);
      return result.data;
    }
    return { code: 'error' };
  },
  async editBusinessRole({ commit, state }, {
    roleUid, name, description, permissions,
  }) {
    const result = await rolesAndPermissionsService.updateBusinessRole(roleUid, { name, description, permissions });
    if (result.success) {
      if (state.businessRole?.uid === roleUid) {
        const mappedBusinessRole = mapBusinessRole(result.data);
        commit('setBusinessRole', mappedBusinessRole);
      }
      return result.data;
    }
    return { code: 'error' };
  },
  async fetchAllPermissions({ commit }) {
    const result = await rolesAndPermissionsService.getAllPermissions();
    if (result.success) {
      commit('setPermissions', mapPermissions(result.data.permissions));
      return result.data;
    }
    return { code: 'error' };
  },
  async getStaffRole({ commit }, staffUid) {
    const result = await rolesAndPermissionsService.getStaffBusinessRole(staffUid);
    if (result.success) {
      commit('setStaffRole', result.data);
      return result.data;
    }
    return { code: 'error' };
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
