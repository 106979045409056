/**
 * Axios adapter to use the Fetch API
 * This is a simple adapter, it does not support all Axios features.
 * See also: https://github.com/axios/axios/issues/1219#issuecomment-2083232706
 * @param config
 * @returns {Promise<{headers: Headers, request: Request, data: Response, statusText: string, config, status: number}>}
 */
function fetchAdapter(config) {
  const url = new URL(config.url, config.baseURL);
  const headers = new Headers(config.headers);

  // Prepare the Fetch API options from the Axios config
  const toFetchConfig = {
    method: config.method.toUpperCase(),
    headers,
    body: config.data,
    // mode: 'cors', // optional, but can be useful to avoid CORS issues
    credentials: config.withCredentials ? 'include' : 'same-origin',
  };
  const request = new Request(url, toFetchConfig);

  // Execute the fetch request
  return fetch(url, toFetchConfig)
    .then(async (response) => {
      const axiosResponse = {
        data: response,
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        config,
        request,
        response,
      };

      // Imitating axios throw when the response status is not 2xx
      if (!response.ok) {
        axiosResponse.response.data = await response.json();
        return Promise.reject(axiosResponse);
      }
      return axiosResponse;
    });
}

export default fetchAdapter;
