const handleFavicon = (data) => {
  const favicon = document.getElementById('favicon');

  switch (data) {
    case 'development':
    case 'integration':
    case 'production':
      favicon.setAttribute('href', `${window.pov_config.VUE_APP_POV_ASSETS_URL}images/favicons/favicon_${data}.png`);
      break;
    case 'star':
      favicon.setAttribute('href', `${window.pov_config.VUE_APP_POV_ASSETS_URL}images/favicons/favicon_${data}.ico`);
      break;
    default:
      favicon.setAttribute('href', `${window.pov_config.VUE_APP_POV_ASSETS_URL}images/favicons/favicon_generic.ico`);
  }
};

export default {
  handleFavicon,
};
