import reserveWithGoogleService from '@/modules/reserveWithGoogle/service/reserveWithGoogleService';

const RGW_STATUS = {
  CONNECTED: 'redirect_connected',
  PAID_NOT_OPTED_IN: 'paid_not_opted_in',
  REDIRECT_NOT_CONNECTED: 'redirect_not_connected',
};

const state = {
  services: [],
  isRwgConnected: false,
  didFetchServices: false,
  rwgStatus: '',
  isOnlineSchedulingEnabled: false,
  googleBusinessDetails: {
    name: '',
    address: '',
    phone: '',
    isLocalBusiness: false,
    didUpdateOnlineScheduling: false,
  },
  businessSettings: {
    name: '',
    address: '',
    phone: '',
  },
};

const getters = {
  googleBusinessDetails: (state) => state.googleBusinessDetails,
  services: (state) => state.services,
  isRwgConnected: (state) => state.isRwgConnected,
  isOnlineSchedulingEnabled: (state) => state.isOnlineSchedulingEnabled,
  didUpdateOnlineScheduling: (state) => state.didUpdateOnlineScheduling,
  rwgStatus: (state) => state.rwgStatus,
  didFetchServices: (state) => state.didFetchServices,
  isLocalBusiness: (state) => state.googleBusinessDetails.isLocalBusiness,
  businessSettings: (state) => state.businessSettings,
};

const mutations = {
  setGoogleBusinessDetails(state, businessDetails) {
    state.googleBusinessDetails = businessDetails;
  },
  setBusinessDetail(state, { value, detail }) {
    state.googleBusinessDetails[detail] = value;
  },
  setIsRwgConnected(state, isRwgConnected) {
    state.isRwgConnected = isRwgConnected;
  },
  setIsOnlineSchedulingEnabled(state, isOnlineSchedulingEnabled) {
    state.isOnlineSchedulingEnabled = isOnlineSchedulingEnabled;
  },
  setRwgStatus(state, rwgStatus) {
    state.rwgStatus = rwgStatus;
  },

  setDidUpdateOnlineScheduling(state, didUpdateOnlineScheduling) {
    state.didUpdateOnlineScheduling = didUpdateOnlineScheduling;
  },
  setBusinessSettings(state, businessSettings) {
    state.businessSettings = businessSettings;
  },
};

const actions = {
  async loadBusinessDetails({ commit }) {
    const data = await reserveWithGoogleService.getBusinessSettings();

    commit('setRwgStatus', data.rwg_status);
    commit('setIsRwgConnected', data.rwg_status === RGW_STATUS.CONNECTED);

    const rwgSettings = {
      name: data.google_redirect?.name,
      address: data.google_redirect?.address,
      phone: data.google_redirect?.phone,
      isLocalBusiness: data.local_business,
    };

    commit('setIsOnlineSchedulingEnabled', data.scheduling_enabled);
    commit('setGoogleBusinessDetails', rwgSettings);
  },
  updateBusinessDetail({ commit }, { value, detail }) {
    commit('setBusinessDetail', { value, detail });
  },
  async submitOptIn({ state, commit, dispatch }) {
    const params = {
      scheduling_enabled: true,
      redirect_business_name: state.googleBusinessDetails.name || state.businessSettings.name,
      redirect_business_address: state.googleBusinessDetails.address || state.businessSettings.address,
      redirect_business_phone: state.googleBusinessDetails.phone || state.businessSettings.phone,
    };

    const data = await reserveWithGoogleService.submitReserve(params);
    if (data.rwg_status === RGW_STATUS.CONNECTED) {
      commit('setRwgStatus', data.rwg_status);
      commit('setIsRwgConnected', true);
      commit('setIsOnlineSchedulingEnabled', true);
      commit('setGoogleBusinessDetails', params);
      dispatch('loadBusinessDetails');
      return 'success';
    }
    return 'error';
  },
  async optOut({ commit, dispatch }) {
    const params = {
      disable_rwg_redirect: true,
    };
    const data = await reserveWithGoogleService.submitReserve(params);
    if (data.rwg_status !== RGW_STATUS.CONNECTED) {
      commit('setRwgStatus', data.rwg_status);
      commit('setIsRwgConnected', false);
      dispatch('loadBusinessDetails');
      return 'success';
    }
    return 'error';
  },
  updateIsOnlineSchedulingEnabled({ commit }, value) {
    commit('setIsOnlineSchedulingEnabled', value);
    if (value) {
      commit('setDidUpdateOnlineScheduling', true);
    }
  },
  updateDidUpdateOnlineScheduling({ commit }, value) {
    commit('setDidUpdateOnlineScheduling', value);
  },
  setBusinessMetaInfo({ commit }, data) {
    commit('setBusinessSettings', data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
