import gatewayService from '@/modules/common/services/gatewayService';

const BASE_PATH = 'v3/license/business_carts';

const createCart = async (purchasablePriceUid) => gatewayService.post(
  `${BASE_PATH}`,
  { business_cart: { offering_uid: purchasablePriceUid } },
)
  .then((res) => res.data?.data);

const getCart = async (cartUId) => gatewayService.get(`${BASE_PATH}/${cartUId}`)
  .then((res) => res.data?.data);

export default {
  createCart,
  getCart,
};
