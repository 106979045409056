<template>
  <VcDropdown class="notification-pane-menu" dataQa="mobileBottomSheet"
              :value="isBottomSheetOpen"
              @input="onStatusChanged">
    <VcList
      :items="translatedItems"
      class="notification-pane-menu-list py-2"
      @select="onAction">
      <template #default="item">
        <VcIcon class="me-3" size="16">{{ item.icon }}</VcIcon>
        <span class="menu-item-text">{{ item.label }}</span>
      </template>
    </VcList>
  </VcDropdown>
</template>

<script>
/* eslint-disable */
import outgoingPostMessageUtils from '@/utils/eventCommunicationUtils/outgoingPostMessageUtils';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'MobileBottomSheet',
  methods: {
    ...mapActions('MobileBottomSheetStore', ['setBottomSheetState']),
    onAction(event) {
      const item = this.menuItems[event];
      this.handleAction(item);
    },
    handleAction(action) {
      switch (action.trigger) {
        case 'post':
          outgoingPostMessageUtils.post(action.payload.action, action.payload.data);
          break;
        case 'callback':
          action.payload(action);
          break;
        default:
          console.log('action not found');
      }
      this.setBottomSheetState(false);
    },
    onStatusChanged(status) {
      this.setBottomSheetState(status);
    },
  },
  computed: {
    ...mapGetters('MobileBottomSheetStore', ['menuItems', 'isBottomSheetOpen']),
    isOpen() {
      return this.isBottomSheetOpen;
    },
    translatedItems() {
      // eslint-disable-next-line localization/no-logic-in-translations
      return this.menuItems.map((item) => ({ ...item, label: this.$t(item.label) }));
    },
  },
};
</script>

<style lang="scss" scoped>

.notification-pane-menu-list {
  text-align: start;

  ::v-deep .vc-base-list-item.single {
    line-height:  var(--size-value4);
  }

  ::v-deep .menu-item-text {
    vertical-align: bottom;
    color: var(--gray-darken-5);
    font-size: 13px;
  }
}
</style>
