const routes = [
  {
    path: '/settings/paygateproxy',
    name: 'paygateproxy',
    component: () => import(/* webpackChunkName: "Purchase" */ './views/payGateProxy/PayGateProxy.vue'),
    props: (route) => {
      const res = { ...route.query, ...route.params };

      try {
        res.cart_items = JSON.parse(res.cart_items);
      } catch (e) {
        res.cart_items = [];
      }

      try {
        res.cartItemsToPurchase = JSON.parse(res.cartItemsToPurchase);
      } catch (e) {
        res.cartItemsToPurchase = [];
      }

      res.successUrl = res.successUrl || res.su;
      res.cancelUrl = res.cancelUrl || res.cu;
      res.isCancelFlow = res.isCancelFlow === 'true';

      return res;
    },
  },
];
export default routes;
