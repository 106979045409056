<template>
  <VcLayout column class="text-input-container">
    <VcTextArea
      class="text-area"
      v-model="text"
      :placeholder="$t('ai_content_generator.custom_instructions.placeholder')"
      auto-grow
      outlined
      solo
      :counter="1000"
      maxlength="1000"
      height="149"
      :rules="rules"
    ></VcTextArea>
    <VcButton
      class="generate-button"
      block
      @click="generate"
      :disabled="!text">
      {{ $t('ai_content_generator.custom_instructions.generate_button') }}
    </VcButton>
  </VcLayout>
</template>

<script>

function validateText(value) {
  return value && value.trim().length >= 2;
}

export default {
  name: 'CustomInstructions',
  data() {
    return {
      text: '',
      error: false,
      rules: [
        (v) => validateText(v) || this.$t('ai_content_generator.custom_instructions.error.min_text'),
      ],
    };
  },
  created() {
    this.addI18nModule('ai-content-generator-pov');
  },
  methods: {
    generate() {
      if (!validateText(this.text)) return;
      this.$emit('generate', this.text);
    },
  },
};
</script>

<style scoped>

.text-input-container {
  padding: 12px 16px;
  background: #FAFAFA;
  border-top: 1px solid #EDEDED;
}

.generate-button {
  margin-top: 12px;
}
</style>
