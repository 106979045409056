import appointmentService from '@/modules/appointment/service/appointmentService';

const state = {
  appointments: {},
};

const getters = {
  getAppointmentByUid: (state) => (uid) => state.appointments[uid],
};

const mutations = {
  setAppointments(state, appointments) {
    appointments.forEach((appointment) => {
      state.appointments = { ...state.appointments, [appointment.id]: appointment };
    });
  },
};

const actions = {
  // eslint-disable-next-line no-shadow
  async fetchAppointments({ commit, getters }, {
    uids, matterUid, clientUid, forceFetch = true,
  }) {
    const appointmentsToFetch = forceFetch ? uids : uids.filter((uid) => !getters.getAppointmentByUid(uid));
    if (appointmentsToFetch.length) {
      const appointments = await appointmentService.fetchAppointments(appointmentsToFetch, matterUid, clientUid);
      commit('setAppointments', appointments);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
