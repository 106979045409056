const state = {
  toastConfig: {
    value: false,
    type: 'success',
    bodyText: '',
    headerText: '',
    timeout: 3000,
  },
};

const mutations = {
  setToastConfig(state, toastConfig) {
    state.toastConfig = toastConfig;
  },
};

const getters = {
  toastConfig: (state) => state.toastConfig,
};

const actions = {
  setToastConfig({ commit }, toastConfig) {
    commit('setToastConfig', toastConfig);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
