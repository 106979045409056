const routes = [
  {
    path: '/clients',
    name: 'clients',
    component: () => import(/* webpackChunkName: "CrmPage" */ './views/CrmPage.vue'),
    props: { featureFlag: 'new_crm_page', angularRoute: 'app.clients' },
  },
];

export default routes;
