const get = (key) => {
  const readCookies = document.cookie;
  const splitReadCookie = readCookies.split('; ');
  for (let i = 0; i < splitReadCookie.length; i++) {
    const cook = splitReadCookie[i];
    const value = cook.split('=');
    if (value[0] === key) {
      return value[1];
    }
  }
  return false;
};

export default {
  get,
};
