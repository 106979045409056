import Vue from 'vue';
import devices from '@/plugins/devices';
import features from '@/plugins/features';
import authData from '@/plugins/authData';
import formatting from '@/plugins/formatting';

import '@/plugins/sentry';
import '@/plugins/vuetify';

devices.addToVueInstance(Vue);
features.addToVueInstance(Vue);
authData.addToVueInstance(Vue);
formatting.addToVueInstance(Vue);
