import LayoutConstants from '@/constansts/layoutConstants';
import outgoingPostMessageUtils from '@/utils/eventCommunicationUtils/outgoingPostMessageUtils';
import loggerService from '@/modules/common/services/loggerService';
import Cookies from 'js-cookie';
import LayoutUtils from '@/modules/layout/utils/layoutUtils';

class MenuItemsUtils {
  static fireNavigationAction(clickedItem) {
    const obj = { route: clickedItem.target, data: clickedItem.target_params };
    outgoingPostMessageUtils.post(LayoutConstants.NAVIGATE_TO, obj);
    outgoingPostMessageUtils.post(LayoutConstants.FIRE_ACTION, { event_name: 'pov-menu-item-clicked' });
  }

  static fireAction(clickedItem, hideBackground = false) {
    const obj = { event_name: 'fire-menu-action', data: clickedItem, hideBackground };
    outgoingPostMessageUtils.post(LayoutConstants.FIRE_ACTION, obj);
    outgoingPostMessageUtils.post(LayoutConstants.FIRE_ACTION, { event_name: 'pov-menu-item-clicked' });
  }

  static shouldShowMenuItemByFF(rootGetters, itemObject) {
    return (LayoutUtils.isPassingFFCriteria(rootGetters, itemObject.allowByFFs, true)
      && LayoutUtils.isPassingFFCriteria(rootGetters, itemObject.hideByFFs, false));
  }

  static categoryObjectShouldAppear(rootGetters, categoryObject, applicationMenuItems) {
    const doesContainSubItems = categoryObject.submenuItems?.length > 0 || categoryObject.name in applicationMenuItems;
    return doesContainSubItems && this.shouldShowMenuItemByFF(rootGetters, categoryObject);
  }

  static shouldShowItemByPlatform(currentPlatform, platformArray) {
    if (platformArray && platformArray.length > 0) {
      return platformArray.includes(currentPlatform);
    }
    return true;
  }

  static getCurrentPlatform(rootGetters) {
    if (rootGetters['CommonStore/isMobileApp']) return 'mobileApp';
    if (rootGetters['CommonStore/isMobile']) return 'mobileWeb';
    return 'desktop';
  }

  static filterSubItemsArray(rootGetters, categoryObject) {
    const currentPlatform = this.getCurrentPlatform(rootGetters);
    return categoryObject.submenuItems.filter((subItem) => (
      this.shouldShowMenuItemByFF(rootGetters, subItem)
      && LayoutUtils.shouldShowItemByPermission(rootGetters, subItem.allowByPermissions, true)
      && this.shouldShowItemByPlatform(currentPlatform, subItem.platform)
      && this.showSpecialMenuItems(rootGetters, subItem.name)));
  }

  static decorateSubItemsArr(filteredSubItemsArray) {
    if (filteredSubItemsArray.length > 0) {
      return filteredSubItemsArray.map(
        (subItem) => MenuItemsUtils.decorateSubItemObj(subItem),
      );
    }
    return [];
  }

  static decorateCategoryWithSubitems(rootGetters, categoryObject, subMenuItemArray) {
    const isBlocked = categoryObject.unlockByFF && rootGetters['BusinessStore/hasFeature'](categoryObject.unlockByFF);
    return {
      name: categoryObject.name,
      submenuItems: subMenuItemArray || [],
      badgeName: categoryObject.badgeName || '',
      externalLink: categoryObject.externalLink || '',
      icon: categoryObject.icon,
      rightIcon: categoryObject.rightIcon || '',
      translationKey: categoryObject.translationKey,
      isAlreadyTranslated: categoryObject.isAlreadyTranslated || false,
      blocked: isBlocked,
      badgeText: categoryObject.badgeTranslationKey,
    };
  }

  static decorateSubItemObj(subItem) {
    return {
      name: subItem.name,
      type: subItem.type || 'angular',
      translationKey: subItem.translationKey,
      label: subItem.label || '',
      target: subItem.target || '',
      target_params: subItem.target_params || '',
      action: subItem.action || '',
      rightIconAction: subItem.rightIconAction || '',
      dispatchAction: subItem.dispatchAction || '',
      badgeText: subItem.badgeTranslationKey,
      isAlreadyTranslated: subItem.isAlreadyTranslated || false,
      url_path: subItem.url_path,
      entity_path: subItem.entity_path || [],
    };
  }

  static showSpecialMenuItems(rootGetters, categoryName) {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const attributes = rootGetters['BusinessStore/attributes'];
    const directorySettings = rootGetters['BusinessStore/directorySettings'];
    const externalApps = rootGetters['MainMenuStore/externalApplications'];
    switch (categoryName) {
      case 'partner_dashboard':
        return (attributes.partner_admin || attributes.partner_operator);
      case 'website_editor':
        return ((attributes.dashboard_as_an_app && !attributes.partner_admin)
          && !this.insideIframe());
      case 'back_to_dashboard':
        return (directorySettings.external_backoffice_url);
      case 'support':
        return (directorySettings.custom_support_url || directorySettings.default_support_url);
      case 'app_market':
        return !isSafari;
      case LayoutConstants.MENU_DIRECTORY_WORK_AS:
        return Cookies.get(LayoutConstants.DIRECTORY_LOGIN_COOKIE);
      case LayoutConstants.RESERVE_WITH_GOOGLE:
        return (externalApps.findIndex((app) => app.app_code_name === LayoutConstants.GOOGLE_INTEGRATION_POINT) > 0);
      default:
        return true;
    }
  }

  static insideIframe() {
    return (window.location !== window.parent.location);
  }

  static updateMenuMatterTerm(rootGetters, categoryObject) {
    const matterTermTranslation = rootGetters['BusinessStore/matterTermTranslation'];
    const translationKey = matterTermTranslation?.navigation || LayoutConstants.BASE_MATTER_TERM_CAPITALIZED;
    return {
      ...categoryObject,
      translationKey,
      icon: rootGetters['BusinessStore/matterTermIcon'],
      submenuItems: this.updateSubItemTerm(translationKey, categoryObject.submenuItems),
    };
  }

  static updateSubItemTerm(translationKey, subItemsArray) {
    return subItemsArray.map((subItem) => {
      if (subItem.name === LayoutConstants.BASE_MATTER_TERM) {
        return {
          ...subItem,
          translationKey,
        };
      }
      return subItem;
    });
  }

  static integrateApplicationsSubItems(applicationMenuItems, category, subItemsArray) {
    if (category in applicationMenuItems) {
      const appsSubItemsArray = applicationMenuItems[category];
      return subItemsArray.concat(appsSubItemsArray);
    }
    return subItemsArray;
  }

  static decorateApplicationMenuItem(appName, subItem, requiresUpgrade) {
    return {
      name: appName,
      translationKey: '',
      label: subItem.key,
      isAlreadyTranslated: true,
      target: LayoutConstants.APPLICATION_BASE_ROUTE,
      target_params: { app_name: appName, route: subItem.route },
      action: requiresUpgrade ? LayoutConstants.APPLICATION_UPGRADE_ACTION : '',
      url_path: `/app/embedded/${appName}/${subItem.route}`,
    };
  }

  static addCategoryToAppsObject(applicationsMenuItems, subItem) {
    // eslint-disable-next-line no-param-reassign
    applicationsMenuItems[subItem.category] = applicationsMenuItems[subItem.category] || [];
  }

  static setAppSubItemsByCategory(
    applicationData,
    applicationsMenuItemsAccumulator,
    appName,
    requiresUpgrade,
  ) {
    applicationData.menu_items.subitems.forEach((subItem) => {
      this.addCategoryToAppsObject(applicationsMenuItemsAccumulator, subItem);
      const applicationMenuItem = this.decorateApplicationMenuItem(appName, subItem, requiresUpgrade);
      applicationsMenuItemsAccumulator[subItem.category].push(applicationMenuItem);
    });
  }

  static serializeApplicationsMenuItems(applicationsInMenu) {
    const applicationsMenuItemsAccumulator = {};
    applicationsInMenu.forEach((applicationData) => {
      const appName = applicationData.app_code_name;
      const requiresUpgrade = applicationData.requires_upgrade;
      this.setAppSubItemsByCategory(applicationData, applicationsMenuItemsAccumulator, appName, requiresUpgrade);
    });
    return applicationsMenuItemsAccumulator;
  }

  static getReduceMenuItemsByCategoryFunction(state, rootGetters) {
    return (accumulator, categoryObject) => {
      try {
        if (MenuItemsUtils.categoryObjectShouldAppear(rootGetters, categoryObject, state.applicationsMenuItems)) {
          const subItemsArray = MenuItemsUtils.aggregateSubitemsArray(rootGetters, categoryObject, state);
          const decoratedSubItemsArr = MenuItemsUtils.decorateMenuItems(subItemsArray, categoryObject, rootGetters);
          MenuItemsUtils.addMenuItemsToAccumulator(decoratedSubItemsArr, accumulator, rootGetters, categoryObject);
        }
      } catch (error) {
        setTimeout(() => {
          loggerService.error('MAIN-MENU-REDUCE-MENU-ITEMS', { error, categoryObject });
        }, LayoutConstants.WAIT_TIME_FOR_ANGULAR_TO_LOAD);
      }
      return accumulator;
    };
  }

  static aggregateSubitemsArray(rootGetters, categoryObject, state) {
    const filteredSubItemsArray = MenuItemsUtils.filterSubItemsArray(rootGetters, categoryObject);
    return MenuItemsUtils.integrateApplicationsSubItems(state.applicationsMenuItems, categoryObject.name, filteredSubItemsArray);
  }

  static decorateMenuItems(subItemsArray, categoryObject, rootGetters) {
    const decoratedSubItemsArr = MenuItemsUtils.decorateSubItemsArr(subItemsArray);
    if (categoryObject.name === LayoutConstants.BASE_MATTER_TERM) {
      Object.assign(categoryObject, MenuItemsUtils.updateMenuMatterTerm(rootGetters, categoryObject));
    }
    return decoratedSubItemsArr;
  }

  static addMenuItemsToAccumulator(decoratedSubItemsArr, accumulator, rootGetters, categoryObject) {
    if (decoratedSubItemsArr.length > 0) {
      accumulator.push(MenuItemsUtils.decorateCategoryWithSubitems(rootGetters, categoryObject, decoratedSubItemsArr));
    }
  }

  static shouldShowProgressBar(progressData) {
    return (!!progressData.currentStep && !!progressData.totalSteps) && progressData.currentStep !== progressData.totalSteps;
  }
}

export default MenuItemsUtils;
