import httpService from '@/modules/common/services/httpService';

const basePlatformRoute = '/platform/v1/scheduling';

const fetchAppointments = async (uids, matterUid, clientUid) => httpService.get(`${basePlatformRoute}/appointments`, {
  uids: uids.join(','),
  matter_uid: matterUid,
  client_id: clientUid,
})
  .then((res) => res.data?.data?.appointments);

export default {
  fetchAppointments,
};
