import LayoutConstants from '@/constansts/layoutConstants';

const bottomNavItemsOverrides = () => [
  {
    idToOverride: 4,
    name: 'reports',
    label: 'common.navigation.reports',
    icon: 'icon-Reports_POV',
    actionType: LayoutConstants.BOTTOM_NAV_ACTION_TYPES.ANGULAR_ACTION,
    action: { event_name: 'go-to-reports-app-from-pov' },
    allowByFFs: ['external_reports_mobile_menu'],
    hideByFFs: [],
    allowByPermissions: [],
    hideByPermissions: [],
  },
  {
    idToOverride: 2,
    name: 'payments',
    label: 'common.navigation.sales',
    icon: 'icon-Payments_POV',
    actionType: LayoutConstants.BOTTOM_NAV_ACTION_TYPES.ANGULAR_NAVIGATION,
    action: 'app.payments.orders',
    allowByFFs: ['command_center', 'sales_menu_category'],
    hideByFFs: [],
    allowByPermissions: ['can_view_payments'],
    hideByPermissions: [],
  },
  {
    idToOverride: 2,
    name: 'payments',
    label: 'common.navigation.payments',
    icon: 'icon-Payments_POV',
    actionType: LayoutConstants.BOTTOM_NAV_ACTION_TYPES.ANGULAR_NAVIGATION,
    action: 'app.payments.orders',
    allowByFFs: ['command_center'],
    hideByFFs: ['sales_menu_category'],
    allowByPermissions: ['can_view_payments'],
    hideByPermissions: [],
  },
  {
    idToOverride: 2,
    name: 'notifications',
    label: 'common.navigation.notifications',
    icon: 'icon-bell',
    actionType: LayoutConstants.BOTTOM_NAV_ACTION_TYPES.ANGULAR_NAVIGATION,
    action: 'app.notifications',
    allowByFFs: ['command_center'],
    hideByFFs: [],
    allowByPermissions: [],
    hideByPermissions: ['can_view_payments'],
  },
];

export default bottomNavItemsOverrides();
