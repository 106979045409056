/* eslint-disable */
// Please remember to modify the frontage version as well
// they should be kept the same until it is deleted - mobileUtils.js
import store from '@/store';
import mobileAppService from './mobileAppService';

const didInitComplete = () => {
  const isInitComplete = store.getters['CommonStore/isInitComplete'];
  return isInitComplete;
};

const AppStates = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

const getInitStatus = () => (didInitComplete() ? 'Complete' : 'None');

let appName;
const getAppName = () => appName;
const getDeviceUUID = () => mobileAppService.getDeviceUUID();

const registerForPush = (deviceToken, deviceType, deviceUUID, appOwner, appVersion) => {
  // Save  app name for UI use
  appName = appOwner;

  mobileAppService.saveDeviceParams(
    deviceToken,
    deviceType,
    deviceUUID,
    appOwner.toLowerCase(),
    appVersion,
  );
  mobileAppService.registerDevice();
};

const deviceFunctions = {};

const registerCallbackFunction = (functionName, dataType) => {
  deviceFunctions[dataType] = functionName;
};

const featuresSupported = new Set();

// Call a function from inside the device
function executeFunctionByName(functionName, ...args) {
  const namespaces = functionName.split('.');
  const func = namespaces.pop();

  let context = window;
  for (let i = 0; i < namespaces.length; i++) {
    context = context[namespaces[i]];
  }

  const functionToCall = context[func];
  return functionToCall.call(context, ...args);
}

function setFeaturesAsSupported(featureName) {
  featuresSupported.add(featureName);
}

function isFeatureSupported(featureName) {
  return featuresSupported.has(featureName);
}

function appStateChangedTo(newState) {
  mobileAppService.emitAppStateChanged(newState);
}

// Called by Frontage when user clicks "import" button
let importContactsFunctionSuccess;
let importContactsFunctionError;
const importContacts = (onPhoneImportSuccess, onPhoneImportError, isMultiple) => {
  const importContactsDeviceFunction = deviceFunctions.contacts;
  if (importContactsDeviceFunction) {
    console.log(`Importing contacts via ${importContactsDeviceFunction}`);
    importContactsFunctionSuccess = onPhoneImportSuccess;
    importContactsFunctionError = onPhoneImportError;
    executeFunctionByName(importContactsDeviceFunction, isMultiple);
  }
};

// The device provides a function that can be called to receive data from the device,
// such as contacts from the device's contact list
/** @deprecated Apps should use registerCallbackFunction() directly, keep it here until apps are updated to use it */
const registerContactsImportFunction = (functionName) => {
  registerCallbackFunction(functionName, 'contacts');
};

// Called by the app in case of success
const contactsImportSuccess = (contactData) => {
  if (importContactsFunctionSuccess) {
    const angularIFrame = document.getElementById('angular-iframe').contentWindow;
    angularIFrame.importContactsFunctionSuccess = importContactsFunctionSuccess;
    angularIFrame.importContactsFunctionSuccess(JSON.parse(contactData));
  }
};

// Called by the app in case of error
const contactsImportError = (error) => {
  if (importContactsFunctionError) {
    const angularIFrame = document.getElementById('angular-iframe').contentWindow;
    angularIFrame.importContactsFunctionError = importContactsFunctionError;
    angularIFrame.importContactsFunctionError(error || {});
  }
};

const getWebViewSize = () => document.body.scrollHeight;

const setWebSizeAsFixed = () => {
  document.getElementsByTagName('body')[0].style.minHeight = `${getWebViewSize()}px`;
};

const FETCH_CONTACTS_MIN_INTERVAL = 60 * 10;
const FETCH_CONTACTS_MAX_CONTACTS = 1000;

let callerIdPermissionEnabled = false;
const setIsCallerIdPermissionEnabled = (isEnabled) => {
  callerIdPermissionEnabled = isEnabled;
};
const getIsCallerIdPermissionEnabled = () => callerIdPermissionEnabled;

const navigateToCallerIdPermissionPage = () => {
  if (deviceFunctions.contactsNavigateToPermissionPage) {
    executeFunctionByName(deviceFunctions.contactsNavigateToPermissionPage, true);
  }
};

const callerIdPermissionPopup = () => {
  if (deviceFunctions.callerIdPermissionPopup) {
    executeFunctionByName(deviceFunctions.callerIdPermissionPopup, true);
  }
};

function buildCallerDescription(client) {
  const clientDescription = [];

  if (client.first_name) {
    clientDescription.push(client.first_name);
  }

  if (client.last_name) {
    clientDescription.push(client.last_name);
  }

  return clientDescription.join(' ');
}

const contactsSaveData = (contacts) => {
  if (deviceFunctions.contactsSave) {
    console.log('saving contacts');
    console.log(contacts);
    executeFunctionByName(deviceFunctions.contactsSave, contacts);
  }

  if (deviceFunctions.contactsSaveAsJSON) {
    console.log('saving contacts. sent as JSON');
    console.log(contacts);
    executeFunctionByName(deviceFunctions.contactsSaveAsJSON, JSON.stringify(contacts));
  }
};

const contactsCleanupData = () => {
  if (deviceFunctions.contactsCleanup) {
    console.log(`cleaning contacts data via ${deviceFunctions.contactsCleanup}`);
    executeFunctionByName(deviceFunctions.contactsCleanup, true);
  }
};

function fetchCallerIdContacts(pageNumber, contacts) {
  mobileAppService.getClients(pageNumber).then((res) => {
    const clients = res?.data?.data.clients;
    console.log(`fetched ${clients?.length} clients`);
    console.log(clients);

    if (clients?.length > 0
      && Object.keys(contacts).length < FETCH_CONTACTS_MAX_CONTACTS) {
      // Parse clients
      clients.forEach((client) => {
        if (client.normalized_phone) {
          // eslint-disable-next-line no-param-reassign
          contacts[client.normalized_phone] = buildCallerDescription(client);
        }
      });

      // Fetch another page
      fetchCallerIdContacts(pageNumber + 1, contacts);
    } else {
      // We're done, save what we have
      contactsSaveData(contacts);
    }
  });
}

function getCallerIdData(lastFetchTime) {
  mobileAppService.getMobileSettings().then((res) => {
    const callerIdSettings = res?.data?.data.caller_id;
    if (callerIdSettings.enabled) {
      if (lastFetchTime > FETCH_CONTACTS_MIN_INTERVAL) {
        console.log('Fetching clients');
        const clients = {};
        fetchCallerIdContacts(1, clients);
      } else {
        return null;
      }
    } else {
      contactsCleanupData();
    }

    return true;
  });

  return true;
}

function getOverrideStartupUrl() {
  let overrideStartup = null;

  switch (window.location.hostname) {
    case 'emp.thryv.com':
      overrideStartup = 'https://emp.thryv.com/app';
      break;
    default:
      break;
  }

  return overrideStartup;
}

/** @deprecated Apps should use registerCallbackFunction() directly, keep it here until apps are updated to use it */
const registerContactsSaveFunction = (functionName) => {
  registerCallbackFunction(functionName, 'contactsSave');
};

/** @deprecated Apps should use registerCallbackFunction() directly, keep it here until apps are updated to use it */
const registerContactsCleanupFunction = (functionName) => {
  registerCallbackFunction(functionName, 'contactsCleanup');
};

/** @deprecated Apps should use registerCallbackFunction() directly, keep it here until apps are updated to use it */
const registerNavigateToCallerIdPermissionPageFunction = (functionName) => {
  registerCallbackFunction(functionName, 'contactsNavigateToPermissionPage');
};

const cleanupAppData = () => {
  contactsCleanupData();
};

const openInAppBrowser = (url) => {
  if (deviceFunctions.openInAppBrowser) {
    executeFunctionByName(deviceFunctions.openInAppBrowser, url);
  }
};

export default {
  getAppName,
  getDeviceUUID,
  didInitComplete,
  getInitStatus,
  registerForPush,
  setFeaturesAsSupported,
  isFeatureSupported,
  appStateChangedTo,
  AppStates,
  registerCallbackFunction,
  registerContactsImportFunction,
  registerNavigateToCallerIdPermissionPageFunction,
  registerContactsSaveFunction,
  registerContactsCleanupFunction,
  importContacts,
  contactsImportSuccess,
  contactsImportError,
  setWebSizeAsFixed,
  getWebViewSize,
  setIsCallerIdPermissionEnabled,
  getIsCallerIdPermissionEnabled,
  navigateToCallerIdPermissionPage,
  callerIdPermissionPopup,
  getCallerIdData,
  contactsSaveData,
  cleanupAppData,
  openInAppBrowser,
  getOverrideStartupUrl,
};
