import storageService from '@/modules/common/services/storageService';
import Vue from 'vue';

const handleLogout = async () => {
  const customLogout = Vue.prototype.getLogoutUrl();
  const isCustomLogoutFFEnabled = Vue.prototype.$hasFeature('enforce_custom_logout');
  storageService.remove('businessUid');
  storageService.remove('staffUid');
  storageService.remove('userProfile');
  // TODO - CHANGE THE URL BASE ON THE ACCOUNT'S DATA
  // AngularJS - auth.js.coffee:126
  const url = new URL(window.location.origin);
  if (window.pov_config.NODE_ENV === 'development') {
    url.port = '7200';
  }
  const isMobile = Vue.prototype.$isMobile();
  await fetch(`${url.origin}/app/logout`, { method: 'POST' });
  if (isMobile) {
    if (customLogout && isCustomLogoutFFEnabled) {
      window.location.replace(customLogout);
    } else {
      window.location.replace(`${url.origin}/app/logout`);
    }
  } else {
    // Send to the old login page - vcita.me
    window.location.replace(`${url.origin}/app/redirect/new?to=/logout`);
  }
};

export default {
  handleLogout,
};
