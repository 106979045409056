import { authService } from '@/modules/auth/services/authService';
import storageService from '@/modules/common/services/storageService';
import logoutUtil from '@/utils/logoutUtil';

const timestampInSec = () => Math.floor(Date.now() / 1000);

const state = {
  businessUid: null,
  staffUid: null,
  lastUserActivity: null,
  sessionInterval: 1000 * 60,
  sessionExpiration: null,
  sessionPrompt: null,
  sessionTimeout: null,
  sessionPromptVisible: false,
};

const getters = {
  businessUid: (state) => state.businessUid,
  staffUid: (state) => state.staffUid,
  isAuthenticated: (state) => !!state.businessUid && !!state.staffUid,
  isSessionPromptVisible: (state) => state.sessionPromptVisible,
};

const mutations = {
  setBusinessUid(state, businessUid) {
    state.businessUid = businessUid;
  },
  setStaffUid(state, staffUid) {
    state.staffUid = staffUid;
  },
  setUserActivity(state, lastUserActivity) {
    state.lastUserActivity = lastUserActivity;
  },
  setSessionExpiration(state, sessionExpiration) {
    state.sessionExpiration = sessionExpiration;
  },
  setSessionPrompt(state, sessionPrompt) {
    state.sessionPrompt = sessionPrompt;
  },
  setSessionPromptVisible(state, sessionPromptVisible) {
    state.sessionPromptVisible = sessionPromptVisible;
  },
  setSessionTimeout(state, setSessionTimeout) {
    clearTimeout(state.setSessionTimeout);
    state.setSessionTimeout = setSessionTimeout;
  },
};

const actions = {
  async initExtendSession({ rootGetters, dispatch, commit }) {
    if (!rootGetters['BusinessStore/hasFeature']('session_timeout')) return;
    //  Set optimistic initial values - Don't wait the server response
    commit('setSessionExpiration', timestampInSec() + (15 * 60));
    commit('setSessionPrompt', timestampInSec() + (13 * 60));
    commit('setUserActivity', timestampInSec());
    dispatch('setSessionInterval');
    dispatch('handleOtherTabsActivity');
  },
  handleOtherTabsActivity({ dispatch, commit }) {
    window.addEventListener('storage', (e) => {
      if (e.key === 'pov-exp') {
        //  Clear interval if another tab is active
        commit('setSessionTimeout', null);
        //  Start a new interval sync with another tab
        dispatch('setSessionInterval');
      }
    }, { passive: true });
  },
  async setSessionInterval({ dispatch, commit }, interval = state.sessionInterval) {
    const timeout = setTimeout(async () => {
      //  Sync with other tabs
      const storageExpiration = storageService.get('exp') || 0;
      const storagePrompt = storageService.get('prompt') || 0;
      const expiration = storageExpiration > state.sessionExpiration ? storageExpiration : state.sessionExpiration;
      const prompt = storagePrompt > state.sessionPrompt ? storagePrompt : state.sessionPrompt;
      if (expiration - timestampInSec() <= 0) {
        await dispatch('onLogout');
      } else if (prompt - timestampInSec() <= 0) {
        commit('setSessionPromptVisible', true);
        dispatch('setSessionInterval');
      } else if (state.lastUserActivity) {
        commit('setUserActivity', null);
        dispatch('extendSession');
        dispatch('setSessionInterval');
      } else {
        dispatch('setSessionInterval');
      }
    }, interval);
    commit('setSessionTimeout', timeout);
  },
  async extendSession({ commit }) {
    commit('setSessionPromptVisible', false);
    const res = await authService.extendSession();
    if (res?.success) {
      const expirationTime = res?.data?.expiration;
      const promptTime = res?.data?.prompt;
      storageService.save('exp', expirationTime);
      storageService.save('prompt', promptTime);
      commit('setSessionExpiration', expirationTime);
      commit('setSessionPrompt', promptTime);
    }
  },
  async getToken({ commit }) {
    const token = await authService.getToken();
    if (token?.success && token?.jwt) {
      commit('setStaffUid', token?.staffUid);
      commit('setBusinessUid', token.businessUid);
    }
  },
  async onLogout() {
    authService.clearAuthLocalData();
    logoutUtil.handleLogout();
  },
  async redirectToLogin() {
    authService.clearAuthLocalData();
    // We need to redirect to the Angular login page to keep the state
    const url = new URL(window.location.href);
    if (window.pov_config.NODE_ENV === 'development') {
      url.port = '7200';
    }
    url.searchParams.append('child_app', 'true');
    window.location.replace(url.href);
  },
  forceWLDomain({ rootGetters }) {
    console.log('trying to forme WL domain');
    const businessAppHost = rootGetters['BusinessStore/appHost'];
    if (!businessAppHost) return;
    console.log(`businessAppHost is ${businessAppHost}`);
    const currentHostName = window.location.hostname;
    if ((window.pov_config.NODE_ENV !== 'development') && (currentHostName !== businessAppHost)) {
      console.log(`Redirecting to ${window.location.pathname}`);
      window.location = `${window.location.protocol}//${currentHostName}/app/redirect/new?to=${window.location.pathname}`;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
