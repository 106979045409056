import httpService from '@/modules/common/services/httpService';

const baseRoute = '/platform/v1/services';

const DEFAULT_PAGE_SIZE = 25;
const DEFAULT_PAGE_INDEX = 0;

const fetchServices = (
  businessId,
  pageIndex = DEFAULT_PAGE_INDEX,
  pageSize = DEFAULT_PAGE_SIZE,
  filter = {},
) => httpService.get(baseRoute, {
  business_id: businessId,
  page: pageIndex,
  per_page: pageSize,
  order_by: 'name',
  ...filter,
}).then((res) => res.data?.data.services);

const fetchNoDiscussServices = (
  businessId,
  pageIndex = DEFAULT_PAGE_INDEX,
  pageSize = DEFAULT_PAGE_SIZE,
) => fetchServices(businessId, pageIndex, pageSize, {
  'charge_type[in]': 'paid_force,paid,paid_non_secured,free,no_price',
});

export default {
  fetchServices,
  fetchNoDiscussServices,
};
