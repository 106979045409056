import httpService from '@/modules/common/services/httpService';

const baseRoute = '/business/payments/v1/products';

const DEFAULT_PAGE_SIZE = 25;
const DEFAULT_PAGE_INDEX = 0;

const fetchProducts = (businessId, pageIndex = DEFAULT_PAGE_INDEX, pageSize = DEFAULT_PAGE_SIZE) => httpService.get(baseRoute, {
  business_id: businessId,
  page: pageIndex,
  per_page: pageSize,
}).then((res) => res.data?.data.products);

const createProduct = (params) => httpService.post(baseRoute, { ...params, new_api: true }).then((res) => res.data?.data.product);

export default {
  fetchProducts,
  createProduct,
};
