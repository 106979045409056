import purchasableService from '@/modules/purchasable/services/purchasableService';

const state = {
  purchasables: {},
};

const mutations = {
  setPurchasable(state, value) {
    state.purchasables = value;
  },
};

const actions = {
  async getPurchasable({ commit, state, rootGetters }, { type, uid }) {
    let accountCurrency = rootGetters['AccountStore/accountCurrency'];

    if (state.purchasables[type] && state.purchasables[type][uid]) {
      // The item has already been fetched
      return state.purchasables[type][uid][accountCurrency] || state.purchasables[type][uid].USD;
    }
    const purchasableValue = await purchasableService.fetchPurchasable(type, uid);
    commit('setPurchasable', { ...state.purchasables, [type]: { ...purchasableValue[type], [uid]: purchasableValue } });

    accountCurrency = accountCurrency || purchasableValue.USD.currency;
    return purchasableValue[accountCurrency];
  },
};

const getters = {
  purchasables: (state) => state.purchasables,
  // TODO: Do we need getters per type and id?
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
