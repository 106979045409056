import storageService from '@/modules/common/services/storageService';

const state = {
  isInitComplete: false,
  isMobile: false,
  isMobileApp: window.navigator.userAgent.match('vcitapp') !== null,
  isRtl: false,
};

storageService.save('isMobileApp', state.isMobileApp);

const getters = {
  isInitComplete: (state) => state.isInitComplete,
  isMobile: (state) => state.isMobile,
  isMobileApp: (state) => state.isMobileApp,
  isRtl: (state) => state.isRtl,
};

const mutations = {
  setIsInitComplete(state, isInitComplete) {
    state.isInitComplete = isInitComplete;
  },
  setIsMobile(state, isMobile) {
    state.isMobile = isMobile;
  },
  setIsRtl(state, isRtl) {
    state.isRtl = isRtl;
  },
};

const actions = {
  async fetchInitialData({ dispatch }) {
    await Promise.all([
      dispatch({ type: 'StaffStore/fetchPermissions' }, { root: true }),
      dispatch({ type: 'StaffStore/fetchStaffData' }, { root: true }),
    ]);
    dispatch({ type: 'MainMenuStore/aggregateMenuItemsForStaff' }, { root: true });

    // Please keep the external services call at the end of the init data flow
    function initScripts() {
      dispatch({ type: 'ExternalScriptsStore/initExternalScripts' }, { root: true });
    }
    setTimeout(initScripts, 0);
  },
  setIsInitComplete({ commit }, isInitComplete) {
    commit('setIsInitComplete', isInitComplete);
  },
  setIsMobile({ commit }, isMobile) {
    commit('setIsMobile', isMobile);
  },
  setIsRtl({ commit }, isRtl) {
    commit('setIsRtl', isRtl);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
