import calendarSyncService from '../services/calendarSyncService';

const state = {
  calendarSyncAppPermissions: {},
  calendarSyncApp: {},
  calendarSyncSettings: {},
  syncAccount: {},
};

const getters = {
  calendarSyncAppPermissions: (state) => state.calendarSyncAppPermissions,
  calendarSyncApp: (state) => state.calendarSyncApp,
  calendarSyncSettings: (state) => state.calendarSyncSettings,
  syncAccount: (state) => state.syncAccount,
};

const mutations = {
  setCalendarSyncAppPermissions(state, calendarSyncAppPermissions) {
    state.calendarSyncAppPermissions = calendarSyncAppPermissions;
  },
  setCalendarSyncApp(state, calendarSyncApp) {
    state.calendarSyncApp = calendarSyncApp;
  },
  updateCalendarSyncSettings(state, updateCalendarSyncResponse) {
    state.calendarSyncSettings = { ...state.calendarSyncSettings, ...updateCalendarSyncResponse };
  },
  setSyncAccount(state, syncAccount) {
    state.syncAccount = syncAccount;
  },
};

const actions = {
  async getCalendarSyncAppPermissions({ commit }, staffUid) {
    const calendarSyncAppPermissions = await calendarSyncService.getCalendarSyncAppPermissions(staffUid);
    commit('setCalendarSyncAppPermissions', calendarSyncAppPermissions);
    return calendarSyncAppPermissions;
  },
  // eslint-disable-next-line no-empty-pattern
  async getCalendarSyncApp({ }, businessUid) {
    const calendarSyncApp = await calendarSyncService.getCalendarSyncApp(businessUid);
    return calendarSyncApp;
  },
  async updateCalendarSyncSettings({ commit }, settingsObj) {
    const updateCalendarSyncResponse = await calendarSyncService.updateCalendarSyncSettings(settingsObj);
    commit('updateCalendarSyncSettings', updateCalendarSyncResponse);
    return updateCalendarSyncResponse;
  },
  async getSyncAccount({ commit }, { appUri, staffUid }) {
    const syncAccount = await calendarSyncService.getSyncAccount(appUri, staffUid);
    commit('setSyncAccount', syncAccount);
  },
  // eslint-disable-next-line no-empty-pattern
  async connectToSync({ }, {
    appUri,
    staffUid,
    businessUid,
    calendarName,
    shouldSyncAfterConnect,
    directoryName,
    appCodeName,
  }) {
    // eslint-disable-next-line max-len
    const connectToSyncResponse = await calendarSyncService.connectToSync(appUri, staffUid, businessUid, calendarName, shouldSyncAfterConnect, directoryName, appCodeName);
    return connectToSyncResponse;
  },
  // eslint-disable-next-line no-empty-pattern
  async createCalendarSyncRecord({ }, { staffUid, appCodeName }) {
    const calendarSyncRecord = await calendarSyncService.createCalendarSyncRecord(staffUid, appCodeName);
    // commit('setCalendarSyncRecord', calendarSyncRecord);
    return calendarSyncRecord;
  },
  // eslint-disable-next-line no-empty-pattern
  async disconnectFromCalendarApp({ }, {
    staffUid,
    appUri,
    exportedCalendarName,
    shouldKeep,
  }) {
    // eslint-disable-next-line max-len
    const disconnectFromCalendarAppResponse = await calendarSyncService.disconnectFromCalendarApp(staffUid, appUri, exportedCalendarName, shouldKeep);
    return disconnectFromCalendarAppResponse;
  },
  // eslint-disable-next-line no-empty-pattern
  async updateSyncSettings({ }, { appUri, accountUid, settingsObj }) {
    const syncSettings = await calendarSyncService.updateSyncSettings(appUri, accountUid, settingsObj);
    return syncSettings;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
