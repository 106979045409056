/* eslint-disable */
import store from '@/store';

const addToVueInstance = (Vue) => {
  Vue.prototype.$saveAuthData = function (businessUid, staffUid) {
    store.commit('AuthStore/setBusinessUid', businessUid);
    store.commit('AuthStore/setStaffUid', staffUid);
  };

  Vue.prototype.getLogoutUrl = function () {
    return store.getters['BusinessStore/getDirectoryLogoutUrl'];
  };
};

export default {
  addToVueInstance,
};
