import { conversationService } from '@/modules/conversation/services/conversationService';

const state = {
  counts: 0,
};

const mutations = {
  setCounts(state, value) {
    state.counts = value;
  },
};

const actions = {
  async fetchConversationCounts({ commit }) {
    const countsValue = await conversationService.getConversationsCounts();
    commit('setCounts', countsValue);
  },
};

const getters = {
  counts: (state) => state.counts,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
