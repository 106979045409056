import Vue from 'vue';
import viewService from '@/modules/crm/services/viewService';
import pick from 'lodash/pick';
import CrmConstants from '@/modules/crm/constants/crmConstants';
import clientService from '@/modules/client/services/clientService';

const state = {
  allViews: {},
  viewList: {
    pinned: [],
    unpinned: [],
  },
  tabIndex: 0,
  headers: [],
  selectedClients: {},
  filters: [],
  availableColumns: [],
  isCurrViewNewColumnsDecoration: false,
};

const helpers = {
  pinnedSort: (a, b) => (a.order && b.order ? a.order - b.order : 0),
  unpinnedSort: (a, b) => a.name.localeCompare(b.name),
  getViewFiltersObject: (view) => {
    const hasShowFiltersFF = Vue.prototype.$hasFeature('crm_show_filters');
    let filtersObject = {};
    if (view.filter) {
      const parsedFilters = JSON.parse(view.filter);
      const isFilterString = !Array.isArray(parsedFilters);
      if (hasShowFiltersFF) {
        filtersObject = isFilterString ? viewService.getNewDecorationFiltersFromFilterString(parsedFilters) : parsedFilters;
      } else {
        filtersObject = viewService.getSearchFiltersObject(parsedFilters);
      }
    }
    return filtersObject;
  },
  viewMap: (view) => ({ ...view, filtersObject: helpers.getViewFiltersObject(view) }),
  filterRelevantColumnsData: (columns) => columns.map((column) => ({
    label: column.label,
    type: column.type,
    identifier: column.identifier,
    sortable: column.isSortable,
  })),
  mergeAvailableColumns: (columns) => {
    const defaultFieldsArr = CrmConstants.CLIENT_LIST_HEADERS;
    const customFieldsTypesProps = CrmConstants.CUSTOM_FIELDS_TYPES_PROPERTIES_MAP;
    const allColumns = [...defaultFieldsArr, ...columns];
    const mergedColumns = allColumns.reduce((acc, currentColumn) => {
      const existingIndex = acc.findIndex((c) => c.identifier === currentColumn.identifier);
      if (existingIndex > -1) {
        acc[existingIndex] = {
          ...acc[existingIndex],
          ...currentColumn,
          isSortable: currentColumn.isSortable || currentColumn.sortable,
        };
      } else {
        const fieldTypeProps = customFieldsTypesProps[currentColumn.type];
        acc.push({
          ...fieldTypeProps,
          ...currentColumn,
          value: currentColumn.identifier,
          isSortable: currentColumn.isSortable || currentColumn.sortable,
        });
      }
      return acc;
    }, []);
    return mergedColumns;
  },
  formatColumnsForView: (columnsList, allAvailableColumns, isCurrViewNewColumnsDecoration) => {
    const identifierKey = isCurrViewNewColumnsDecoration ? 'identifier' : 'old_identifier';
    const newDecorationArray = [];
    columnsList.forEach((column) => {
      const columnIdentifier = isCurrViewNewColumnsDecoration ? column.identifier : column;
      const foundColumn = allAvailableColumns.find((item) => item[identifierKey] === columnIdentifier);
      if (foundColumn) {
        const mergedColumn = {
          ...foundColumn,
          ...column,
        };
        newDecorationArray.push(isCurrViewNewColumnsDecoration ? mergedColumn : foundColumn);
      } else {
        console.error(`Column with identifier ${columnIdentifier} not found`);
      }
    });
    return newDecorationArray;
  },
};

const getters = {
  viewList: (state) => state.viewList,
  currentTabView: (state) => state.viewList.pinned[state.tabIndex],
  currentViewFilter: (state) => (Vue.prototype.$hasFeature('crm_show_filters') ? viewService.getSearchFiltersObjectFromNewDecorationFiltersObject(state.viewList.pinned[state.tabIndex].filtersObject) : state.viewList.pinned[state.tabIndex].filtersObject),
  tabIndex: (state) => state.tabIndex,
  pinnedViews: (state) => state.viewList.pinned,
  unpinnedViews: (state) => state.viewList.unpinned,
  pinnedViewByIndex: (state) => (viewIndex) => state.viewList.pinned[viewIndex],
  headers: (state) => state.headers,
  unsavedViews: (state) => state.viewList.pinned.filter((view) => view.unsavedChanges).map((view) => view.name),
  selectedClients: (state) => (view) => state.selectedClients[view],
  selectedClientsCount: (state) => (view) => state.selectedClients[view] && Object.values(state.selectedClients[view]).length,
  groupedFilters: (state) => state.filters,
  ungroupedFilters: (state) => {
    const filters = [];

    state.filters.forEach((group) => {
      filters.push(...group.items.map((item) => ({ ...item, group: group.id })));
    });

    return Object.fromEntries(filters.map((filter) => [filter.id, filter]));
  },
  availableColumns: (state) => state.availableColumns,
  isCurrViewNewColumnsDecoration: (state) => state.isCurrViewNewColumnsDecoration,
};

const mutations = {
  setAllViews(state, views) {
    state.allViews = Object.fromEntries(views.map((view) => [view.uid, view]));
  },
  setViewList(state, viewsList) {
    state.viewList.pinned = viewsList.pinned;
    state.viewList.unpinned = viewsList.unpinned;
  },
  setTabIndex(state, index) {
    state.tabIndex = index;
  },
  setPinnedViews(state, views) {
    state.viewList.pinned = views;
  },
  setUnpinnedViews(state, views) {
    state.viewList.unpinned = views;
  },
  setHeaders(state, headers) {
    state.headers = headers;
  },
  setPinnedViewColumns(state, payload) {
    const { columns } = payload;
    Vue.set(state.viewList.pinned, state.tabIndex, {
      ...state.viewList.pinned[state.tabIndex],
      columns,
    });
  },
  setPinnedViewSort(state, payload) {
    const { sort } = payload;
    const updatedView = {
      ...state.viewList.pinned[state.tabIndex],
      sorting_column: sort.sortBy,
      sorting_direction: sort.sortOrder,
    };
    Vue.set(state.viewList.pinned, state.tabIndex, updatedView);
  },
  setPinnedViewFiltersObject(state, filtersObject) {
    Vue.set(state.viewList.pinned, state.tabIndex, {
      ...state.viewList.pinned[state.tabIndex],
      filtersObject,
      unsavedChanges: true,
    });
  },
  savePinnedView(state, payload) {
    const { view, index } = payload;
    state.allViews[view.uid] = view;
    Vue.set(state.viewList.pinned, index, view);
  },
  setPinnedViewCount(state, { index, count }) {
    Vue.set(state.viewList.pinned, index, {
      ...state.viewList.pinned[index],
      count,
    });
  },
  addNewView(state, view) {
    state.allViews[view.uid] = view;
  },
  pushNewView(state, uid) {
    state.viewList.pinned.push(state.allViews[uid]);
  },
  updateViewPinStatus(state, { uid, pinned }) {
    state.allViews[uid].pinned = pinned;
  },
  updateOrder(state, views) {
    views.forEach((view) => {
      state.allViews[view.uid].order = view.order;
      const pinnedView = state.viewList.pinned.find((v) => v.uid === view.uid);
      pinnedView.order = view.order;
    });

    state.viewList.pinned = state.viewList.pinned.sort(helpers.pinnedSort);
  },
  deleteView(state, uid) {
    Vue.delete(state.allViews, uid);
  },
  deletePinnedView(state, uid) {
    const index = state.viewList.pinned.findIndex((view) => view.uid === uid);
    if (index > -1) {
      Vue.delete(state.viewList.pinned, index);
    }
  },
  selectClient(state, client) {
    const view = getters.currentTabView(state).uid;

    state.selectedClients = {
      ...state.selectedClients,
      [view]: {
        ...state.selectedClients[view],
        [client.matter_uid]: client,
      },
    };
  },
  selectClients(state, clients) {
    const view = getters.currentTabView(state).uid;
    state.selectedClients = { ...state.selectedClients, [view]: clients };
  },
  deselectClient(state, client) {
    const view = getters.currentTabView(state).uid;
    const clients = JSON.parse(JSON.stringify(state.selectedClients[view]));
    delete clients[client.matter_uid];
    state.selectedClients = { ...state.selectedClients, [view]: clients };
  },
  deselectAll(state) {
    const view = getters.currentTabView(state).uid;
    state.selectedClients = { ...state.selectedClients, [view]: {} };
  },
  clearAllSelections(state) {
    state.selectedClients = {};
  },
  setFilters(state, filters) {
    state.filters = filters;
  },
  resetFilters(state) {
    state.filters = [];
  },
  setAvailableColumns(state, availableColumns) {
    state.availableColumns = availableColumns;
  },
  setIsCurrViewNewColumnsDecoration(state, isCurrViewNewColumnsDecoration) {
    state.isCurrViewNewColumnsDecoration = isCurrViewNewColumnsDecoration;
  },
};

const actions = {
  async fetchViews({ commit, dispatch, state }) {
    let allViews = await viewService.getViews();
    allViews = allViews.map(helpers.viewMap);
    commit('setAllViews', allViews);
    dispatch({ type: 'resetViews' });

    if (!state.viewList.pinned.length) {
      const index = state.viewList.unpinned.findIndex((view) => view.level === 'system' && view.filter === '{}');
      await dispatch({ type: 'pinView', index, order: 1 });
    }

    if (state.viewList.pinned.length > CrmConstants.MAX_OPEN_VIEWS) {
      const numberOfViewsToRemove = state.viewList.pinned.length - CrmConstants.MAX_OPEN_VIEWS;
      /* eslint-disable-next-line max-len */
      const viewsToUnpin = state.viewList.pinned.filter((view, index) => index >= CrmConstants.MAX_OPEN_VIEWS - numberOfViewsToRemove && index < CrmConstants.MAX_OPEN_VIEWS);
      await dispatch({ type: 'unpinViews', viewsToUnpin });
    }
  },
  async fetchViewsCounters({ commit, state, rootGetters }) {
    let clients;
    let filtersObject;
    const useNewDecoration = rootGetters['BusinessStore/hasFeature']('crm_custom_fields');
    try {
      await Promise.all(
        state.viewList.pinned.map(async (view, index) => {
          filtersObject = Vue.prototype.$hasFeature('crm_show_filters') ? viewService.getSearchFiltersObjectFromNewDecorationFiltersObject(view.filtersObject) : view.filtersObject;
          clients = await clientService.getClients(0, 0, '', '', '', filtersObject, useNewDecoration);
          commit('setPinnedViewCount', { index, count: clients.count });
        }),
      );
    } catch (e) {
      console.error(e);
    }
  },
  async fetchAvailableColumns({ commit }) {
    const res = {};
    try {
      const hasCustomFieldsFF = Vue.prototype.$hasFeature('crm_custom_fields');
      if (hasCustomFieldsFF) {
        const availableColumns = await viewService.getAvailableColumns();
        const mergedColumns = helpers.mergeAvailableColumns(availableColumns);
        commit('setAvailableColumns', mergedColumns);
      } else {
        const availableColumns = CrmConstants.CLIENT_LIST_HEADERS.map((column) => ({ ...column, value: column.old_identifier }));
        commit('setAvailableColumns', availableColumns);
      }
    } catch (e) {
      console.error(e);
      res.error = e;
    }
    return res;
  },
  setPinnedViewCount({ commit }, { index, count }) {
    commit('setPinnedViewCount', { index, count });
  },
  resetViews({ commit }) {
    const orderedViews = {
      pinned: Object.values(state.allViews).filter((view) => view.pinned).sort(helpers.pinnedSort),
      unpinned: Object.values(state.allViews).filter((view) => !view.pinned).sort(helpers.unpinnedSort),
    };

    commit('setViewList', orderedViews);
  },
  setTabIndex({ commit }, index) {
    commit('setTabIndex', index);
  },
  async createView({ state, commit }, payload) {
    const hasCustomFieldsFF = Vue.prototype.$hasFeature('crm_custom_fields');
    const hasShowFiltersFF = Vue.prototype.$hasFeature('crm_show_filters');
    const { pinned } = state.viewList;
    const viewToCopy = pinned.find((pinnedView) => pinnedView.uid === payload.copyFromUid);
    const viewTemplate = payload.copyFromUid ? viewToCopy : CrmConstants.DEFAULT_VIEW;
    let filteredColumns = viewTemplate.columns;
    let viewFilters = viewTemplate.filter;

    if (hasShowFiltersFF) {
      if (payload.copyFromUid) {
        viewFilters = JSON.stringify(viewTemplate.filtersObject);
      } else {
        viewFilters = '[]';
      }
    }

    if (hasCustomFieldsFF) {
      if (payload.copyFromUid) {
        filteredColumns = state.isCurrViewNewColumnsDecoration ? viewTemplate.columns
          : helpers.formatColumnsForView(viewTemplate.columns, state.availableColumns, false);
      } else {
        filteredColumns = helpers.formatColumnsForView(viewTemplate.columns, state.availableColumns, false);
      }
      filteredColumns = helpers.filterRelevantColumnsData(filteredColumns);
    }

    const view = {
      ...viewTemplate,
      columns: filteredColumns,
      filter: viewFilters,
      ...payload.view,
      view_type: 'client',
    };

    try {
      const { data } = await viewService.createView(view);
      const newView = { ...data, filtersObject: helpers.getViewFiltersObject(view) };
      commit('addNewView', newView);
      return newView.uid;
    } catch (e) {
      console.error(e);
    }

    return false;
  },
  pushNewView({ commit }, uid) {
    commit('pushNewView', uid);
  },
  async savePinnedView({ state, commit }) {
    const hasCustomFieldsFF = Vue.prototype.$hasFeature('crm_custom_fields');
    const hasShowFiltersFF = Vue.prototype.$hasFeature('crm_show_filters');
    let view = getters.currentTabView(state);
    const index = state.tabIndex;
    const { level, ...restView } = view;
    if (view.level === 'system') {
      view = restView;
    }

    try {
      const mappedColumns = hasCustomFieldsFF ? helpers.filterRelevantColumnsData(view.columns) : view.columns;
      const filter = hasShowFiltersFF ? JSON.stringify(view.filtersObject) : view.filter;
      const viewToSave = { ...view, columns: mappedColumns, filter };
      const { data } = await viewService.updateView(view.uid, viewToSave);
      commit('savePinnedView', { view: { ...helpers.viewMap(data), count: view.count }, index });
    } catch (e) {
      console.error(e);
    }
  },
  restorePinnedView({ state, commit }) {
    const index = state.tabIndex;
    const view = state.viewList.pinned[index];
    const viewCount = view.count;
    const restoredView = state.allViews[view.uid];
    commit('savePinnedView', { view: restoredView, index });
    commit('setPinnedViewCount', { index, count: viewCount });
  },
  async updatePinnedView({ commit }, payload) {
    const { view } = payload;
    const index = state.viewList.pinned.findIndex((item) => item.uid === view.uid);
    try {
      await viewService.updateView(view.uid, view);
      commit('savePinnedView', { view, index });
    } catch (e) {
      console.error(e);
    }
  },
  async reorderPinnedViews({ commit }, pinnedViews) {
    const views = pinnedViews.map((view, index) => ({ uid: view.uid, order: index + 1 }));
    const res = {};

    try {
      const { data } = await viewService.updateViews(views);
      const updatedViews = data.map(helpers.viewMap);
      commit('updateOrder', updatedViews);
    } catch (e) {
      console.error(e);
      res.error = e;
    }

    return res;
  },
  async setPinnedViewSort({ commit, state }, sort) {
    const view = state.viewList.pinned[state.tabIndex];
    try {
      await viewService.updateView(view.uid, { sorting_column: sort.sortBy, sorting_direction: sort.sortOrder, order: view.order });
    } catch (e) {
      console.error(e);
    }
    commit('setPinnedViewSort', { sort });
  },
  async setPinnedViewColumns({ state }) {
    const view = state.viewList.pinned[state.tabIndex];
    const hasCustomFieldsFF = Vue.prototype.$hasFeature('crm_custom_fields');
    const columns = hasCustomFieldsFF ? helpers.filterRelevantColumnsData(view.columns) : view.columns;
    try {
      await viewService.updateView(view.uid, { columns, order: view.order });
    } catch (e) {
      console.error(e);
    }
  },
  async unpinViews({ commit, dispatch }, payload) {
    const { viewsToUnpin } = payload;
    const views = viewsToUnpin.map((view) => ({ uid: view.uid, order: null }));

    try {
      await viewService.updateViews(views);
      views.forEach((view) => commit('updateViewPinStatus', { uid: view.uid, pinned: false }));
      dispatch({ type: 'resetViews' });
    } catch (e) {
      console.error(e);
    }
  },
  async unpinView({ state, commit }, index) {
    const viewUid = state.viewList.pinned[index].uid;
    const view = state.allViews[viewUid];
    commit('setUnpinnedViews', state.viewList.unpinned.concat({ ...view, unsavedChanges: false }).sort(helpers.unpinnedSort));
    commit('setPinnedViews', state.viewList.pinned.toSpliced(index, 1));
    commit('updateViewPinStatus', { uid: viewUid, pinned: false });
    const res = {};

    try {
      await viewService.updateView(viewUid, { order: null });
    } catch (e) {
      console.error(e);
      res.error = e;
    }

    return res;
  },
  addViewToPinned({ state, commit }, payload) {
    const { index, order } = payload;
    const viewUid = state.viewList.unpinned[index].uid;
    const view = state.allViews[viewUid];
    const pinnedView = { ...view, pinned: true, order };

    commit('setPinnedViews', state.viewList.pinned.concat(pinnedView).sort(helpers.pinnedSort));
    commit('setUnpinnedViews', state.viewList.unpinned.toSpliced(index, 1));
    commit('updateViewPinStatus', { uid: viewUid, pinned: true });
  },
  async pinAddedView({ state }) {
    const { uid, order } = state.viewList.pinned[state.viewList.pinned.length - 1];
    const res = {};

    try {
      await viewService.updateView(uid, { order });
    } catch (e) {
      console.error(e);
      res.error = e;
    }

    return res;
  },
  setPinnedViews({ commit }, views) {
    commit('setPinnedViews', views);
  },
  setHeaders({ commit, state }, currentViewIndex) {
    const hasCustomFieldsFF = Vue.prototype.$hasFeature('crm_custom_fields');
    const hasBulkActionsFF = Vue.prototype.$hasFeature('crm_bulk_actions');
    const currentView = state.viewList.pinned[currentViewIndex];
    if (!currentView) return;
    const columns = [...currentView.columns];

    if (hasCustomFieldsFF) {
      const isCurrViewNewColumnsDecoration = typeof columns?.[0] === 'object';
      const formatterColumns = helpers.formatColumnsForView(columns, state.availableColumns, isCurrViewNewColumnsDecoration);
      const checkboxColumnItem = state.availableColumns.find((c) => c.identifier === 'checkbox');
      if (hasBulkActionsFF && formatterColumns.findIndex((c) => c.identifier === 'checkbox') === -1) {
        formatterColumns.unshift(checkboxColumnItem);
      }
      commit('setHeaders', formatterColumns);
      commit('setIsCurrViewNewColumnsDecoration', isCurrViewNewColumnsDecoration);
    } else {
      if (hasBulkActionsFF && columns.indexOf('checkbox') === -1) {
        columns.unshift('checkbox');
      }
      const lastActivityIndex = columns.findIndex((column) => column === 'last_activity');
      if (lastActivityIndex > -1) {
        columns.splice(lastActivityIndex + 1, 0, 'activity_time');
      }

      const headers = columns.map((column) => {
        const columnItem = state.availableColumns.find((c) => c.old_identifier === column);
        return columnItem;
      });

      commit('setHeaders', headers);
    }
  },
  reorderHeaders({ rootGetters, commit }, payload) {
    const hasCustomFieldsFF = Vue.prototype.$hasFeature('crm_custom_fields');
    if (hasCustomFieldsFF) {
      const checkBoxColumn = state.availableColumns.find((c) => c.identifier === 'checkbox');
      const matterNameColumn = state.availableColumns.find((c) => c.identifier === 'matter_name');
      const staticColumns = rootGetters['BusinessStore/hasFeature']('crm_bulk_actions') ? [checkBoxColumn, matterNameColumn] : [matterNameColumn];
      const columnsWithPrependPinned = staticColumns.concat(payload.columns);
      commit('setPinnedViewColumns', { columns: columnsWithPrependPinned });
      commit('setHeaders', columnsWithPrependPinned);
      commit('setIsCurrViewNewColumnsDecoration', true);
    } else {
      const staticColumns = rootGetters['BusinessStore/hasFeature']('crm_bulk_actions') ? ['checkbox', 'matter_name'] : ['matter_name'];
      const columnsWithPrependPinned = staticColumns.concat(payload.columns);
      const headers = columnsWithPrependPinned.map((column) => {
        const columnItem = state.availableColumns.find((c) => c.old_identifier === column);
        return columnItem;
      });
      commit('setPinnedViewColumns', { columns: columnsWithPrependPinned });
      commit('setHeaders', headers);
    }
  },
  updateClientSelection({ commit }, payload) {
    const { value, client } = payload;

    if (value) {
      commit('selectClient', client);
    } else {
      commit('deselectClient', client);
    }
  },
  async updateAllSelection({ rootGetters, commit }, payload) {
    const { value, data } = payload;

    if (value) {
      try {
        const useNewDecoration = rootGetters['BusinessStore/hasFeature']('crm_custom_fields');
        const { query, sortBy, sortOrder } = data;
        const filter = getters.currentViewFilter(state);
        const perPage = rootGetters['ClientStore/clientResultsCount'](getters.currentTabView(state).uid);
        const { clients } = await clientService.getClients(perPage, 1, query, sortBy, sortOrder, filter, useNewDecoration);
        const mappedClients = clients.map((client) => ({ ...pick(client, CrmConstants.BULK_ACTION_MAPPING) }));
        commit('selectClients', mappedClients.reduce((a, v) => ({ ...a, [v.matter_uid]: v }), {}));
      } catch (e) {
        console.error(e);
      }
    } else {
      commit('deselectAll');
    }
  },
  deselectAll({ commit }) {
    commit('deselectAll');
  },
  selectCurrentPage({ rootGetters, commit }) {
    const mappedClients = rootGetters['ClientStore/clientResults'](getters.currentTabView(state).uid).map((client) => ({ ...pick(client, CrmConstants.BULK_ACTION_MAPPING) }));
    commit('selectClients', mappedClients.reduce((a, v) => ({ ...a, [v.matter_uid]: v }), {}));
  },
  clearAllSelections({ commit }) {
    commit('clearAllSelections');
  },
  async deleteView({ commit, dispatch }, view) {
    try {
      await viewService.deleteView(view.uid);
      commit('deleteView', view.uid);
      commit('deletePinnedView', view.uid);
      dispatch('ClientStore/removeViewFromClientsResult', view.uid, { root: true });
    } catch (e) {
      console.log(e);
    }
  },
  async getFilters({ commit }) {
    if (state.filters.length) {
      return;
    }

    try {
      const filters = await viewService.getFilters();
      const filterGroups = Object.keys(filters).map((key) => ({
        id: key,
        label: filters[key].label,
        order: filters[key].order,
        items: filters[key].filters.filter((item) => !item.options?.hidden).map((item) => ({ id: item.key, ...item })),
      })).filter((group) => group.items.length).sort((a, b) => a.order - b.order);
      commit('setFilters', filterGroups);
    } catch (e) {
      console.error(e);
    }
  },
  resetFilters({ commit }) {
    commit('resetFilters');
  },
  clearAllCurrentViewFilters({ commit }) {
    commit('setPinnedViewFiltersObject', []);
  },
  removeFilterFromCurrentView({ commit }, currentFilter) {
    const currentTabView = getters.currentTabView(state);
    const index = currentTabView.filtersObject.findIndex((filter) => filter.key === currentFilter.key);
    if (index > -1) {
      const updatedViewFiltersObject = currentTabView.filtersObject.toSpliced(index, 1);
      commit('setPinnedViewFiltersObject', updatedViewFiltersObject);
    }
  },
  addOrUpdateFilterToCurrentView({ state, commit }, filter) {
    const currentView = getters.currentTabView(state);
    let filtersObject;
    const index = currentView.filtersObject.findIndex((currentViewFilter) => filter.key === currentViewFilter.key);
    if (index !== -1) {
      filtersObject = [...currentView.filtersObject];
      filtersObject[index] = filter;
    } else {
      filtersObject = [filter, ...currentView.filtersObject];
    }
    commit('setPinnedViewFiltersObject', filtersObject);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  helpers,
};
