import httpService from '@/modules/common/services/httpService';

const VERTICALS = ['healthcare']; // Currently hard coded - with future option of getting from DB (core)

const getAccount = (params) => {
  const paramsString = Object.entries(params).map((entry) => entry.join('=')).join('&');
  return httpService.get(`/v2/profile?${paramsString}`, params);
};

const getBusiness = () => httpService.get('v2/profile/business');

const getSettings = () => httpService.get('v2/profile/settings');

const getStaff = () => httpService.get('v2/profile/staff');

const getGeoDataByIP = () => httpService.get('v2/profile/geo_data_by_ip').then((res) => res.data);

const checkVerticalExistance = (businessVertical) => VERTICALS.includes(businessVertical);

export default {
  getAccount,
  getBusiness,
  getStaff,
  getSettings,
  getGeoDataByIP,
  checkVerticalExistance,
};
