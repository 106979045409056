<script>
export default {
  props: {
    item: Object,
  },
};
</script>

<template>
  <VcLayout class="px-4 menu-item__container">
    <VcIcon class="menu-item__icon" small>{{item.icon}}</VcIcon>
    <span class="prompt-item-label">{{ item.prompt.title }}</span>
    <VcIcon v-if="!!item.rightIcon" small>{{ item.rightIcon }}</VcIcon>
  </VcLayout>
</template>

<style scoped lang="scss">

.menu-item__container {
  gap: var(--size-value4);

  .icon {
    flex-grow: 0;

    height: 100%;
    justify-content: center;

    ::v-deep svg {
      max-width: 100%;
      height: 100%;
    }
  }

  .prompt-item-label {
    flex-grow: 1;
  }
}

.menu-item__icon {
  width: var(--size-value4);
}
</style>
