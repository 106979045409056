import servicesService from '@/modules/services/service/servicesService';

const PAGE_SIZE = 25;

const state = {
  services: [],
  loading: false,
  currentPageIndex: 0,
  completed: false,
  noDiscussServices: [],
  noDiscussLoading: false,
  noDiscussCurrentPageIndex: 0,
  noDiscussCompleted: false,
};

const mutations = {
  addServices(state, services) {
    state.services = state.services.concat(services);
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setCompleted(state, completed) {
    state.completed = completed;
  },
  increaseCurrentPageIndex(state) {
    state.currentPageIndex += 1;
  },
  addNoDiscussServices(state, services) {
    state.noDiscussServices = state.noDiscussServices.concat(services);
  },
  setNoDiscussLoading(state, loading) {
    state.noDiscussLoading = loading;
  },
  setNoDiscussCompleted(state, completed) {
    state.noDiscussCompleted = completed;
  },
  increaseNoDiscussCurrentPageIndex(state) {
    state.noDiscussCurrentPageIndex += 1;
  },
};

const actions = {
  async loadServices({ state, dispatch }) {
    if (state.services.length) return;
    await dispatch('loadMoreServices');
  },
  async loadMoreServices({ commit, state, rootGetters }) {
    if (state.completed || state.loading) return;
    commit('setLoading', true);

    try {
      const businessId = rootGetters['AuthStore/businessUid'];
      const services = await servicesService.fetchServices(businessId, state.currentPageIndex, PAGE_SIZE);
      commit('addServices', services);
      commit('increaseCurrentPageIndex');
      if (services.length < PAGE_SIZE) {
        commit('setCompleted', true);
      }
    } catch (e) {
      console.log('Error loading services', e); // eslint-disable-line no-console
    }

    commit('setLoading', false);
  },
  async loadNoDiscussServices({ state, dispatch }) {
    if (state.noDiscussServices.length) return;
    await dispatch('loadMoreNoDiscussServices');
  },
  async loadMoreNoDiscussServices({ commit, state, rootGetters }) {
    if (state.noDiscussCompleted || state.noDiscussLoading) return;
    commit('setNoDiscussLoading', true);

    try {
      const businessId = rootGetters['AuthStore/businessUid'];
      const services = await servicesService.fetchNoDiscussServices(businessId, state.noDiscussCurrentPageIndex, PAGE_SIZE);
      commit('addNoDiscussServices', services);
      commit('increaseNoDiscussCurrentPageIndex');
      if (services.length < PAGE_SIZE) {
        commit('setNoDiscussCompleted', true);
      }
    } catch (e) {
      console.log('Error loading no discuss services', e); // eslint-disable-line no-console
    }

    commit('setNoDiscussLoading', false);
  },
};

const getters = {
  services: (state) => state.services,
  getServiceByUid: (state) => (uid) => state.services.find((service) => service.id === uid),
  loading: (state) => state.loading,
  completed: (state) => state.completed,
  noDiscussServices: (state) => state.noDiscussServices,
  getNoDiscussServiceByUid: (state) => (uid) => state.noDiscussServices.find((service) => service.id === uid),
  noDiscussLoading: (state) => state.noDiscussLoading,
  noDiscussCompleted: (state) => state.noDiscussCompleted,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
