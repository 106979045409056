import searchBarService from '@/modules/layout/topBar/services/searchBarService';
import SearchBarUtils from '@/modules/layout/topBar/utils/searchBarUtils';

const state = {
  searchResults: [],
  showSearchResults: false,
  isWaitingForSearchResponse: false,
};

const mutations = {
  setSearchResults(state, searchResults) {
    state.searchResults = searchResults;
  },
  setShowSearchResults(state, showSearchResults) {
    state.showSearchResults = showSearchResults;
  },
  setIsWaitingForSearchResponse(state, isWaitingForSearchResponse) {
    state.isWaitingForSearchResponse = isWaitingForSearchResponse;
  },
};

const getters = {
  searchResults: (state) => state.searchResults,
  showSearchResults: (state) => state.showSearchResults,
  isWaitingForSearchResponse: (state) => state.isWaitingForSearchResponse,
};

const actions = {
  async search({ rootGetters, commit }, query) {
    if (query === '') {
      commit('setShowSearchResults', false);
      commit('setSearchResults', []);
      return;
    }

    commit('setIsWaitingForSearchResponse', true);
    commit('setShowSearchResults', true);
    const staff = rootGetters['StaffStore/staffData'];
    const hasPhoneSearchFF = rootGetters['BusinessStore/hasFeature']('phone_search');
    const searchResult = await searchBarService.fetchSearchResults(query, staff.teams_view_filter, hasPhoneSearchFF);
    const topHits = searchResult.top_hits;
    const { counts } = searchResult;
    const formattedSearchResults = [];
    if (topHits?.client) formattedSearchResults.push({ id: 'client', count: counts.client, items: SearchBarUtils.formatClientsArray(topHits.client) });
    Object.keys(topHits).forEach((key) => {
      if (key !== 'client') formattedSearchResults.push({ id: key, count: counts[key], items: SearchBarUtils.formatEntityArray(topHits[key]) });
    });

    commit('setSearchResults', formattedSearchResults);
    commit('setIsWaitingForSearchResponse', false);
  },
  async setShowSearchResults({ commit }, showSearchResults) {
    commit('setShowSearchResults', showSearchResults);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
