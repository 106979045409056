import { render, staticRenderFns } from "./AiGeneratorAngularAdapter.vue?vue&type=template&id=cb81424c&scoped=true"
import script from "./AiGeneratorAngularAdapter.vue?vue&type=script&lang=js"
export * from "./AiGeneratorAngularAdapter.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb81424c",
  null
  
)

export default component.exports