/* eslint-disable */
import Vue from 'vue';
import notificationCenterService from '../services/notificationCenterService';

const state = {
  isNewCounter: 0,
  staffUid: null,
  notifications: [],
  isNotificationsDataLoading: true,
  shouldCallLongPolling: true,
  currentPage: 1,
  notificationsLoadCompleted: false,
};

const DAYS_IN_MONTH = 30;
const DEFAULT_PER_PAGE = 20;

const getters = {
  isNewCounter: (state) => state.isNewCounter,
  notifications: (state) => state.notifications,
  isNotificationsDataLoading: (state) => state.isNotificationsDataLoading,
  unreadNotifications: (state) => state.notifications.filter((notification) => !notification.read),
  notificationsLoadCompleted: (state) => state.notificationsLoadCompleted,
};

const mutations = {
  setIsNewCounter(state, counter) {
    state.isNewCounter = counter;
  },
  setStaffUid(state, staffUid) {
    state.staffUid = staffUid;
  },
  setShouldCallLongPolling(state, value) {
    state.shouldCallLongPolling = value;
  },
  setNotifications(state, notifications) {
    state.notifications = notifications;
  },
  setIsNotificationsDataLoading(state, value) {
    state.isNotificationsDataLoading = value;
  },
  setNotificationStatus(state, { payload }) {
    const notificationIdx = state.notifications.findIndex((notification) => notification.uid === payload.notification_uid);
    if (notificationIdx < 0) return;
    const notification = state.notifications[notificationIdx];
    Vue.set(notification, 'read', !notification.read);
  },
  setAllRead(state) {
    state.notifications.forEach((notification) => {
      Vue.set(notification, 'read', true);
    });
  },
  setCurrentPage(state, page) {
    state.currentPage = page;
  },
  setNotificationsLoadCompleted(state, value) {
    state.notificationsLoadCompleted = value;
  },
};

const actions = {
  async initNotificationCenterBadge({ commit, dispatch }, payload) {
    try {
      document.addEventListener('visibilitychange', () => {
        commit('setShouldCallLongPolling', !document.hidden);
      });
      const staffUid = payload?.staffUid;
      commit('setStaffUid', staffUid);
      await dispatch('fetchNotificationsCounterLongPolling');
    } catch (e) {
      console.error('error', e);
    }
  },
  async setNotificationReadStatus({ commit }, payload) {
    try {
      await notificationCenterService.setNotificationReadStatus(payload.notification_uid, payload.status);
      commit('setNotificationStatus', { payload });
    } catch (e) {
      console.error('error', e);
    }
  },
  async setAllNotificationsAsRead({ commit }) {
    try {
      await notificationCenterService.setAllNotificationsAsRead();
      commit('setAllRead');
    } catch (e) {
      console.error('error', e);
    }
  },
  async fetchNotificationsCounterLongPolling({ commit, dispatch }) {
    notificationCenterService.cancelRequest();

    try {
      if (state.shouldCallLongPolling) {
        const isNewCounter = (await notificationCenterService.getIsNewNotificationCounter(state.isNewCounter, state.staffUid));
        if (isNewCounter === undefined) {
          // eslint-disable-next-line no-promise-executor-return
          await new Promise((resolve) => setTimeout(resolve, 30000));
          return;
        }
        if (isNewCounter !== state.isNewCounter) {
          commit('setIsNewCounter', isNewCounter);
        }
      }
    } catch (e) {
      console.error('error', e);
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, 30000));
    } finally {
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, 500));
      await dispatch('notificationsCenterLongPollDebouncer');
    }
  },
  async resetNotificationCounter({ commit }) {
    try {
      await notificationCenterService.resetNotificationCounter();
      const isNewCounter = 0;
      commit('setIsNewCounter', isNewCounter);
    } catch (e) {
      console.error('error', e);
    }
  },
  async fetchNotifications({ commit, dispatch }) {
    try {
      let currentType = null;
      let typeName = '';
      const allNotifications = await notificationCenterService.getNotifications(DAYS_IN_MONTH, DEFAULT_PER_PAGE, state.currentPage);
      const allMetadata = await notificationCenterService.getAllRelevantMetadata(allNotifications);
      const allTypes = await notificationCenterService.getAllRelevantTypes(allMetadata);

      const mappedNotifications = allNotifications.map((notification) => {
        const resultNotif = { ...notification };
        const currentMetadata = allMetadata.find((metadata) => metadata.uid === notification.notification_metadata_uid);
        if (currentMetadata) {
          if (currentMetadata?.notification_type_uid) {
            currentType = allTypes.find((type) => type.uid === currentMetadata.notification_type_uid);
            typeName = (currentType && currentType.name) ? currentType.name : '';
          }

          if (currentMetadata.notification_code_name === 'appointment_booking_reminder') {
            const title = notificationCenterService.buildTitleAppointmentBookingReminder(notification);
            const body = notificationCenterService.buildBodyAppointmentBookingReminder(notification);
            resultNotif.text_sent = {
              title,
              body,
            };
          }

          return {
            ...resultNotif,
            ...{
              notification_type: typeName,
              notification_code_name: currentMetadata.notification_code_name,
              creator_type: currentMetadata.creator_type,
              creator_identifier: currentMetadata.creator_identifier,
              // avatarImage: notificationCenterService.getAvatarByType(typeName),
              avatarType: typeName,
              pane: currentMetadata.pane,
            },
          };
        }
        return resultNotif;
      })
        .filter((notification) => notification?.pane);

      if (allNotifications.length < DEFAULT_PER_PAGE) {
        dispatch('completeLoading');
      }

      dispatch('concatNotifications', { notifications: mappedNotifications });
      dispatch('incrementCurrentPage');
      commit('setIsNotificationsDataLoading', false);
    } catch (e) {
      console.error('error', e);
    }
  },
  async notificationsCenterLongPollDebouncer({ dispatch }) {
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((resolve) => setTimeout(resolve, 1000));
    dispatch('fetchNotificationsCounterLongPolling');
  },
  async incrementCurrentPage({ commit }) {
    const pageValue = state.currentPage + 1;
    commit('setCurrentPage', pageValue);
  },
  resetCurrentPage({ commit }) {
    commit('setCurrentPage', 1);
  },
  async concatNotifications({ commit }, payload) {
    const updatedNotifications = state.notifications.concat(payload.notifications || []);
    commit('setNotifications', updatedNotifications);
  },
  resetNotifications({ commit }) {
    commit('setNotifications', []);
  },
  async reloadNotificationsData({ dispatch }) {
    dispatch('setIsNotificationsDataLoading', true);
    dispatch('resetCurrentPage');
    dispatch('resetNotifications');
    await dispatch('fetchNotifications');
    dispatch('setIsNotificationsDataLoading', false);
  },
  async completeLoading({ commit }) {
    commit('setNotificationsLoadCompleted', true);
  },
  setIsNotificationsDataLoading({ commit }, value) {
    commit('setIsNotificationsDataLoading', value);
  },
  async handleTopbarBadgeClicked({ dispatch }) {
    if (state.isNewCounter > 0) {
      await dispatch('reloadNotificationsData');
      await dispatch('resetNotificationCounter');
    }
    if (state.notifications.length === 0) {
      await dispatch('fetchNotifications');
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
