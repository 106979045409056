import httpService from '@/modules/common/services/httpService';

const baseRoute = '/business/payments/v1';
const v2Route = '/v2';

const fetchScheduledPayments = async (uid) => httpService.get(`${baseRoute}/scheduled_payments_rules/${uid}`)
  .then((res) => res.data?.data?.scheduled_payments_rule);

const fetchPaymentsByScheduledPaymentsUid = async (uid) => httpService.get(`${baseRoute}/payments?filter[scheduled_payments_rule_uid]=${uid}`)
  .then((res) => res.data?.data?.payments);

const fetchPaymentsByPaymentStatusUid = async (uid) => httpService.get(`${baseRoute}/payments?filter[payment_status_uid_in]=${uid}`)
  .then((res) => res.data?.data?.payments);

const cancelScheduledPayments = async (uid) => httpService.put(`${baseRoute}/scheduled_payments_rules/${uid}/cancel`)
  .then((res) => res.data?.data?.scheduled_payments_rule);

const refundPayment = async (payload) => httpService.post(`${baseRoute}/refunds`, payload)
  .then((res) => res.data);

const waivePayment = async (paymentStatusUid, payload) => httpService.put(`${v2Route}/payment_statuses/${paymentStatusUid}/waive_payment`, payload)
  .then((res) => res);

const cancelInvoice = async (payableUid, payload) => httpService.put(`${baseRoute}/invoices/${payableUid}/cancel`, payload)
  .then((res) => res.data);

const fetchPayment = async (paymentUid) => httpService.get(`${v2Route}/payments/${paymentUid}?full=true&localized=true`)
  .then((res) => res.data);

const fetchPaymentStatus = async (paymentStatusUid) => httpService.get(`${v2Route}/payment_statuses/${paymentStatusUid}?full=true&localized=true`)
  .then((res) => res.data);

const fetchPaymentLink = async (paymentUid, clientUid, amount) => httpService.get(`${baseRoute}/payment_requests/${paymentUid}/get_link?new_api=true&client_id=${clientUid}&amount=${amount}`)
  .then((res) => res?.data?.data?.link);

export default {
  fetchScheduledPayments,
  fetchPaymentsByScheduledPaymentsUid,
  cancelScheduledPayments,
  refundPayment,
  waivePayment,
  cancelInvoice,
  fetchPayment,
  fetchPaymentsByPaymentStatusUid,
  fetchPaymentStatus,
  fetchPaymentLink,
};
