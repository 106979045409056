<template>
  <VcLayout class="title-header" justify-space-between>
    <div class="badge-title">{{ title }}</div>
    <VcButton icon
              data-qa="notifications-settings"
              flavor="secondary"
              @click="$emit('notificationsSettingsButtonClick')">
      <template #prepend>
        <VcIcon size="20">icon-Settings</VcIcon>
      </template>
    </VcButton>
  </VcLayout>
</template>

<script>

export default {
  name: 'NotificationPaneTitle',
  computed: {
    title() {
      return this.$t('notification_center.badge.title');
    },
  },
};
</script>

<style lang="scss" scoped>

.title-header{
  display: flex;
  margin-top:unset;
  align-items: center;
  padding: 0 var(--size-value6);

  .badge-title{
    display: inline-flex;
    font-weight: var(--font-weight-large2);
    font-size: var(--font-size-medium);
    line-height: var(--size-value7);
    padding-top: var(--size-value4);
    padding-inline-end: var(--size-value2);
  }

  .icon-Settings {
    padding-top: var(--size-value4);
    color: var(--gray-darken-3);
    cursor: pointer;
  }
}
</style>
