/* eslint-disable */
/* eslint-disable import/no-relative-packages */
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import axios from 'axios';
// eslint-disable-next-line import/no-extraneous-dependencies
import messages from '../../../config/locales/pov/common-pov.en.yml';

// eslint-disable-next-line no-restricted-globals
let locale = new URL(location).searchParams.get('language') || 'en';

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale, // set locale
  fallbackLocale: 'en',
  messages, // set locale messages
});

const loadedLanguages = {};

function setI18nLanguage(lang) {
  i18n.locale = lang;
  axios.defaults.headers.common['Accept-Language'] = lang;
  document.querySelector('html').setAttribute('lang', lang);
  return lang;
}

async function importModule(module, currentLocale) {
  await import(/* webpackChunkName: "lang-[request]" */ `../../../config/locales/pov/${module}.${currentLocale}.yml`).then(
    async (newMessages) => {
      i18n.mergeLocaleMessage(currentLocale, newMessages.default[currentLocale]);
    },
    async (error) => {
      console.error(error);
    },
  );
}

/**
 * @param module - the localization module that is required
 * @returns {Promise<unknown>}
 */
Vue.prototype.addI18nModule = async (module) => {
  if (!Object.keys(loadedLanguages).includes(locale)) {
    loadedLanguages[locale] = [];
    setI18nLanguage(locale);
  }

  // If the module hasn't been loaded yet
  if (!loadedLanguages[locale].includes(module)) {
    loadedLanguages[locale].push(module);

    await importModule(module, locale);
    // Load the fallback language
    if (locale !== 'en') {
      await importModule(module, 'en');
    }
  }
};

// These are essential translation modules, which will be automatically reloaded when a language is changed
const essentialModules = {};

Vue.prototype.addEssentialI18nModule = async (module) => {
  essentialModules[module] = true;
  await Vue.prototype.addI18nModule(module);
};

function reloadEssentialModules() {
  Object.keys(essentialModules).forEach(async (module) => {
    await Vue.prototype.addI18nModule(module);
  });
}

Vue.prototype.setLocale = (lang) => {
  locale = lang;
  setI18nLanguage(lang);
  reloadEssentialModules();
};

export default i18n;
