import httpService from '@/modules/common/services/httpService';

const baseRoute = '/business/staffs/v2/staffs';
const baseRouteV1 = '/business/staffs/v1/staffs';

const fetchStaffData = async (staffUid, businessUid) => httpService.get(`/platform/v1/businesses/${businessUid}/staffs/${staffUid}`)
  .then((res) => res.data?.data?.staff[0]);

const fetchPermissions = async (staffUid) => httpService.get(`${baseRoute}/${staffUid}/permissions`)
  .then((res) => {
    const response = res.data?.data?.permissions;
    const permissions = Object.keys(response).filter((permission) => response[permission]);
    return permissions;
  });

const toggleStaffCrmSettings = async (staffUid) => httpService.post(`${baseRouteV1}/${staffUid}/toggle_staff_crm_settings`)
  .then((res) => res.data?.data);

const fetchAllStaff = async () => httpService.get('/v2/staffs')
  .then((res) => res.data?.data);

// eslint-disable-next-line import/prefer-default-export
export const staffService = {
  fetchPermissions,
  fetchStaffData,
  fetchAllStaff,
  toggleStaffCrmSettings,
};
