class SearchBarConstants {
  static ENTITY_STATES = {
    client: 'app.client',
    conversation: 'app.conversation.show',
    document: 'app.document.show',
    appointment: 'app.appointment.show',
    event_instance: 'app.event',
    payment: 'app.payment.show',
    estimate: 'app.estimate.show',
  }

  static searchEntities = [
    'client', 'document', 'conversation', 'appointment', 'payment',
  ];
}
export default SearchBarConstants;
