import categoriesService from '@/modules/categories/service/categoriesService';

const state = {
  isLoading: false,
  categoriesServices: [],
  didFetchServices: false,
};

const getters = {
  categoriesServices: (state) => state.categoriesServices,
  isLoading: (state) => state.isLoading,
  didFetchServices: (state) => state.didFetchServices,
};

const mutations = {
  setCategoriesServices(state, serviceList) {
    state.categoriesServices = serviceList;
  },
  setDidFetchServices(state, didFetchServices) {
    state.didFetchServices = didFetchServices;
  },
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

const actions = {
  async loadServices({ commit }, businessUid) {
    commit('setIsLoading', true);
    const { categories } = await categoriesService.getServicesCategories(businessUid);
    const servicesData = await Promise.allSettled(
      categories.map((category) => categoriesService.getAllServicesOfCategory(category.id, businessUid)),
    );
    const services = [].concat(...servicesData.map((service) => service.value));
    commit('setCategoriesServices', services);
    commit('setDidFetchServices', true);
    commit('setIsLoading', false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
