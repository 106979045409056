import httpService from '@/modules/common/services/httpService';

const settingsRout = '/v2/settings';

const getBusinessSettings = async () => httpService.get(settingsRout)
  .then(((res) => res.data));
const submitReserve = async (data) => httpService.put(`${settingsRout}`, { ...data })
  .then((res) => res.data);

export default {
  submitReserve,
  getBusinessSettings,
};
