<template>
  <VcConfirmModal
    class="session-timeout-modal"
    :showCloseButton="false"
    showDialog
    :title="$t('session_timout.title')"
    :contentText="$t('session_timout.content')"
    useOffset
    size="md"
    :okButtonLabel="$t('session_timout.keep_me_logged_in')"
    @onOkClicked="extendSession"
    @onCancelClicked="onLogout"
    :cancelButtonLabel="$t('session_timout.logout')"
    dataQa="session-timeout-modal"
  />
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'SessionTimoutPromptContainer',
  created() {
    this.addI18nModule('session-timeout-pov');
  },
  methods: {
    ...mapActions('AuthStore', ['extendSession', 'onLogout']),
  },
};
</script>
