import gatewayService from '@/modules/common/services/gatewayService';

const BASE_PATH = 'business/subscriptionsmng/v1';

const fetchPurchasable = async (type, uid) => gatewayService.get(`${BASE_PATH}/purchasables?page=1&per_page=50&filter[purchasable_type]=${type}&filter[item_uid]=${uid}`)
  .then((result) => {
    const { prices } = result.data.data.purchasables[0];
    const displayName = result.data.data.purchasables[0].display_name;
    return prices.reduce((acc, obj) => {
      acc[obj.currency] = { ...obj, display_name: displayName };
      return acc;
    }, {});
  })
  .catch((error) => {
    console.error(error);
    return { code: 'error' };
  });

export default {
  fetchPurchasable,
};
