import AiError from '@/modules/aiContentGenerator/utils/errors/aiError';

export default class AiRateLimitError extends AiError {
  constructor(
    {
      header = 'ai_content_generator.errors.http_rate_limit_default.header',
      text = 'ai_content_generator.errors.http_rate_limit_default.text',
    } = {},
  ) {
    super({
      header,
      text,
      type: 'warning',
    });
  }
}
