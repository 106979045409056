<template>
  <VcLayout v-if="userHasRelevantFF" column
            class="layout-menu-container py-4"
            :class="{ 'parent-directory-container': hasParentDirectory }">
    <VcProfileIndication
      class="profile-indication mx-4"
      :title="staffData.display_name"
      :subTitle="staffData.email"
      :avatar="profileAvatar"
      :showArrow="false">
    </VcProfileIndication>
    <VcMenuItemsGroup
      class="menu-items-group mt-4 mx-4"
      :class="{hasParentDirectory: 'parent-directory-menu-items-group'}"
      :groupedMenuItems="mappedMenuItems"
      :isMenuLoading="isMenuLoading"
      @menuItemClick="onMenuItemClick"
      @subMenuItemClick="onMenuItemClick"
      @rightAreaClick="onRightAreaClick">
      <template #right-area-checklist>
        <VcProgressCircular
          v-if="checklistProgress.show"
          :currentStep="checklistProgress.currentStep"
          :steps="checklistProgress.totalSteps"
        />
      </template>
      <template #right-area-inbox>
        <VcBadge v-if="conversationCounts" :badgeText="conversationCounts" inline flavor="neutral"></VcBadge>
      </template>
    </VcMenuItemsGroup>
    <PoweredByContainer/>
  </VcLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PoweredByContainer from '@/modules/layout/mainMenu/components/PoweredByContainer.vue';

export default {
  name: 'MainMenu',
  components: { PoweredByContainer },
  props: {
    dataQa: {
      type: String,
      default: 'MainMenu',
    },
    userHasRelevantFF: {
      type: Boolean,
      default: false,
    },
    staffData: {
      type: Object,
      default: () => ({}),
    },
    profileAvatar: {
      type: Object,
      default: () => ({
        colorId: 27,
        name: '',
        imagePath: '',
      }),
    },
    mappedMenuItems: {
      type: Array,
      default: () => [],
    },
    isMenuLoading: {
      type: Boolean,
      default: true,
    },
    checklistProgress: {
      type: Object,
      default: () => ({
        show: false,
        currentStep: 0,
        totalSteps: 0,
      }),
    },
    conversationCounts: {
      type: Number,
      default: 0,
    },
  },
  created() {
    this.setTopBarConfig(this.menuTopBarConfig);
  },
  computed: {
    ...mapGetters('BusinessStore', ['directorySettings']),
    menuTopBarConfig() {
      const firstButton = {
        identifier: 1,
        icon: 'icon-Magnifi-Glass2',
        actionEvent: 'MainMenuStore/openSearchAction',
        dispatchActionType: 'pov',
      };
      const topBarConfig = {
        title: this.$t('layout.menu.topbar_title'),
        firstButton,
        show: true,
      };
      return topBarConfig;
    },
    hasParentDirectory() {
      return this.directorySettings.has_parent_directory;
    },
  },
  methods: {
    ...mapActions('LayoutTopBarStore', ['setTopBarConfig']),
    onMenuItemClick(event) {
      this.$emit('menuItemClick', event);
    },
    onRightAreaClick(event) {
      this.$emit('rightAreaClick', event);
    },
  },
};
</script>

<style lang="scss" scoped>
$bottom_bar_height: 48px;

.layout-menu-container{
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: rgba(242, 243, 245, 1);
  position: absolute;
  top: var(--vc-top-bar-height);
  bottom: $bottom_bar_height;
  right:0;
  left:0;

  .menu-items-group{
    &::v-deep{
      .VcMenuItemsGroup {
        background-color: white;
      }
    }
  }
}

.parent-directory-container {
  background-color: #ffffff;

  .parent-directory-menu-items-group{
    &::v-deep{
      .VcMenuItemsGroup {
        box-shadow: none;
        border: var(--gray-lighten-2) 1px solid;
      }
    }
  }
}

</style>
