import gatewayService from '@/modules/common/services/gatewayService';

const baseRoute = '/business/subscriptionsmng/v1';

const createSubscription = async (params) => gatewayService.post(`${baseRoute}/subscriptions`, params)
  .then((res) => res.data.data.subscription);

const purchaseItemSubscription = async (itemUid, item) => gatewayService.post(`${baseRoute}/subscriptions/items/${itemUid}`, { item })
  .then((res) => res.data.data.subscriptionsPayPerUnit[0]);

const cancelSubscription = async (subscriptionUid) => gatewayService.put(`${baseRoute}/subscriptions/${subscriptionUid}`, {
  subscription: {
    purchase_state: 'canceled',
  },
});

const getAccount = async () => gatewayService.get(`${baseRoute}/accounts`).then((res) => res.data.data);

const getSubscriptionByUid = async (subscriptionUid) => gatewayService.get(`${baseRoute}/subscriptions?[filter][uid]=${subscriptionUid}`)
  .then((res) => res.data.data.subscriptions[0]);

const getSubscriptionDetails = async () => {
  const accountDetails = await getAccount();
  const result = await getSubscriptionByUid(accountDetails.main_subscription_uid);
  const purchasables = await gatewayService.get(`${baseRoute}/purchasables?page=1&per_page=50&filter[purchasable_type]=package&filter[uid]=${result.purchasable_uid}`);
  result.purchasable_item_uid = purchasables.data.data.purchasables[0].item_uid;

  return result;
};

export default {
  createSubscription,
  purchaseItemSubscription,
  cancelSubscription,
  getSubscriptionDetails,
  getSubscriptionByUid,
};
