const getPayload = (message, data) => {
  const payload = { source: 'pov-wrapper', event: message };

  if (data) {
    payload.data = data;
  }

  return payload;
};

const post = (message, data) => {
  window.parent.postMessage(getPayload(message, data), '*');
};

/*
 * Sends a message to the window that opened the current window tab.
 * This function is used when the current window is a child window and needs to communicate back to its opener tab.
 * For example, it used when purchasing a paid app via the app market using PayGateProxy.
 */
const postOpener = (message, data) => {
  window.opener.postMessage(getPayload(message, data), '*');
};

export default {
  post,
  postOpener,
};
