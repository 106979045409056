/* eslint-disable */
import store from '@/store';

const addToVueInstance = (Vue) => {
  Vue.prototype.$isMobile = function () {
    return store.getters['CommonStore/isMobile'];
  };

  Vue.prototype.$isMobileApp = function () {
    return store.getters['CommonStore/isMobileApp'];
  };
};

export default {
  addToVueInstance,
};
