import cartService from '@/modules/cart/services/cartService';

const state = {
  cart: undefined,
};

const getters = {
  cart: (state) => state.cart,
};

const mutations = {
  setCart(state, value) {
    state.cart = value;
  },
};

const actions = {
  async createCart({ commit }, purchasablePriceUid) {
    const cart = await cartService.createCart(purchasablePriceUid);

    if (!cart) throw new Error('Failed to create cart');

    commit('setCart', cart);
  },
  async fetchCart({ commit }, cartUid) {
    const cart = await cartService.getCart(cartUid);

    if (!cart) throw new Error('Failed to fetch cart');

    commit('setCart', cart);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
