import AiError from '@/modules/aiContentGenerator/utils/errors/aiError';

export default class AiPromptsError extends AiError {
  constructor() {
    super({
      header: 'ai_content_generator.errors.init_prompts.header',
      text: 'ai_content_generator.errors.init_prompts.text',
    });
  }
}
