import ScriptInjectionUtils from '@/modules/externalScripts/utils/scriptInjectionUtils';
import { datadogRum } from '@datadog/browser-rum';

// Save datadogRum globally
window.datadogRum = datadogRum;

const initDatadog = (env, staffUid, staffDisplayName, staffEmail, staffRole) => {
  const fullStoryScriptId = 'pov_datadog';
  const script = `datadogRum.init({
    applicationId: '4ca47f08-1407-4a21-a404-48d9d2ad8e4d',
    clientToken: 'pube9a8020d41d2a002725b152f2269943c',
    site: 'datadoghq.com',
    service: 'frontage',
    env: '${env}',
    usePartitionedCrossSiteSessionCookie: true,
    trackSessionAcrossSubdomains: true,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    usePartitionedCrossSiteSessionCookie: true,
    trackResources: true,
    allowedTracingUrls: [ /\\/api2/i ],
    trackLongTasks: true,
    trackSessionAcrossSubdomains: true,
    defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.setUser({
      id: '${staffUid}',
      name: '${staffDisplayName}',
      email: '${staffEmail}',
      role: '${staffRole}',
    });
  `;
  ScriptInjectionUtils.injectScriptToMainDom(script, fullStoryScriptId);
};

export default {
  initDatadog,
};
