/* eslint-disable */
import axios from 'axios';
import storageService from '@/modules/common/services/storageService';

const baseURL = (window.pov_config.NODE_ENV === 'development') ? 'http://app.dev-vcita.me:7200/app/' : `${window.location.origin}/app`;

const FRONTAGE_API = axios.create({
  baseURL,
  withCredentials: true,
});

FRONTAGE_API.interceptors.request.use(
  (initialConfig) => {
    const config = { ...initialConfig };
    // Do something before request is sent
    config.headers = {
      ...config.headers,
      'x-app-name': 'Frontage-pov',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    };

    return config;
  },
);

let jwtProcessing = false;
let jwtPromise = null;
let jwtToken = null;
let jwtStaffUid = null;
let jwtBusinessUid = null;

const getToken = async (newToken = false) => {
  //  if token already exists and not forced to get a new token
  const isTokenAlreadyInCache = jwtStaffUid && jwtBusinessUid && jwtToken && !newToken;
  if (isTokenAlreadyInCache) {
    return {
      success: true,
      jwt: jwtToken,
      staffUid: jwtStaffUid,
      businessUid: jwtBusinessUid,
    };
  }
  //  if it is not in progress mode - define a new promise and set processing var to true
  //  else it will proceed from the previous promise
  if (!jwtProcessing) {
    jwtProcessing = true;
    jwtPromise = FRONTAGE_API.post('get_token');
  }
  try {
    const res = await jwtPromise;
    if (res?.data?.status === 'success') {
      jwtToken = res?.data?.jwt;
      jwtStaffUid = res?.data?.staff_uid;
      jwtBusinessUid = res?.data?.business_uid;
      jwtProcessing = false;
      return {
        success: true,
        jwt: jwtToken,
        staffUid: jwtStaffUid,
        businessUid: jwtBusinessUid,
      };
    }
    jwtProcessing = false;
    jwtToken = null;
    jwtStaffUid = null;
    jwtBusinessUid = null;
    return { success: false };
  } catch (e) {
    jwtProcessing = false;
    jwtToken = null;
    jwtStaffUid = null;
    jwtBusinessUid = null;

    if (!e.response || (e.response && e.response.status === 500)) {
      return { success: false, error: 'server_error' };
    }

    if (e.response && e.response.status === 401) {
      return { success: false, error: 'invalid_credentials' };
    }

    return { success: false };
  }
};

const logout = async () => FRONTAGE_API.post('logout');

const extendSession = async function () {
  return FRONTAGE_API.post('extend_session').then((res) => res.data);
};

const clearAuthLocalData = function () {
  storageService.remove('userProfile');
};

// eslint-disable-next-line import/prefer-default-export
export const authService = {
  getToken,
  logout,
  extendSession,
  clearAuthLocalData,
};
