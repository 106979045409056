import LayoutConstants from '@/constansts/layoutConstants';

const bottomNavItems = () => [
  {
    id: 1,
    name: 'home',
    label: 'common.navigation.home',
    icon: 'icon-Dashboard_POV',
    actionType: LayoutConstants.BOTTOM_NAV_ACTION_TYPES.ANGULAR_NAVIGATION,
    action: 'app.dashboard',
  },
  {
    id: 2,
    name: 'inbox',
    label: 'common.navigation.inbox',
    icon: 'icon-Inbox_POV',
    badgeValue: 0,
    actionType: LayoutConstants.BOTTOM_NAV_ACTION_TYPES.ANGULAR_NAVIGATION,
    action: 'app.inbox',
  },
  {
    id: 3,
    name: 'clients',
    label: 'common.navigation.clients',
    icon: 'icon-clients-POV',
    actionType: LayoutConstants.BOTTOM_NAV_ACTION_TYPES.ANGULAR_NAVIGATION,
    action: 'app.clients',
  },
  {
    id: 4,
    name: 'calendar',
    label: 'common.navigation.calendar',
    icon: 'icon-Calendar_POV',
    actionType: LayoutConstants.BOTTOM_NAV_ACTION_TYPES.ANGULAR_NAVIGATION,
    action: 'app.calendar',
    extraActions: ['app.scheduler'],
  },
  {
    id: 5,
    name: 'menu',
    label: 'common.navigation.menu',
    icon: 'icon-Menu_POV',
    actionType: LayoutConstants.BOTTOM_NAV_ACTION_TYPES.POV_ACTION,
    dispatchAction: {
      action: 'MainMenuStore/updateShouldShowMenu',
      value: true,
    },
  },
];

export default bottomNavItems();
