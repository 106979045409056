import subscriptionService from '@/modules/subscription/services/subscriptionService';

const state = {
  subscription: undefined,
};

const mutations = {
  setSubscription(state, value) {
    state.subscription = value;
  },
};

const actions = {
  async fetchSubscription({ commit, state }) {
    if (!state.subscription) {
      const subscriptionValue = await subscriptionService.getSubscriptionDetails();

      if (!subscriptionValue) throw new Error('Failed to fetch subscription');

      commit('setSubscription', subscriptionValue);
    }
  },
  async fetchSubscriptionByUid({ commit }, subscriptionUid) {
    const subscriptionValue = await subscriptionService.getSubscriptionByUid(subscriptionUid);

    if (!subscriptionValue) throw new Error('Failed to fetch subscription');

    commit('setSubscription', subscriptionValue);
  },
  async purchaseItemSubscription({ dispatch }, params) {
    const res = await subscriptionService.purchaseItemSubscription(params.item_uid, params.item);

    if (!res) throw new Error('Failed to purchase item subscription');

    await dispatch('fetchSubscriptionByUid', res.subscription_uid);
  },
  async purchaseSubscription({ commit }, params) {
    const res = await subscriptionService.createSubscription(params);

    if (!res) throw new Error('Failed to purchase subscription');

    commit('setSubscription', res);
  },
  async cancelSubscription({ getters }) {
    await subscriptionService.cancelSubscription(getters.subscription.uid);
  },
  async pollingSubscriptionStatus({ getters, dispatch }, subscriptionUid) {
    return new Promise((resolve) => {
      let requestInterval;

      const makeRequest = async () => {
        try {
          const purchaseState = getters.subscription.purchase_state;

          if (purchaseState === 'purchased') {
            clearInterval(requestInterval);
            resolve('success');
          } else if (purchaseState === 'canceled' || purchaseState === 'expired') {
            clearInterval(requestInterval);
            resolve('error');
          } else {
            await dispatch('fetchSubscriptionByUid', subscriptionUid);
          }
        } catch (error) {
          console.error('Error fetching subscription:', error);
          clearInterval(requestInterval);
          resolve('error');
        }
      };

      setTimeout(() => {
        console.log('Polling timed out');
        clearInterval(requestInterval);
        resolve('error');
      }, 12000);

      requestInterval = setInterval(makeRequest, 1000);
    });
  },
};

const getters = {
  subscription: (state) => state.subscription,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
