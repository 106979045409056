/* eslint-disable */
import Vue from 'vue';

let iframe;
const EventBus = new Vue();

const addListener = (event, listener) => {
  EventBus.$on(event, listener);
};

const removeListener = (event, listener) => {
  EventBus.$off(event, listener);
};

const emit = (event, payload) => {
  // Emit to vue listeners
  EventBus.$emit(event, payload);

  // Also emit to angular listeners
  if (!iframe) {
    iframe = document.getElementById('angular-iframe');
  }
  if (iframe) {
    iframe.contentWindow?.postMessage({ event, payload }, '*');
  }
};

export default {
  addListener,
  removeListener,
  emit,
};
