<template>
  <div class="layout-bottom-nav" v-if="showBottomNav">
    <VcBottomNav :tabs="translatedBottomNavTabs" v-model="selectedTab" @change="onChange" :isLoading="isLoading">
      <template #tab-1="data">
        <VcBadge :badgeText="data.item.badgeValue" :offset-y="12" :offset-x="8" size="sm">
          <VcIcon color="currentColor" size="20">
            {{ data.item.icon }}
          </VcIcon>
        </VcBadge>
      </template>
    </VcBottomNav>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import LayoutConstants from '@/constansts/layoutConstants';
import outgoingPostMessageUtils from '@/utils/eventCommunicationUtils/outgoingPostMessageUtils';

export default {
  name: 'bottomNavContainer',
  computed: {
    ...mapGetters('LayoutBottomNavStore', ['showBottomNav', 'bottomNavTabs', 'currentIndex', 'isLoading']),
    ...mapGetters(['isInitialDataLoaded']),
    translatedBottomNavTabs() {
      return this.bottomNavTabs.map((tab) => (
        // eslint-disable-next-line localization/no-logic-in-translations
        { ...tab, label: this.$t(tab.label) }));
    },
    currentTab() {
      return this.translatedBottomNavTabs[this.selectedTab];
    },
    selectedTab: {
      get() {
        return this.currentIndex;
      },
      set(newIndex) {
        this.setCurrentIndex(newIndex);
      },
    },
  },
  methods: {
    ...mapMutations('LayoutBottomNavStore', ['setCurrentIndex']),
    ...mapActions('LayoutBottomNavStore', ['createBottomNavTabs', 'updateInboxTabConversationCounts']),
    ...mapActions('MainMenuStore', ['updateShouldShowMenu']),
    async initBottomNav() {
      await this.createBottomNavTabs();
      await this.updateInboxTabConversationCounts();
    },
    onChange() {
      this.onTabClick(this.currentTab);
    },
    onTabClick(tabData) {
      if (tabData.name !== 'menu') {
        this.updateShouldShowMenu(false);
      }
      this.handleTabClick(tabData);
    },
    handleTabClick(tabData) {
      switch (tabData.actionType) {
        case LayoutConstants.BOTTOM_NAV_ACTION_TYPES.ANGULAR_NAVIGATION:
          outgoingPostMessageUtils.post(LayoutConstants.NAVIGATE_TO, { route: tabData.action });
          outgoingPostMessageUtils.post(LayoutConstants.FIRE_ACTION, { event_name: 'pov-menu-item-clicked' });
          break;
        case LayoutConstants.BOTTOM_NAV_ACTION_TYPES.POV_ACTION:
          this.$store.dispatch(tabData.dispatchAction.action, { value: tabData.dispatchAction.value });
          break;
        case LayoutConstants.BOTTOM_NAV_ACTION_TYPES.ANGULAR_ACTION:
          outgoingPostMessageUtils.post(LayoutConstants.FIRE_ACTION, tabData.action);
          break;
        default:
          break;
      }
    },
  },
  watch: {
    isInitialDataLoaded(newValue, oldValue) {
      if (newValue && !oldValue) {
        this.initBottomNav();
      }
    },
  },
};
</script>

<style scoped>

.layout-bottom-nav {
  background-color: #FFFFFF;
}

</style>
