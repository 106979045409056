import httpService from '@/modules/common/services/httpService';

const viewsRoute = '/business/search/v1/views';
const filtersRoute = '/business/search/v1/view_filters';

const getViews = async () => httpService.get(viewsRoute)
  .then((res) => res.data?.data);

const getFilters = async () => httpService.get(filtersRoute)
  .then((res) => res.data?.data);

const updateViews = async (views) => httpService.post(`${viewsRoute}/bulk`, { views })
  .then((res) => res.data);

const createView = async (view) => httpService.post(`${viewsRoute}`, { view })
  .then((res) => res.data);

const updateView = async (uid, view) => httpService.put(`${viewsRoute}/${uid}`, { view })
  .then((res) => res.data);

const deleteView = async (uid) => httpService.remove(`${viewsRoute}/${uid}`, { uid })
  .then((res) => res.data);

const getAvailableColumns = async () => httpService.get(`${viewsRoute}_columns`)
  .then((res) => res.data?.data);

const getNewDecorationFiltersFromFilterString = (filters) => {
  const newDecorationFilter = [];
  if (filters.requires_payment) {
    newDecorationFilter.push({
      key: 'matter_metadata_flat.payments.open',
      type: 'smart',
      value: {
        range: {
          gte: 1,
        },
      },
    });
  }
  if (filters.last_client_activity_filter && Object.keys(filters.last_client_activity_filter).length > 0) {
    newDecorationFilter.push({
      key: 'last_client_activity_filter',
      type: 'date',
      value: filters.last_client_activity_filter,
    });
  }
  if (filters.created_at_filter && Object.keys(filters.created_at_filter).length > 0) {
    newDecorationFilter.push({
      key: 'created_at_filter',
      type: 'date',
      value: filters.created_at_filter,
    });
  }
  if (filters.client_data_associated_with_field) {
    Object.keys(filters.client_data_associated_with_field).forEach((key) => {
      newDecorationFilter.push({
        key: `client_data_associated_with_field_filter.${key}`,
        type: 'status',
        value: filters.client_data_associated_with_field[key] || '',
      });
    });
  }
  return newDecorationFilter;
};

const getSearchFiltersObjectFromNewDecorationFiltersObject = (filters) => {
  const searchFilters = { search_filter: {} };

  filters.forEach((filter) => {
    const { key, value } = filter;
    const isMatterMetadata = key.startsWith('matter_metadata_flat');

    if (filter.type === 'tags') {
      const tagKey = key;
      const { tags, relation } = value;

      searchFilters.search_filter[tagKey] = tags.join(',');
      searchFilters.search_filter.tags_relation = relation;
    } else if (isMatterMetadata) {
      const mainKey = 'matter_metadata_flat';
      const subKey = key.slice(mainKey.length + 1);

      searchFilters.search_filter[mainKey] = {
        ...searchFilters.search_filter[mainKey],
        [subKey]: value,
      };
    } else {
      const keys = key.split('.');
      let currentLevel = searchFilters.search_filter;

      keys.forEach((k, index) => {
        if (index === keys.length - 1) {
          currentLevel[k] = value;
        } else {
          if (!currentLevel[k]) {
            currentLevel[k] = {};
          }
          currentLevel = currentLevel[k];
        }
      });
    }
  });

  return searchFilters;
};

const getSearchFiltersObject = (filters) => {
  const searchFilters = { search_filter: {} };
  if (filters.requires_payment) {
    searchFilters.search_filter.matter_metadata_flat = {
      'payments.open': {
        range: {
          gte: 1,
        },
      },
    };
  }
  if (filters.last_client_activity_filter) {
    searchFilters.search_filter.last_client_activity_filter = filters.last_client_activity_filter;
  }
  if (filters.created_at_filter) {
    searchFilters.search_filter.created_at_filter = filters.created_at_filter;
  }
  if (filters.client_data_associated_with_field) {
    searchFilters.search_filter.client_data_associated_with_field_filter = {};
    Object.keys(filters.client_data_associated_with_field).forEach((key) => {
      searchFilters.search_filter.client_data_associated_with_field_filter[key] = filters.client_data_associated_with_field[key] || '';
    });
  }
  return searchFilters;
};

export default {
  getViews,
  getFilters,
  updateViews,
  createView,
  updateView,
  deleteView,
  getAvailableColumns,
  getSearchFiltersObject,
  getNewDecorationFiltersFromFilterString,
  getSearchFiltersObjectFromNewDecorationFiltersObject,
};
