import httpService from '@/modules/common/services/httpService';

const getCalendarSyncAppPermissions = async (staffUid) => {
  let result;
  try {
    const res = await httpService.get('business/scheduling/v1/calendar_syncs', { staff_uid: staffUid, new_api: true });
    result = res?.data;
  } catch (error) {
    result = { code: 'error' };
    console.error(error);
  }
  return result;
};

const getCalendarSyncApp = async (businessUid) => {
  let result;
  try {
    const res = await httpService.get('v2/settings/get_available_apps_by_type', { type: 'calendar_sync', buid: businessUid, translate_apps: true });
    result = res.data?.data;
  } catch (error) {
    result = { code: 'error' };
    console.error(error);
  }
  return result;
};

const updateCalendarSyncSettings = async (settingsObj) => {
  let result;
  try {
    const res = await httpService.put('business/scheduling/v1/calendar_syncs', { ...settingsObj, new_api: true });
    result = res.data;
  } catch (error) {
    result = { code: 'error' };
    console.error(error);
  }
  return result;
};

const createCalendarSyncRecord = async (staffUid, appCodeName) => {
  let result;
  try {
    const res = await httpService.post('business/scheduling/v1/calendar_syncs', {
      staff_uid: staffUid,
      new_api: true,
      app_code_name: appCodeName,
    });
    result = res.data;
  } catch (error) {
    result = { code: 'error' };
    console.error(error);
  }
  return result;
};

const getWindowOptions = () => {
  const windowWidth = 902;
  let windowHeight = 637;
  windowHeight += 20;
  const dualScreenLeft = window.screenLeft || window.screenX;
  const dualScreenTop = window.screenTop || window.screenY;

  const width = window.innerWidth || document.documentElement.clientWidth || window.screen.width;
  const height = window.innerHeight || document.documentElement.clientHeight || window.screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - windowWidth) / 2 / systemZoom + dualScreenLeft;
  const top = (height - windowHeight) / 2 / systemZoom + dualScreenTop;
  return `scrollbars=yes, width=${windowWidth / systemZoom}, height=${windowHeight / systemZoom},top=${top},left=${left}`;
};

const connectToCalendarApp = async (appUri, staffUid, businessUid, calendarName, connectWindow, shouldSyncAfterConnect, directoryName) => (
  new Promise((res, rej) => {
    const MAX_ATTEMPTS = 10;
    const INTERVAL_DURATION = 3000;
    let connectUrl = `${appUri}/accounts/initialConnection?staff_uid=${staffUid}&business_uid=${businessUid}&should_sync_after_connect=${shouldSyncAfterConnect}&directory=${directoryName}`;
    if (!shouldSyncAfterConnect) {
      connectUrl += `&exported_calendar_name=${encodeURIComponent(calendarName)}`;
    }
    const connectWindowObj = connectWindow;
    connectWindowObj.location.href = connectUrl;
    let attempts = 0;
    const intervalIdx = setInterval(async () => {
      const permissions = await getCalendarSyncAppPermissions(staffUid);
      attempts += 1;
      if (connectWindowObj.closed) {
        clearInterval(intervalIdx);
        if (!permissions || !permissions.enabled) {
          rej(new Error('Failed to connect to calendar app'));
          return;
        }
      }

      const finalize = (result, isSuccess) => {
        connectWindowObj.close();
        clearInterval(intervalIdx);
        if (isSuccess) {
          res(result);
        } else {
          rej(new Error('Failed to connect to calendar app'));
        }
      };

      if (permissions.enabled) {
        finalize(permissions, true);
      } else if (attempts > MAX_ATTEMPTS) {
        finalize(permissions, false);
      }
    }, INTERVAL_DURATION);
  }));

const getSyncAccount = async (appUri, staffUid) => {
  let result;
  try {
    const res = await httpService.get(`${appUri}/accounts`, { staff_uid: staffUid });
    result = res.data?.data?.account;
  } catch (error) {
    if (error?.response?.status === 422 && error?.response?.data?.errors[0]?.message === 'invalid_grant') {
      return { error: 'invalid_grant' };
    }
  }
  return result;
};

const disconnectFromCalendarApp = async (staffUid, appUri, exportedCalendarName, shouldKeep) => {
  let queryToSend = '';
  if (exportedCalendarName) {
    queryToSend = 'keep_exported_calendar';
  } else {
    queryToSend = 'keep_exported_items';
  }
  const res = await httpService.put(`${appUri}/accounts/disconnectAccount?staff_uid=${staffUid}`, { [queryToSend]: shouldKeep });
  return res;
};

const updateAppSettings = async (appUri, accountUid, settingsObj) => {
  const res = await httpService.put(`${appUri}/accounts/${accountUid}/sync`, {
    should_export: settingsObj.should_export,
    should_import: settingsObj.should_import,
    imported_calendar_uids: settingsObj.imported_calendar_uids,
    new_exported_calendar_name: settingsObj.new_exported_calendar_name,
    sync_at: settingsObj.sync_at,
  });
  return res.data;
};

const updateSyncSettings = async (appUri, accountUid, settingsObj) => {
  const updateCoreData = await updateCalendarSyncSettings(settingsObj);
  const updateApplicationData = await updateAppSettings(appUri, accountUid, settingsObj);

  return [updateCoreData, updateApplicationData];
};

const connectToSync = async (appUri, staffUid, businessUid, calendarName, shouldSyncAfterConnect, directoryName, appCodeName) => {
  const connectWindow = window.open('', '_blank', getWindowOptions());
  await createCalendarSyncRecord(staffUid, appCodeName);
  const con = await connectToCalendarApp(appUri, staffUid, businessUid, calendarName, connectWindow, shouldSyncAfterConnect, directoryName);
  return con;
};

const appsWithBetaBadge = ['esignatures', 'tips'];

export default {
  getCalendarSyncApp,
  getCalendarSyncAppPermissions,
  connectToCalendarApp,
  disconnectFromCalendarApp,
  getSyncAccount,
  createCalendarSyncRecord,
  updateCalendarSyncSettings,
  updateAppSettings,
  updateSyncSettings,
  connectToSync,
  appsWithBetaBadge,
};
