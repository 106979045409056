const state = {
  menuItems: [],
  isBottomSheetOpen: false,
};

const getters = {
  menuItems: (state) => state.menuItems,
  isBottomSheetOpen: (state) => state.isBottomSheetOpen,
};

const mutations = {
  setMenuItems(state, menuItems) {
    state.menuItems = menuItems;
  },
  setBottomSheetState(state, isOpen) {
    state.isBottomSheetOpen = isOpen;
  },
};

const actions = {
  setMenuItems({ commit }, menuItems) {
    commit('setMenuItems', menuItems);
  },
  setBottomSheetState({ commit }, isOpen) {
    commit('setBottomSheetState', isOpen);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
