const routes = [
  {
    path: '/paygateproxy',
    name: 'paygateproxy',
    component: () => import(/* webpackChunkName: "Purchase" */ './views/paygateproxy/paygateproxy.vue'),
    props: (route) => {
      const res = { ...route.query, ...route.params };
      try {
        res.cart_items = JSON.parse(res.cart_items);
      } catch (e) {
        res.cart_items = [];
      }
      return res;
    },

  },
];
export default routes;
