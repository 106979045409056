/* eslint-disable */
import Vue from 'vue';
import accountService from '@/utils/backwardCompatibility/accountModule/services/accountService.js';
import { appsService } from '../../app/services/appsService';
import { subscriptionManagerService } from '../services/subscriptionManagerService';

const DEFAULT_PAYMENT_TYPE = 'monthly';

const state = {
  apps: [],
  billingApp: null,
  billingAccount: null,
  externalApps: [],
  currentSubscriptionDetails: null,
};

const helpers = {
  updatePovLayoutOnAppChanges: (isNewLayout) => {
    if (isNewLayout) {
      Vue.prototype.$frontageApi.send('refresh-pov-layout-data');
    }
  },
};

const getters = {
  apps: (state) => state.apps,
  externalApps: (state) => state.externalApps,
  app: (state) => function (appCodeName) {
    return state.apps.find((a) => a.app_code_name == appCodeName);
  },
  externalApp: (state) => function (appCodeName) {
    return state.externalApps.find((app) => app.app_code_name === appCodeName);
  },
  billingApp: (state) => state.billingApp,
  billingAccount: (state) => state.billingAccount,
  purchasedApps: (state) => state.apps.filter((app) => app.paid_data && !app.paid_data.requires_purchase),
  appUsabilityStatus: (state, getters) => function (appCodeName, hasAppMarket) {
    const app = getters.app(appCodeName);
    if (!app) return {};
    const status = {
      requires_upgrade: false,
      requires_install: false,
      purchase_state: '',
      requires_purchase: false,
      is_usable: false,
    };

    if (app.paid_data) {
      status.purchase_state = app.paid_data.purchase_state;
      status.requires_purchase = app.paid_data.requires_purchase;
    }
    if (app.requires_upgrade) status.requires_upgrade = true;
    if (hasAppMarket && app.status != 'installed' && !app.built_in) status.requires_install = true;
    if (!status.requires_upgrade && !status.requires_install && !status.requires_purchase) status.is_usable = true;

    return status;
  },
  isSignatureAppAvailable: (state) => ['esignatures', 'approve'].some((appName) => state.apps.find((app) => app.app_code_name === appName)),
  isTrackingAppInstalled: (state) => state.apps.find((app) => app.app_code_name === 'conversiontracking')?.status === 'installed',
  isValidBillingAccount: (state) => state.billingApp && state.billingAccount && state.billingAccount.code !== 'error' && state.billingAccount.billing_app_account_data.validation_code === 'valid',
  currentSubscription: (state) => state.currentSubscriptionDetails,

};

const mutations = {
  setApps(state, filters) {
    state.apps = filters;
  },
  setBillingApp(state, app) {
    state.billingApp = app;
  },
  setExternalApps(state, apps) {
    state.externalApps = apps;
  },
  setBillingAccount(state, value) {
    state.billingAccount = value;
  },
  setApp(state, value) {
    if (value.app_code_name == 'paypalwallet') {
      value.description.logo = 'https://app0.vcdnita.com/vue/img/Paypal_venmo_full_sized_with_fixed_O.bd4ebfdd.svg';
    }

    for (let i = 0; i < state.apps.length; i++) {
      if (state.apps[i].app_code_name === value.app_code_name) {
        Vue.set(state.apps, i, value);
        break;
      }
    }
  },
  setPurchasedAppData(state, value) {
    let paid_data;
    const { subscription } = value;
    const { app_code_name } = value;
    const { price_uid } = value;
    const { pay_per_unit } = value;
    const { base_price } = value;

    for (let i = 0; i < state.apps.length; i++) {
      if (state.apps[i].app_code_name === app_code_name) {
        paid_data = {
          base_price,
          price: Number.parseFloat(subscription.purchase_price).toFixed(2),
          currency: subscription.purchase_currency,
          purchase_state: subscription.purchase_state,
          payment_type: subscription.payment_type ?? DEFAULT_PAYMENT_TYPE,
          price_uid,
          pay_per_unit,
          requires_purchase: false,
        };
        Vue.set(state.apps, i, { ...state.apps[i], paid_data });
        break;
      }
    }
  },
  setCurrentSubscriptionDetails(state, currentSubscriptionDetails) {
    state.currentSubscriptionDetails = currentSubscriptionDetails;
  },
};

const actions = {
  async loadApps({
    commit, rootGetters, getters, state,
  }, data = {}) {
    if (getters.apps.length == 0 || data.force) {
      const result = await appsService.getApps();

      const paypalIndex = result.apps.map((app) => app.app_code_name).indexOf('paypalwallet');
      if (paypalIndex != -1) {
        result.apps[paypalIndex].description.logo = 'https://app0.vcdnita.com/vue/img/Paypal_venmo_full_sized_with_fixed_O.bd4ebfdd.svg';
      }

      const aggResult = await subscriptionManagerService.aggregateAppData(result.apps, data.hasPaidApps);
      commit('setApps', aggResult);
    }
  },
  async loadBillingAppData({ commit }, businessUid) {
    const result = await appsService.getAppsByType({ appType: 'billing_app', businessUid });
    commit('setBillingApp', result.apps[0]);
  },
  async loadExternalApps({ commit }) {
    const { apps } = await appsService.getExternalApps();
    commit('setExternalApps', apps);
  },
  async loadBillingAccount({ commit }) {
    const billingAccount = await subscriptionManagerService.getBillingAccount();
    commit('setBillingAccount', billingAccount);
  },
  async installApp({
    rootGetters, commit, getters, state,
  }, params) {
    const result = await appsService.installApp(params);
    const appPaidData = getters.app(result.app_data.app_code_name).paid_data;
    let { app_data } = result;

    if (appPaidData) {
      app_data = { ...result.app_data, paid_data: appPaidData };
    }

    helpers.updatePovLayoutOnAppChanges(rootGetters.isNewPovLayoutMobile);
    commit('setApp', app_data);
  },
  async uninstallApp({
    rootGetters, commit, getters, state,
  }, params) {
    const result = await appsService.uninstallApp(params);
    const appPaidData = getters.app(result.app_data.app_code_name).paid_data;
    let { app_data } = result;

    if (appPaidData) {
      const paid_data = {
        base_price: appPaidData.base_price,
        price: appPaidData.base_price,
        currency: appPaidData.currency,
        purchase_state: 'canceled',
        payment_type: appPaidData.payment_type,
        price_uid: appPaidData.price_uid,
        pay_per_unit: appPaidData.pay_per_unit,
        requires_purchase: true,
      };
      app_data = { ...result.app_data, paid_data };
    }

    helpers.updatePovLayoutOnAppChanges(rootGetters.isNewPovLayoutMobile);
    commit('setApp', app_data);
  },
  async purchaseSubscription({
    rootGetters, commit, getters, state,
  }, params) {
    const res = await subscriptionManagerService.createSubscription(params.purchasable_data);
    if (res.error) {
      throw res.error;
    }
  },
  async purchaseApp({
    rootGetters, commit, getters, state,
  }, params) {
    let result = await subscriptionManagerService.createSubscription(params.purchasable_data);
    const appPaidData = getters.app(params.app_code_name).paid_data;
    result = {
      subscription: {
        purchase_price: params.total_price,
        purchase_currency: appPaidData.currency,
        purchase_state: 'purchased',
        payment_type: 'monthly',
      },
    };

    helpers.updatePovLayoutOnAppChanges(rootGetters.isNewPovLayoutMobile);
    commit('setPurchasedAppData', {
      base_price: params.base_price,
      app_code_name: params.app_code_name,
      price_uid: params.purchasable_data.subscription.purchasable_price_uid,
      pay_per_unit: params.pay_per_unit,
      subscription: result.subscription,
    });
  },
  async logUpgradeButtonClicked({ commit, state }, selectedApp) {
    await appsService.logUpgradeButtonClicked(selectedApp.app_code_name);
  },
  async initiateAppFlow({
    commit, dispatch, getters, rootGetters,
  }, appCodeName) {
    await dispatch('loadApps', {});
    const hasAppMarket = rootGetters.hasFeature('app_market_available');
    const status = getters.appUsabilityStatus(appCodeName, hasAppMarket);

    if (hasAppMarket && (status.requires_upgrade || status.requires_install || status.requires_purchase)) Vue.prototype.$frontageApi.send('open-app-info-page', { app_code_name: appCodeName });
    else if (!hasAppMarket && status.requires_upgrade) Vue.prototype.$frontageApi.send('open-upgrade-dialog');
  },
  async getPurchasable({ commit, getters, rootGetters }, { type, item_uid }) {
    const result = await subscriptionManagerService.getPurchasable(type, item_uid);
    const geoData = await accountService.getGeoDataByIP();
    const accountCurrency = geoData.country.toLowerCase() == 'united kingdom' ? 'GBP' : geoData.continent.toLowerCase() == 'europe' ? 'EUR' : 'USD';
    return result[accountCurrency] || result.USD;
  },
  async loadCurrentSubscriptionDetails({ commit }) {
    let res;
    try {
      res = await subscriptionManagerService.getSubscriptionDetails();
      commit('setCurrentSubscriptionDetails', res);
    } catch (e) {
      res = { res: 'error' };
    }
    return res;
  },
  async cancelSubscription({ dispatch, state }) {
    const res = await subscriptionManagerService.cancelSubscription(state.currentSubscriptionDetails.uid);
    dispatch('loadCurrentSubscriptionDetails');
    return res;
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
