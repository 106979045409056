import AiRateLimitError from '@/modules/aiContentGenerator/utils/errors/aiRateLimitError';
import AiPromptsError from '@/modules/aiContentGenerator/utils/errors/aiPromptError';
import AiChatError from '@/modules/aiContentGenerator/utils/errors/aiChatError';
import AiStreamError from '@/modules/aiContentGenerator/utils/errors/aiStreamError';
import AiError from '@/modules/aiContentGenerator/utils/errors/aiError';
import AiTimeoutError from '@/modules/aiContentGenerator/utils/errors/aiTimeoutError';

const TooManyRequestsFactory = (error) => {
  const reason = error.response?.data?.errors?.[0]?.message;
  switch (reason) {
    case 'messages-create/dollars-per-month':
      return new AiRateLimitError({
        header: 'ai_content_generator.errors.http_rate_limit_monthly.header',
        text: 'ai_content_generator.errors.http_rate_limit_monthly.text',
      });
    default:
      return new AiRateLimitError();
  }
};

const operationToError = {
  getPrompts: AiPromptsError,
  createChat: AiChatError,
  stream: AiStreamError,
  is: (err, operationError) => err instanceof operationError,
};

export const AiOperations = new Proxy(operationToError, {
  get(target, prop) {
    if (prop in target) {
      return target[prop];
    }
    return AiError;
  },
});

export const AiErrorFactory = (error, BaseError) => {
  switch (error.response?.status) {
    case 408:
      return new AiTimeoutError(error);
    case 429:
      return TooManyRequestsFactory(error);
    default:
      return new BaseError(error);
  }
};
