import httpService from '@/modules/common/services/httpService';
import qs from 'qs';

const baseRoute = '/v2/clients';
const basePlatformRoute = '/platform/v1/clients';
const searchRoute = '/v2/search';
const noteRoute = '/v2/notes';

const fetchClient = async (uid) => httpService.get(`${baseRoute}/${uid}`).then((res) => res.data);

const fetchClientVaultedCards = async (uid) => httpService.get(`${basePlatformRoute}/${uid}/payment/cards`)
  .then((res) => res.data?.data?.cards);

const getClients = async (perPage, page, query = '', sortBy = '', sortOrder = '', filterParams = {}, newDecoration = false) => {
  const params = {
    ...filterParams,
    entity: 'client',
    source: 'client_list',
    search_filter: {
      ...filterParams.search_filter,
      text_filter: query,
    },
    page,
    per_page: perPage,
    query,
    sort_by: sortBy,
    sort_order: sortOrder,
    new_decoration: newDecoration,
  };

  return httpService.get(searchRoute, params, (p) => qs.stringify(p))
    .then((res) => {
      if (newDecoration) {
        return {
          clients: res.data?.matters, count: res.data?.counts,
        };
      }
      return { clients: res.data?.top_hits.client, count: res.data?.counts.client };
    }).catch((err) => {
      throw err;
    });
};

const deleteClients = async (uids) => httpService.remove('business/clients/v1/matters', { headers: { 'Content-Type': 'application/json' }, data: { uids } })
  .then((res) => res.data);

const getStatuses = async () => httpService.get('/v2/settings/client_status')
  .then((res) => res.data);

const createNote = async (payload) => httpService.post(`${noteRoute}`, payload)
  .then((res) => res);

export default {
  fetchClient,
  fetchClientVaultedCards,
  getClients,
  getStatuses,
  createNote,
  deleteClients,
};
