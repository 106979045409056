const save = (name, value) => localStorage.setItem(name, JSON.stringify(value));

const get = (name) => {
  const val = localStorage.getItem(name);
  if (!val) return null;

  return JSON.parse(val);
};

const remove = (name) => localStorage.removeItem(name);

const getWithExpiry = (key) => {
  const itemStr = get(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    remove(key);
    return null;
  }
  return item.value;
};

const saveWithExpiry = (key, value, ttl) => {
  const now = new Date();
  const item = {
    value,
    expiry: now.getTime() + ttl,
  };
  save(key, JSON.stringify(item));
};

export default {
  save,
  get,
  remove,
  getWithExpiry,
  saveWithExpiry,
};
