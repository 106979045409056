import outgoingPostMessageUtils from '@/utils/eventCommunicationUtils/outgoingPostMessageUtils';
import Vue from 'vue';

const AnalyticsPlugin = {
  // eslint-disable-next-line no-unused-vars
  install(app, vueOptions) {
    // eslint-disable-next-line no-param-reassign
    app.prototype.$track = (eventName, options = {}) => {
      outgoingPostMessageUtils.post('povAnalyticsEvent', { eventName, options });
    };

    window.addEventListener('message', (event) => {
      // IMPORTANT: Mirrors behavior from vue/src/plugins/analytics.js
      // If POV is hosting a micro-service iframe - get analytics events
      // Consider any modifications made here to be included in the Vue equivalent
      const eventData = event.data;
      if (eventData.event === 'analyticsServiceEvent') {
        if (eventData.origin) eventData.data.options.source = eventData.origin;
        app.prototype.$track(eventData.data.eventName, eventData.data.options);
      }
    });
  },
};

Vue.use(AnalyticsPlugin);

export default AnalyticsPlugin;
