import eventAttendanceService from '@/modules/eventAttendance/service/eventAttendanceService';

const state = {
  eventAttendances: {},
};

const getters = {
  getEventAttendanceByUid: (state) => (uid) => state.eventAttendances[uid],
};

const mutations = {
  setEventAttendances(state, eventAttendances) {
    eventAttendances.forEach((eventAttendance) => {
      state.eventAttendances = { ...state.eventAttendances, [eventAttendance.id]: eventAttendance };
    });
  },
};

const actions = {
  // eslint-disable-next-line no-shadow
  async fetchEventAttendances({ commit, getters }, {
    uids, matterUid, clientUid, forceFetch = true,
  }) {
    const eventAttendancesToFetch = forceFetch ? uids : uids.filter((uid) => !getters.getEventAttendanceByUid(uid));
    if (eventAttendancesToFetch.length) {
      const eventAttendances = await eventAttendanceService.fetchEventAttendances(eventAttendancesToFetch, matterUid, clientUid);
      commit('setEventAttendances', eventAttendances);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
