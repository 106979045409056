/* eslint-disable */
import httpGatewayService from '@/modules/common/services/httpService.js';
import accountService from '@/utils/backwardCompatibility/accountModule/services/accountService.js';
import axios from 'axios';
import httpService from '@/modules/common/services/httpService.js';

const DEFAULT_PAYMENT_TYPE = 'monthly';
const baseRoute = 'business/subscriptionsmng/v1';
/* const HTTP = axios.create({
  baseURL: window.vue_config.VUE_APP_SUBSCRIPTIONS_MNG_URL,
  headers: {
    "X-AUTHORIZATION": '',
    "content-type": "application/json"
  }
}); */

// TODO: This file is deprecated, it doesn't live up to minimal standards of quality, and it should be removed.
const createSubscription = async (params) => {
  let result;
  try {
    result = await httpService.post(`${baseRoute}/subscriptions`, params);
    result = result.data.data;
  } catch (error) {
    result = { code: 'error' };
    console.error(error);
  }

  return result;
};

const getSubscriptions = async () => {
  let result;
  try {
    result = await httpService.get(`${baseRoute}/subscriptions?filter[purchase_state][in]=purchased,suspended`);
    result = result.data.data.subscriptions;
  } catch (error) {
    result = { code: 'error' };
    console.error(error);
  }

  return result;
};

const getPaidSubscriptions = async () => {
  let result;
  try {
    result = await httpGatewayService.get(`${baseRoute}/subscriptions?filter[purchase_state][in]=purchased`);
    result = result.data.subscriptions;
  } catch (error) {
    result = { code: 'error' };
    console.error(error);
  }

  return result;
};

const getBillingAccount = async () => {
  let result;
  try {
    result = await httpService.get(`${baseRoute}/billing/accounts`);
    result = result.data.data.account;
  } catch (error) {
    result = { code: 'error' };
    console.error(error);
  }

  return result;
};
const getAccount = async () => {
  let result;
  try {
    result = await httpService.get(`${baseRoute}/accounts`);
    result = result.data.data;
  } catch (error) {
    result = { code: 'error' };
    console.error(error);
  }

  return result;
};

const getSubscriptionDetails = async () => {
  let result;
  try {
    const accountDetails = await getAccount();
    result = await httpService.get(`${baseRoute}/subscriptions?[filter][uid]=${accountDetails.main_subscription_uid}`);
    result = result.data.data.subscriptions[0];
    let subscriptionName = await httpGatewayService.get(`${baseRoute}/purchasables?page=1&per_page=50&filter[purchasable_type]=package&filter[uid]=${result.purchasable_uid}`);
    subscriptionName = subscriptionName.data.data.purchasables[0].item_uid;
    result.subscriptionName = subscriptionName;
  } catch (error) {
    result = { code: 'error' };
    console.error(error);
  }

  return result;
};

const getPurchasableApps = async (appCodeNames) => {
  let result;

  try {
    result = await httpService.get(`${baseRoute}/purchasables?page=1&per_page=50&filter[purchasable_type][eq]=app&filter[item_uid][in]=${appCodeNames.join(',')}`);
    result = result.data.data.purchasables;

    result = result.reduce((map, obj) => {
      map[obj.uid] = obj;
      return map;
    }, {});
  } catch (error) {
    result = { code: 'error' };
    console.error(error);
  }

  return result;
};

const getPurchasable = async (type, item_uid) => {
  let result;

  try {
    result = await httpService.get(`${baseRoute}/purchasables?page=1&per_page=50&filter[purchasable_type]=${type}&filter[item_uid]=${item_uid}`);
    result = result.data.data.purchasables[0].prices;

    result = result.reduce((map, obj) => {
      map[obj.currency] = obj;
      return map;
    }, {});
  } catch (error) {
    result = { code: 'error' };
    console.error(error);
  }

  return result;
};

const getPaidAppsData = async (apps) => {
  const purchasableApps = await getPurchasableApps(apps.map((app) => app.app_code_name));
  const subscriptions = await getSubscriptions();
  const geoData = await accountService.getGeoDataByIP();

  const paidAppsUids = Object.keys(purchasableApps);

  let app_code_name; let
    found;
  const result = {};

  paidAppsUids.forEach((paidAppUid) => {
    found = false;

    app_code_name = purchasableApps[paidAppUid].item_uid;
    const price_info = findUserPrice(geoData, purchasableApps[paidAppUid]);
    // TODO refactor subscriptions to be in hash by uid
    for (let i = 0; i < subscriptions.length && !found; i++) {
      if (subscriptions[i].purchasable_uid === paidAppUid) {
        result[app_code_name] = purchasedAppData(subscriptions[i], price_info);
        found = true;
      }
    }

    if (!found) {
      if (price_info) {
        result[app_code_name] = paidAppData(price_info);
      } else {
        apps.splice(apps.findIndex((app) => app.app_code_name === app_code_name), 1);
      }
    }
  });

  return result;
};
function findUserPrice(geoData, app) {
  const accountCurrency = geoData.country.toLowerCase() == 'united kingdom' ? 'GBP' : geoData.continent.toLowerCase() == 'europe' ? 'EUR' : 'USD';
  return app.prices?.find((item) => item.currency == accountCurrency);
}

function purchasedAppData(subscription, price_info) {
  const pay_per_unit = price_info.pay_per_unit ? price_info.pay_per_unit : [];
  return {
    base_price: price_info.price,
    price: subscription.purchase_price,
    currency: subscription.purchase_currency,
    payment_type: subscription.payment_type ?? DEFAULT_PAYMENT_TYPE,
    purchase_state: subscription.purchase_state,
    subscription_created_at: subscription.created_at,
    pay_per_unit,
    requires_purchase: false,
  };
}

function paidAppData(price_info) {
  const pay_per_unit = price_info.pay_per_unit ? price_info.pay_per_unit : [];
  return {
    base_price: price_info.price,
    price: price_info.price,
    currency: price_info.currency,
    payment_type: price_info.payment_type,
    purchase_state: 'not_purchased',
    price_uid: price_info.uid,
    pay_per_unit,
    requires_purchase: true,
  };
}

const aggregateAppData = async (apps, hasPaidApps) => {
  if (hasPaidApps) {
    const paidAppsData = await getPaidAppsData(apps);
    apps.forEach((app) => {
      if (paidAppsData[app.app_code_name]) {
        app.paid_data = paidAppsData[app.app_code_name];
      }
    });
  }
  return apps;
};

const removePaidApps = async (apps, hasPaidApps) => {
  if (hasPaidApps) {
    const paidAppsData = await getPaidAppsData(apps);
    apps = apps.filter((app) => !paidAppsData[app.app_code_name]);
  }
  return apps;
};

const cancelSubscription = async (subscription_uid) => {
  let res;
  res = await httpService.put(`${baseRoute}/subscriptions/${subscription_uid}`, {
    subscription: {
      purchase_state: 'canceled',
    },
  });
  return res.data.data.data;
};
export const subscriptionManagerService = {
  createSubscription,
  getSubscriptions,
  getPaidSubscriptions,
  getBillingAccount,
  getPurchasableApps,
  getPaidAppsData,
  aggregateAppData,
  removePaidApps,
  getPurchasable,
  getSubscriptionDetails,
  cancelSubscription,
};
