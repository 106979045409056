class AiPlatformService {
  $client = null;

  $streamClient = null;

  constructor(xhrService, fetchService) {
    this.$client = xhrService;
    this.$streamClient = fetchService;
  }

  async createChat(agentName, { instruction, windowSize = undefined, modelName = undefined }) {
    return this.$client.post('/v3/ai/bizai_chats', {
      agent: agentName,
      config: {
        instruction,
        window_size: windowSize,
        model_name: modelName,
      },
    });
  }

  async sendMessage(chatUid, content, streaming = true) {
    const payload = {
      content: {},
      streaming,
    };

    if (content.promptUid) {
      payload.type = 'prompt';
      payload.content = {
        uid: content.promptUid,
        variables: content.variables,
      };
    } else {
      payload.type = 'text';
      payload.content = {
        text: content.text,
      };
    }

    const sp = new URLSearchParams({
      bizai_chat_uid: chatUid,
    });
    return this.$streamClient.post(
      `/v3/ai/bizai_chat_messages?${sp}`,
      payload,
    );
  }

  async getPrompts(feature) {
    return this.$client.get('/v3/ai/bizai_prompts', {
      feature,
    });
  }
}

export default AiPlatformService;
