<template>
  <VcLayout class="mainLoader" align-center justify-center :data-qa="dataQa">
    <VcLoader color="#0C315E"/>
  </VcLayout>
</template>

<script>

export default {
  name: 'MainLoader',
  props: {
    dataQa: {
      type: String,
      default: 'MainLoader',
    },
  },
};
</script>

<style scoped lang="scss">
.mainLoader {
  position: absolute;
  background: #F2F3F5;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

</style>
