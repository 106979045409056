const state = {
  systemNotifications: [],
};

const mutations = {
  setSystemNotifications(state, systemNotifications) {
    state.systemNotifications = systemNotifications;
  },
  removeNotificationByName(state, notificationName) {
    state.systemNotifications = state.systemNotifications.filter((notification) => notification.name !== notificationName);
  },
};

const getters = {
  systemNotifications: (state) => state.systemNotifications,
};

const actions = {
  initSystemNotifications({ commit }, systemNotifications) {
    commit('setSystemNotifications', systemNotifications);
  },
  removeNotificationByName({ commit }, notificationName) {
    commit('removeNotificationByName', notificationName);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
