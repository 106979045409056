import httpService from '@/modules/common/services/httpService';

const categoriesRout = 'platform/v1/categories';
const getServicesCategories = (businessUid) => {
  const getCategoriesParams = {
    business_id: businessUid,
    only_visible_services: false,
    categories_array: [],
    has_future_instance: false,
    use_platform_api: true,
  };
  return httpService.get(categoriesRout, getCategoriesParams).then((res) => res.data.data);
};

const fetchServicesOfCategory = (params) => httpService.get(`${categoriesRout}/${params.category_id}/services`, params.category_params)
  .then((res) => res.data);

const getAllServicesOfCategory = async (categoryId, businessUid) => {
  const getServicesParams = {
    business_id: businessUid,
    page: 0,
    per_page: 50,
    include_hidden: true,
    only_visible_services: false,
    has_future_instance: false,
    use_platform_api: true,
    extra_decorator_fields: true,
  };
  const params = { category_id: categoryId, category_params: getServicesParams };
  const categoryServicesRes = await fetchServicesOfCategory(params);
  let servicesFullList = categoryServicesRes.data.services;
  let nextPage = categoryServicesRes.next_page;
  while (nextPage && nextPage > 0) {
    getServicesParams.page = nextPage;
    /* eslint-disable no-await-in-loop */
    const categoryServicesResPage = await fetchServicesOfCategory(params);
    const extraServices = categoryServicesResPage.data.services;
    servicesFullList = servicesFullList.concat(extraServices);
    nextPage = categoryServicesResPage.next_page;
  }
  return servicesFullList;
};

export default {
  getServicesCategories,
  getAllServicesOfCategory,
};
