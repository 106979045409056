<template>
  <div class="system-notifications-container">
    <VcSystemAlert v-for="notification in notificationsToShow"
                    :key="notification.name"
                    :data-qa="`vc-notification-${notification.name}`"
                    :message="notification.message"
                    :buttonLabel="notification.button_text"
                    :type="notification.category"
                    @click="onNotificationClick(notification)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import outgoingPostMessageUtils from '@/utils/eventCommunicationUtils/outgoingPostMessageUtils';
import LayoutConstants from '@/constansts/layoutConstants';

export default {
  name: 'SystemNotificationsContainer',
  computed: {
    ...mapGetters('SystemNotificationStore', ['systemNotifications']),
    notificationsToShow() {
      return this.sortNotificationsByCategory(this.systemNotifications);
    },
  },
  methods: {
    onNotificationClick(notification) {
      outgoingPostMessageUtils.post(LayoutConstants.FRONTAGE_SYSTEM_NOTIFICATION_CLICK_ACTION, notification.name);
    },
    sortNotificationsByCategory(notifications) {
      const categoryOrder = {
        error: 0,
        warning: 1,
        info: 2,
      };

      notifications.sort((a, b) => {
        const categoryA = a.category;
        const categoryB = b.category;

        const orderA = categoryOrder[categoryA];
        const orderB = categoryOrder[categoryB];

        return orderA - orderB;
      });
      return notifications.slice(0, 2);
    },
  },
};
</script>
