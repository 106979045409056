const addNewrelicMetaData = (isApp) => {
  if (typeof window?.newrelic === 'object') {
    window.newrelic.setCustomAttribute('isPOV', 'pov_enabled');
    window.newrelic.setCustomAttribute('isAPP', (isApp ? 'app' : 'web'));
  }
};

export default {
  addNewrelicMetaData,
};
