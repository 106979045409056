import { i18n } from '@/plugins/i18n';

const priceDisplay = (amount, currency, locale = i18n.locale) => {
  if (amount || amount === 0) {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
    }).format(amount);
  }
  return '';
};

const roundNumber = (number, decimalPlaces = 2) => {
  if (number || number === 0) {
    return +(`${Math.round(`${number}e+${decimalPlaces}`)}e-${decimalPlaces}`);
  }
  return number;
};

const percentageDisplay = (amount) => {
  if (amount || amount === 0) {
    const roundAmount = roundNumber(amount);
    return `${roundAmount}%`;
  }
  return '';
};
const getCurrencySymbol = (currency, locale = i18n.locale) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  });
  const parts = formatter.formatToParts(1000);
  return parts.find((part) => part.type === 'currency').value;
};

export default {
  priceDisplay,
  roundNumber,
  percentageDisplay,
  getCurrencySymbol,
};
