import businessService from '@/modules/business/services/businessService';

const state = {
  businessSettings: {},
};

const mutations = {
  setBusinessSettings(state, businessSettings) {
    state.businessSettings = businessSettings;
  },
};

const actions = {
  async fetchBusinessSettings({ commit }, businessUid) {
    try {
      const businessSettings = await businessService.fetchBusinessSettings(businessUid);
      if (businessSettings) {
        commit('setBusinessSettings', businessSettings);
      }
    } catch (e) {
      console.error('error', e);
    }
  },
};

const getters = {
  businessSettings: (state) => state.businessSettings,
  businessCategory: (state) => state.businessSettings.profession?.text || '',
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
