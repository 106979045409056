import eventListenerService from '@/utils/eventCommunicationUtils/eventListenerService';

const state = {
  isAngularLoaded: false,
  isNavigatingToAngular: false,
  isAngularDisplayed: 0,
  fullscreenState: {},
  isOverlayDisplayed: false,
  blurredLayout: false,
};

const getters = {
  isAngularLoaded: (state) => state.isAngularLoaded > 0,
  isAngularDisplayed: (state) => state.isAngularDisplayed,
  isNavigatingToAngular: (state) => state.isNavigatingToAngular,
  isFullscreen: (state) => Object.values(state.fullscreenState).some((val) => val > 0),
  isVueFullscreen: (state) => (state.fullscreenState['vue-modal'] || 0) + (state.fullscreenState['vue-modal-fullscreen'] || 0) > 0,
  isVueModalFullscreen: (state) => (state.fullscreenState['vue-modal'] || 0) > 0,
  isOverlayDisplayed: (state) => state.isOverlayDisplayed,
  blurredLayout: (state) => state.blurredLayout,
};

const mutations = {
  setIsAngularLoaded(state, isAngularLoaded) {
    state.isAngularLoaded = isAngularLoaded;
    eventListenerService.emit('angularLoaded');
  },
  setIsAngularDisplayed(state, isAngularDisplayed) {
    state.isAngularDisplayed += (isAngularDisplayed ? 1 : -1);
  },
  setFullscreenState(state, { id, show }) {
    if (id) {
      if (state.fullscreenState[id]) {
        const updatedValue = show ? state.fullscreenState[id] + 1 : Math.max(state.fullscreenState[id] - 1, 0);
        state.fullscreenState = { ...state.fullscreenState, [id]: updatedValue };
      } else {
        state.fullscreenState = { ...state.fullscreenState, [id]: show ? 1 : 0 };
      }
    }
  },
  setIsNavigatingToAngular(state, isNavigating) {
    state.isNavigatingToAngular = isNavigating;
  },
  toggleIsOverlayDisplay(state) {
    state.isOverlayDisplayed = !state.isOverlayDisplayed;
  },
  setIsOverlayDisplay(state, value) {
    state.isOverlayDisplayed = value;
  },
  setBlurredLayout(state, value) {
    state.blurredLayout = value;
  },
};

const actions = {
  setIsAngularLoaded({ commit }, isAngularLoaded) {
    commit('setIsAngularLoaded', isAngularLoaded);
  },
  setIsAngularDisplayed({ commit }, isAngularDisplayed) {
    commit('setIsAngularDisplayed', isAngularDisplayed);
  },
  setFullscreenState({ commit }, data) {
    commit('setFullscreenState', data);
  },
  setIsNavigatingToAngular({ commit }, isNavigating) {
    commit('setIsNavigatingToAngular', isNavigating);
  },
  toggleIsOverlayDisplay({ commit }, color = 'transparent') {
    commit('toggleIsOverlayDisplay', color);
  },
  setIsOverlayDisplay({ commit }, value, color = 'transparent') {
    commit('setIsOverlayDisplay', value, color);
  },
  setBlurredLayout({ commit }, value) {
    commit('setBlurredLayout', value);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
