import httpService from '@/modules/common/services/httpService';

// TODO: merge with appService.js
const getApps = async () => {
  let result;
  try {
    const res = await httpService.get('/v2/settings/get_available_market_apps');
    result = res.data?.data;
  } catch (error) {
    result = { code: 'error' };
    console.error(error);
  }
  return result;
};

const getAppsByType = async ({ type, businessUid }) => {
  let result;
  try {
    const res = await httpService.get('v2/settings/get_available_apps_by_type', { type, buid: businessUid });
    result = res.data?.data;
  } catch (error) {
    result = { code: 'error' };
    console.error(error);
  }
  return result;
};

const installApp = async (params) => {
  let result;
  try {
    const res = await httpService.post('platform/v1/apps/manager/install_app', params);
    result = res.data?.data;
  } catch (error) {
    result = { code: 'error' };
    console.error(error);
  }
  return result;
};

const uninstallApp = async (params) => {
  let result;
  try {
    const res = await httpService.post('platform/v1/apps/manager/uninstall_app', params);
    result = res.data?.data;
  } catch (error) {
    result = { code: 'error' };
    console.error(error);
  }
  return result;
};

const logUpgradeButtonClicked = async (appCodeName) => {
  let result;
  try {
    await httpService.post('platform/v1/apps/manager/upgrade_clicked_log', { app_code_name: appCodeName });
  } catch (error) {
    result = { code: 'error' };
    console.error(error);
  }
  return result;
};

const appsWithBetaBadge = ['esignatures', 'tips'];

export {
  getApps,
  getAppsByType,
  installApp,
  uninstallApp,
  appsWithBetaBadge,
  logUpgradeButtonClicked,
};
