<template>
  <div>
    <RefundDialog v-if="isModalOpen('refund-dialog')" />
    <CalendarSyncDialog v-if="isModalOpen('calendar-sync-dialog')" />
    <InvoiceCancelDialog v-if="isModalOpen('invoice-cancel-dialog')"/>
    <SaleCancelDialog v-if="isModalOpen('sale-cancel-dialog')"/>
    <SaleQrDialog v-if="isModalOpen('sale-qr-dialog')"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CommonModals',
  components: {
    InvoiceCancelDialog: () => import('@/modules/modals/components/InvoiceCancelDialog.vue'),
    RefundDialog: () => import('@/modules/modals/components/RefundDialog.vue'),
    CalendarSyncDialog: () => import('@/modules/modals/components/CalendarSyncDialog.vue'),
    SaleCancelDialog: () => import('@/modules/modals/components/SaleCancelDialog.vue'),
    SaleQrDialog: () => import('@/modules/modals/components/SaleQrDialog.vue'),
  },
  computed: {
    ...mapGetters('ModalStore', ['isModalOpen']),
  },
};
</script>
