import httpService from '@/modules/common/services/httpService';

const baseRoute = '/business/payments/v1/product_orders';

const fetchProductOrder = async (uid) => httpService.get(`${baseRoute}/${uid}`)
  .then((res) => res.data?.data?.product_order);

export default {
  fetchProductOrder,
};
