import httpService from '@/modules/common/services/httpService';

const basePlatformRoute = '/platform/v1/scheduling';

const fetchEventAttendances = async (uids, clientUid) => httpService.get(`${basePlatformRoute}/event_attendances`, {
  uids: uids.join(','),
  client_uid: clientUid,
})
  .then((res) => res.data?.data?.event_attendances);

export default {
  fetchEventAttendances,
};
