import searchBarService from '@/modules/layout/topBar/services/searchBarService';

class SearchBarUtils {
  static formatClientsArray(clients) {
    let formattedClients = clients.map((client) => {
      const sameMatterContact = client.full_name === client.matter_name;
      const name = sameMatterContact ? client.full_name : client.matter_name;
      const details = sameMatterContact ? client.email || '' : `${`${client.full_name}`} ${client.email ? ` | ${client.email}` : ''}`;
      return {
        name,
        details,
        id: client.uid,
        type: client.type,
        matter_uid: client.matter_uid,
      };
    });

    if (formattedClients.length > searchBarService.MAX_ITEM_AMOUNT) {
      formattedClients = formattedClients.slice(0, searchBarService.MAX_ITEM_AMOUNT);
      formattedClients.push({
        id: 'showMoreClient', showMore: true, type: 'client',
      });
    }

    return formattedClients;
  }

  static formatEntityArray(entityArray) {
    const dateFormat = { year: 'numeric', month: 'short', day: 'numeric' };
    let formattedEntities = entityArray.map((entity) => ({
      name: entity.type === 'document' ? entity.title : undefined,
      details: `${`${entity.client.first_name} ${entity.client.last_name ? entity.client.last_name : ''}`} | ${new Date(entity.created_at).toLocaleDateString('en-US', dateFormat)}`,
      id: entity.uid,
      type: entity.type,
    }));

    if (formattedEntities.length >= searchBarService.MAX_ITEM_AMOUNT) {
      formattedEntities = formattedEntities.slice(0, searchBarService.MAX_ITEM_AMOUNT);
      formattedEntities.push({
        id: `showMore${entityArray[0].type}`, showMore: true, type: entityArray[0].type,
      });
    }
    return formattedEntities;
  }
}

export default SearchBarUtils;
