<template>
  <div class="angular-iframe"
       :class="{'is-mobile' : $isMobile() || isMobileApp,
                  'is-desktop': !$isMobile() && !isMobileApp,
                    'isDisplayed' : isAngularDisplayed,
                    'isFullscreen' : isFullscreen,
                    'isModalMode': isAngularModalOpen,
                    'isAngularLoaded': isAngularLoaded}">
    <iframe id="angular-iframe"
            class="angular-iframe"
            :src="getAngularURL"
            v-show="isAngularDisplayed || isAngularModalOpen"
            data-qa="angular-iframe"
            title="angularjs"></iframe>
    <MainLoader v-if="!isAngularLoaded" data-qa="layoutLoader"></MainLoader>
  </div>
</template>

<script>

import eventListenerService from '@/utils/eventCommunicationUtils/eventListenerService';
import outgoingPostMessageUtils from '@/utils/eventCommunicationUtils/outgoingPostMessageUtils';
import mobileAppService from '@/utils/appUtils/mobileAppService';
import { mapActions, mapGetters } from 'vuex';
import MainLoader from '@/modules/layout/mainLoader/MainLoader.vue';

export default {
  name: 'AngularDelegator',
  components: { MainLoader },
  data() {
    return {
      currentRoute: '',
    };
  },
  async created() {
    this.setCurrentRoute(window.location);

    eventListenerService.addListener('on-message', async (message) => {
      if (message.data?.doneLoading) {
        this.setIsAngularLoaded(true);
        outgoingPostMessageUtils.post('init-complete', { initDone: true });
      }
      if (message.data?.authenticated) {
        mobileAppService.registerDevice();
      }
    });
  },
  methods: {
    ...mapActions('AngularStore', ['setIsAngularLoaded']),
    setCurrentRoute(url) {
      // This is a result of differences between local and integration envs
      if (url.pathname.includes('/app')) {
        this.currentRoute = url.pathname.substring(url.pathname.indexOf('/app') + 4);
      } else {
        this.currentRoute = url.pathname;
      }
      this.currentRoute += url.search;
      this.currentRoute += url.hash;
    },
  },
  computed: {
    ...mapGetters('CommonStore', ['isMobileApp']),
    ...mapGetters('AngularStore', ['isAngularDisplayed', 'isFullscreen', 'isAngularLoaded']),
    ...mapGetters('ModalStore', ['isAngularModalOpen']),
    getAngularURL() {
      const url = new URL(`${window.location.protocol}//${window.location.host}/app${this.currentRoute}`);
      url.searchParams.set('child_app', 'true');
      if (window.pov_config.NODE_ENV === 'development') {
        url.port = '7200';
      }
      return url.toString();
    },
  },
  destroyed() {
    window.removeEventListener('message', this.listener);
  },
};

</script>
<style scoped lang="scss">
.angular-iframe {
  pointer-events: all;
  width: 100%;
  height: 100%;
  border: unset;
  display: block;
  position: absolute;
}

.is-mobile {
  background: transparent;
  position: absolute;
  top: 0;
  pointer-events: none;

  &.isDisplayed {
    pointer-events: all;
  }

  &.isFullscreen {
    z-index: 100;
  }
}

.is-desktop {
  z-index: 0;

  &.isDisplayed {
    z-index: 0;
  }

  &.isFullscreen {
    z-index: 100;
  }

  &.isModalMode {
    transition: z-index 500ms step-end;
  }
}

</style>
