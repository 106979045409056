const routes = [
  {
    path: '/settings/roles_and_permissions',
    component: () => import(/* webpackChunkName: "RolesListPage" */ './views/RolesListPage.vue'),
    name: 'RolesListPage',
    props: (route) => ({ ...route.query, ...route.params }),
  },
  {
    path: '/settings/roles_and_permissions/role/:uid',
    component: () => import(/* webpackChunkName: "RolePage" */ './views/RolePage.vue'),
    name: 'RolePage',
    props: (route) => ({ ...route.query, ...route.params }),
  },
];

export default routes;
