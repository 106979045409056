import httpService from '@/modules/common/services/httpService';

const baseRoute = '/business/clients/v1/matters';

const fetchMatter = async (uid) => httpService.get(`${baseRoute}/${uid}`)
  .then((res) => res.data?.data?.matter);

export default {
  fetchMatter,
};
