import httpService from '@/modules/common/services/httpService';

const gatewayService = httpService.create(window.pov_config.VUE_APP_API_URL_GATEWAY);
const baseRoute = 'v3/access_control';

const getAllBusinessRoles = async () => gatewayService.get(`${baseRoute}/business_roles`, { per_page: 100 }).then((res) => res.data);
const getBusinessRole = async (roleUid) => gatewayService.get(`${baseRoute}/business_roles/${roleUid}`).then((res) => res.data);
const createBusinessRole = async (params) => gatewayService.post(`${baseRoute}/business_roles`, params).then((res) => res.data);
const updateBusinessRole = async (roleUid, params) => gatewayService.put(`${baseRoute}/business_roles/${roleUid}`, params).then((res) => res.data);
const deleteBusinessRole = async (roleUid) => gatewayService.remove(`${baseRoute}/business_roles/${roleUid}`).then((res) => res);

const getAllPermissions = async () => gatewayService.get(`${baseRoute}/permissions`, { per_page: 100 }).then((res) => res.data);

const getAllStaffBusinessRoles = async () => gatewayService.get(`${baseRoute}/staff_business_roles`, { per_page: 100 }).then((res) => res.data);
const getStaffBusinessRole = async (roleUid) => gatewayService.get(`${baseRoute}/staff_business_roles/${roleUid}`).then((res) => res.data);
const changeRoleToStaff = async (staffUid, roleUid) => gatewayService.put(`${baseRoute}/staff_business_roles`, { staff_uid: staffUid, business_role_uid: roleUid }).then((res) => res.data);

export default {
  getAllBusinessRoles,
  createBusinessRole,
  getBusinessRole,
  updateBusinessRole,
  deleteBusinessRole,
  getAllPermissions,
  getAllStaffBusinessRoles,
  getStaffBusinessRole,
  changeRoleToStaff,
};
