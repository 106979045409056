<template>
  <div class="pane-row d-flex pt-4 pb-5 px-6" :class="{'mobile': $isMobile()}">
    <div class="pane-row__avatar me-5 mt-1">
      <VcAvatar
        :name="avatarText"
        :colorId="1"
        size="md"
        :disabled="false"
        :badgeIcon="avatarIcon"
        :showBorder="false"
        :image-path="getAvatarByType"
      />
    </div>

    <div class="pane-row__body flex-grow-1" :class="unread ? 'pane-row__body--active' : 'pane-row__body--inactive'">
      <div class="d-flex justify-space-between pb-1">
        <div class="pane-row__body__title">{{ title }}</div>
        <VcTooltip
          :content="tooltipLabel"
          :disabled="$isMobile()"
          dark top
          :nudgeLeft="30">
          <div @click.stop="$emit('toggleStatus')"
               class="pane-row__body__dot d-flex align-center justify-center"
               aria-hidden="true">
            <span v-if="unread"></span>
          </div>
          <template>
          </template>
        </VcTooltip>
      </div>
      <div v-if="description" class="pane-row__body__description"
           :class="{'pane-row__body--active__description' : unread}">{{ description }}
      </div>
      <div v-if="preview" class="pane-row__body__preview px-3 py-2 mt-1">{{ preview }}</div>
      <div class="pane-row__body__time mt-2" :class="{'pane-row__body--active__time' : unread}">
        <VcTimeSince :timestamp="timestamp"/>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'NotificationItem',
  props: {
    notificationType: {
      type: [String, Object],
    },
    avatarText: {
      type: String,
    },
    avatarIcon: {
      type: String,
    },
    unread: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    preview: {
      type: String,
      required: false,
    },
    timestamp: {
      type: Number,
      required: true,
    },
  },
  computed: {
    tooltipLabel() {
      if (this.unread) return this.$t('notification_center.badge.mark_as_read');
      return this.$t('notification_center.badge.mark_as_unread');
    },
    getAvatarByType() {
      switch (this.notificationType) {
        case 'clients':
          // eslint-disable-next-line global-require
          return require('../../assets/clients_thumbnail.svg');
        case 'marketing':
          // eslint-disable-next-line global-require
          return require('../../assets/marketing_thumbnail.svg');
        case 'calendar':
          // eslint-disable-next-line global-require
          return require('../../assets/calendar_thumbnail.svg');
        case 'payments':
          // eslint-disable-next-line global-require
          return require('../../assets/payment_thumbnail.svg');
        case 'booking':
          // eslint-disable-next-line global-require
          return require('../../assets/booking_thumbnail.svg');
        case 'scheduling':
          // eslint-disable-next-line global-require
          return require('../../assets/booking_thumbnail.svg');
        case 'teamchat':
          // eslint-disable-next-line global-require
          return require('../../assets/team_chat_thumbnail.svg');
        case 'advertising':
          // eslint-disable-next-line global-require
          return require('../../assets/advertising_thumbnail.svg');
        case 'group_events':
          // eslint-disable-next-line global-require
          return require('../../assets/group_event.svg');
        case 'reviews':
          // eslint-disable-next-line global-require
          return require('../../assets/review.svg');
        case 'messages':
          // eslint-disable-next-line global-require
          return require('../../assets/messages.svg');
        case 'documents':
          // eslint-disable-next-line global-require
          return require('../../assets/document.svg');
        case 'social':
          // eslint-disable-next-line global-require
          return require('../../assets/social_content.svg');
        default:
          return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pane-row {
  cursor: pointer;
  border-bottom: 1px solid var(--gray-lighten-2);
  transition: 0.3s background-color;
  background: #FFFFFF;

  &__body {
    color: var(--gray-darken-4);
    text-align: start;

    &__title {
      font-weight: var(--font-weight-medium2);
      font-size: var(--font-size-x-small);
      line-height: var(--size-value5);
    }

    &__dot {
      width: var(--size-value5);
      min-width: var(--size-value5);
      height: var(--size-value5);
      border-color: #FFFFFF;
      transition: 0.3s border;

      span {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: var(--blue);
      }
    }

    &__description {
      font-weight: var(--font-weight-medium2);
      font-size: var(--font-size-x-small);;
      line-height: 18px;
      color: var(--gray-darken-3);
    }

    &__preview {
      border: 1px solid var(--gray-lighten-1);
      border-radius: var(--size-value1);
      font-weight: var(--font-weight-medium2);
      font-size: var(--font-size-xx-small);
      line-height: var(--size-value5);
    }

    &__time {
      font-weight: var(--font-weight-medium2);
      font-size: var(--font-size-xs-small);
      line-height: 14px;
      color: var(--gray-darken-2);
    }

    &--active {
      color: var(--gray-darken-5);

      &__time {
        color: var(--gray-darken-4);
      }

      &__description {
        color: var(--gray-darken-5);
      }
    }
  }

  &:hover:not(.mobile) {
    background: rgba(0, 0, 0, 0.04);

    .pane-row__body {
      &__dot {
        border-radius: 50%;
        border: 1px solid var(--gray);
      }
    }
  }

  &.mobile {
    .pane-row__body {
      &__dot {
        border-radius: 50%;
        border: 1px solid var(--gray);
      }
    }
  }
}

</style>
