import LayoutConstants from '@/constansts/layoutConstants';

const state = {
  topBarConfig: {
    show: true,
    showBackButton: false,
    avatar: null,
    title: '',
    subtitle: '',
    logo: '',
    firstButton: null,
    secondButton: null,
    textActionableButton: null,
    bottomSheetActions: [],
    dispatchActionType: LayoutConstants.POV_ACTION_TYPE,
  },
};

const helpers = {
  buildTopbarConfigWithNewBadge(oldTopBarConfig, isNewCounter) {
    const current = {
      ...oldTopBarConfig,
      firstButton: {
        ...oldTopBarConfig.firstButton,
        badgeValue: isNewCounter,
      },
    };
    return current;
  },
};

const mutations = {
  setTopBarConfig(state, topBarConfig) {
    state.topBarConfig = topBarConfig;
  },
  setTopBarTitle(state, title) {
    state.topBarConfig.title = title;
  },
  setTopBarSubtitle(state, subtitle) {
    state.topBarConfig.subtitle = subtitle;
  },
};

const getters = {
  // eslint-disable-next-line no-shadow
  topBarConfig: (state, getters, rootState, rootGetters) => {
    if (state.topBarConfig?.firstButton?.badgeValue !== undefined) {
      const counter = rootGetters['NotificationCenterStore/isNewCounter'];
      return helpers.buildTopbarConfigWithNewBadge(state.topBarConfig, counter);
    }
    return state.topBarConfig;
  },
};

const actions = {
  setTopBarConfig({ commit, rootGetters }, topBarConfig) {
    const config = { ...topBarConfig };
    if (topBarConfig.showTopBarBrandingLogo) {
      config.logo = rootGetters['BusinessStore/logo'];
    }
    commit('setTopBarConfig', config);
  },
  setTopBarTitle({ commit }, title) {
    commit('setTopBarTitle', title);
  },
  setTopBarSubtitle({ commit }, subtitle) {
    commit('setTopBarSubtitle', subtitle);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
