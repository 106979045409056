function processBuffer(buffer) {
  let remainingBuffer = buffer;
  let eolIndex = remainingBuffer.indexOf('\n');
  const parsedData = [];

  while (eolIndex !== -1) {
    const line = remainingBuffer.slice(0, eolIndex).trim();
    remainingBuffer = remainingBuffer.slice(eolIndex + 1);
    if (line) {
      const parsed = JSON.parse(line);
      parsedData.push(parsed);
    }
    eolIndex = remainingBuffer.indexOf('\n');
  }
  return { remainingBuffer, parsedData };
}

async function processStream(response, cb = () => null) {
  const reader = response.data.body.getReader();
  const decoder = new TextDecoder('utf-8');
  let buffer = '';
  let finishReasonMet = false;

  let accumulatedData = '';
  let reading = true;
  let { value, done } = await reader.read();
  while (reading) {
    if (done) {
      reading = false;
    }

    buffer += decoder.decode(value, { stream: true });
    const { remainingBuffer, parsedData } = processBuffer(buffer);
    buffer = remainingBuffer;
    for (let i = 0; i < parsedData.length; i++) {
      const parsed = parsedData[i];
      cb(parsed);
      finishReasonMet = finishReasonMet || parsed.finish_reason === 'stop';
      if (parsed.delta) {
        accumulatedData += parsed.delta;
      }
    }
    if (!done) {
      // eslint-disable-next-line no-await-in-loop
      ({ value, done } = await reader.read());
    }
  }
  if (!finishReasonMet) {
    throw new Error('Stream did not end with the expected \'stop\' finish reason.');
  }

  return accumulatedData;
}

export default {
  processStream,
};
