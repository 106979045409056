/** *************************************************************************************
 ***** DUPLICATE FILE ACROSS PROJECTS!!! SEARCH FOR THE NAME "thryvTealiumScript"   *****
 ************************************************************************************** */
class ThryvTealiumScript {
  static getScriptAsString({
    profileData, businessData, settingsData, staffData, numberOfStaffs,
  }) {
    return `
  var utag_data = {
    user_prop: {
      id: "${staffData.uid}",
      account_id: "${businessData.uid}",
      email: "${staffData.email}",
      first_name: "${staffData.first_name}",
      last_name: "${staffData.last_name}",
      full_name: "${staffData.display_name}",
      language: "${businessData.locale}",
      role: "${staffData.role}",
    },
    account_prop: {
      id: "${businessData.uid}",
      business_name: "${businessData.name}",
      channels: null, // should always be null
      create_date_time: "${businessData.created_at}",
      matter_term: "${settingsData.selected_term}",
      mc_package: null, // should always be null
      package: "${businessData.package_display_name}",
      staffs: "${numberOfStaffs}",
      tags: "${profileData.tags}",
      primary_payment_gateway: "${settingsData.payments_gateway_type}",
    },
    bc_user_email: "${staffData.email}"
  };
  (function(a,b,c,d){
    a='https://tags.tiqcdn.com/utag/dexmedia/thryvapp/prod/utag.js';
    b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;
    a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);
  })();
`;
  }
}

export default ThryvTealiumScript;
