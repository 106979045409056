import httpService from '@/modules/common/services/httpService';

const baseRoute = '/business/payments/v1/carts';

const createSale = async (matterUid, currency, items) => httpService.post(baseRoute, {
  is_sale: true,
  cart: {
    matter_uid: matterUid,
    currency,
    items,
  },
}).then((res) => res.data.data.cart);

const cancelSale = async (saleUid, payload) => httpService.put(`${baseRoute}/${saleUid}/cancel`, payload)
  .then((res) => res.data);

const completeSale = async (saleUid, payload) => httpService.put(`${baseRoute}/${saleUid}/cart_completed`, payload)
  .then((res) => res.data);

export default {
  createSale,
  cancelSale,
  completeSale,
};
