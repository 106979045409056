import paymentsService from '@/modules/payments/services/paymentsService';

const state = {
  scheduledPayments: {},
  payments: {},
  invoices: {},
  paymentStatuses: {},
};

const getters = {
  scheduledPayments: (state) => state.scheduledPayments,
  scheduledPaymentsByUid: (state) => (uid) => state.scheduledPayments[uid],
  paymentsByScheduledPaymentsUid: (state) => (uid) => Object.values(state.payments)
    .filter((payment) => payment.scheduled_payments_rule_uid === uid),
  paymentsByPaymentStatusUid: (state) => (uid) => Object.values(state.payments)
    .filter((payment) => payment.payment_request_uid === uid),
  paymentByUid: (state) => (uid) => state.payments[uid],
};

const mutations = {
  setScheduledPayments(state, scheduledPayments) {
    state.scheduledPayments = {
      ...state.scheduledPayments,
      [scheduledPayments.uid]: scheduledPayments,
    };
  },
  setPayments(state, payments) {
    payments.forEach((payment) => {
      state.payments = { ...state.payments, [payment.uid]: payment };
    });
  },
  updateInvoice(state, updatedFields) {
    state.invoices = { ...state.invoices, [updatedFields.uid]: { ...state.invoices[updatedFields.uid], ...updatedFields } };
  },
  updatePaymentStatus(state, updatedFields) {
    state.paymentStatuses = {
      ...state.paymentStatuses,
      [updatedFields.uid]: {
        ...state.paymentStatuses[updatedFields.uid],
        ...updatedFields,
      },
    };
  },
};

const actions = {
  async getScheduledPayments({ commit }, uid) {
    const scheduledPayments = await paymentsService.fetchScheduledPayments(uid);
    commit('setScheduledPayments', scheduledPayments);
  },
  async getPaymentsByScheduledPaymentsUid({ commit }, scheduledPaymentsUid) {
    const payments = await paymentsService.fetchPaymentsByScheduledPaymentsUid(scheduledPaymentsUid);
    commit('setPayments', [...payments]);
  },
  async getPaymentsByPaymentStatusUid({ commit }, paymentStatusUid) {
    const payments = await paymentsService.fetchPaymentsByPaymentStatusUid(paymentStatusUid);
    commit('setPayments', [...payments]);
  },
  async cancelScheduledPayments({ commit }, scheduledPaymentsUid) {
    const scheduledPayments = await paymentsService.cancelScheduledPayments(scheduledPaymentsUid);
    commit('setScheduledPayments', scheduledPayments);
  },
  // eslint-disable-next-line no-shadow
  async fetchPayment({ commit, getters }, { uid, forceFetch = true }) {
    const existingPayment = getters.paymentByUid(uid);
    if (!existingPayment || forceFetch) {
      const payment = await paymentsService.fetchPayment(uid);
      commit('setPayments', [payment]);
    }
  },
  async refundPayment({ dispatch }, payload) {
    const response = await paymentsService.refundPayment(payload);
    dispatch('fetchPayment', { uid: payload.refund.payment_uid });
    return response;
  },
  async cancelInvoice({ commit }, { payableUid, payload }) {
    const cancelInvoiceResponse = await paymentsService.cancelInvoice(payableUid, payload);
    commit('updateInvoice', cancelInvoiceResponse.data.invoice);
    return cancelInvoiceResponse;
  },
  async waivePayment({ commit }, { paymentStatusUid, payload }) {
    const waivePaymentResponse = await paymentsService.waivePayment(paymentStatusUid, payload);
    commit('updatePaymentStatus', waivePaymentResponse.data);
    return waivePaymentResponse;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
