class LayoutUtils {
  static isPassingFFCriteria(rootGetters, ffArray, allow) {
    if (ffArray && ffArray.length > 0) {
      if (allow) {
        return ffArray.every((element) => rootGetters['BusinessStore/hasFeature'](element));
      }
      return !ffArray.some((element) => rootGetters['BusinessStore/hasFeature'](element));
    }
    return true;
  }

  static shouldShowItemByPermission(rootGetters, permissionsArray, allow) {
    if (permissionsArray && permissionsArray.length > 0) {
      if (allow) {
        return permissionsArray.every((permission) => rootGetters['StaffStore/hasPermission'](permission));
      }
      return !permissionsArray.some((permission) => rootGetters['StaffStore/hasPermission'](permission));
    }
    return true;
  }
}
export default LayoutUtils;
