class ScriptInjectionUtils {
  static injectScriptToMainDom(scriptAsString, scriptId) {
    const isScriptAlreadyInjected = document.getElementById(scriptId);
    if (!isScriptAlreadyInjected) {
      const SCRIPT_ELEMENT_KEY = 'script';
      const ID_ELEMENT_KEY = 'id';
      const HEAD_ELEMENT_KEY = 'head';
      const scriptAsElement = document.createElement(SCRIPT_ELEMENT_KEY);
      scriptAsElement.innerHTML = scriptAsString;
      scriptAsElement.id = scriptId;
      scriptAsElement.setAttribute(ID_ELEMENT_KEY, scriptId);
      document.getElementsByTagName(HEAD_ELEMENT_KEY)[0].appendChild(scriptAsElement);
    }
  }
}

export default ScriptInjectionUtils;
