<template>
  <div :style="menuStyleVars" class="menu-wide">
    <VcLayout @click="redirectToHome" class="logo" column>
      <VcSkeleton v-if="isMenuLoading" type="button" width="100%"></VcSkeleton>
      <VcImage :image="logo" v-else-if="isLogo"/>
    </VcLayout>

    <VcLayout align-start class="quick-actions-container">
      <VcSkeleton v-if="isMenuLoading" class="quick-actions" type="chip" min-height="40" width="155px"></VcSkeleton>
      <QuickActions :is-actions-disabled="isActionsDisabled" v-else class="quick-actions"/>
    </VcLayout>

    <div class="menu-items-group">
      <VcMenuItem v-for="(menuItem, index) in (isMenuLoading ? skeletonMenuItems : mappedMenuItems)"
                  v-bind="menuItem"
                  flavor="desktop"
                  class="menu-item"
                  size="md"
                  :key="index"
                  :data-qa="`VcMenuItem-${menuItem.name}`"
                  :is-loading="isMenuLoading || menuItem.isLoading"
                  :isSelected="isSelected(menuItem)"
                  :selected-subitem="selectedSubItemIndex(menuItem)"
                  :is-expanded="isMenuItemExpanded(menuItem)"
                  :label="menuItem.label"
                  @click="onMenuItemClick(menuItem)"
                  @onSubmenuClicked="subItem => onMenuItemClick(subItem)"
                  @change="expanded => onExpandMenuItem(expanded, menuItem)">
        <template #actions v-if="menuItem.rightIcon">
            <VcButton small icon @click="onRightAreaClick(menuItem)">
              <template #prepend v-if="menuItem.rightIcon">
                <VcIcon class="right-icon" size="16" :color="textColor"
                        :data-qa="`right-area-vc-icon-${menuItem.name}`">{{ menuItem.rightIcon }}</VcIcon>
              </template>
            </VcButton>
        </template>
        <template #actions v-else-if="menuItem.name === 'checklist'">
            <VcProgressCircular v-if="checklistProgress.show"
              size="sm"
              :currentStep="checklistProgress.currentStep"
              :steps="checklistProgress.totalSteps"
              :fontColorByTheme="true"
            />
        </template>
        <template #actions v-else-if="menuItem.name === 'inbox'">
          <VcBadge v-if="conversationCounts"
                   inline
                   flavor="neutral"
                   data-qa="inbox-counter"
                   :badgeText="conversationCounts" />
        </template>
      </VcMenuItem>
    </div>
    <PoweredByContainer class="powered-by-container"/>
  </div>
</template>

<script>
import QuickActions from '@/modules/layout/quickActions/components/quickActions.vue';
import { mapGetters } from 'vuex';
import outgoingPostMessageUtils from '@/utils/eventCommunicationUtils/outgoingPostMessageUtils';
import PoweredByContainer from './PoweredByContainer.vue';

export default {
  name: 'MainMenuWide',
  components: { QuickActions, PoweredByContainer },
  data() {
    return {
      selectedItem: null,
      expandedMenuItems: [],
      queuedMenuItem: null,
    };
  },
  props: {
    mappedMenuItems: {
      type: Array,
      default: () => [],
    },
    checklistProgress: {
      type: Object,
      default: () => ({
        show: false,
        currentStep: 0,
        totalSteps: 0,
      }),
    },
    conversationCounts: {
      type: Number,
      default: 0,
    },
    isMenuLoading: {
      type: Boolean,
      default: true,
    },
    isActionsDisabled: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.$router.beforeEach(this.routeChange);
  },
  watch: {
    mappedMenuItems() {
      this.routeChange();
    },
    isAngularLoaded() {
      if (this.isAngularLoaded && this.queuedMenuItem) {
        this.selectMenuItem(this.queuedMenuItem);
      }
    },
  },
  computed: {
    ...mapGetters('BusinessStore', ['logo', 'primaryColor', 'textColor']),
    ...mapGetters('AngularStore', ['isAngularLoaded']),
    isLogo() {
      return this.logo;
    },
    menuStyleVars() {
      return {
        'background-color': this.primaryColor,
      };
    },
    skeletonMenuItems() {
      return Array.from(Array(10)).map(() => ({
        label: 'Label 1',
        isLoading: true,
        size: 'md',
        submenuItems: [],
        flavor: 'desktop',
      }));
    },
  },
  methods: {
    isSelected(item) {
      if (!this.selectedItem) return false;
      return (
        this.selectedItem.url_path.includes(item.url_path)
        || this.selectedItem.entity_path === item.entity_path
        || this.hasSelectedSubItem(item)
      );
    },
    redirectToHome() {
      outgoingPostMessageUtils.post('navigate-to', { route: 'app.dashboard' });
    },
    onMenuItemClick(item) {
      if (this.isAngularLoaded || item.type === 'pov') {
        this.selectMenuItem(item);
        this.queuedMenuItem = null;
      } else {
        this.queuedMenuItem = item;
      }
    },
    selectMenuItem(item) {
      if (!item.action && !item.dispatchAction) this.selectedItem = item;
      this.$emit('menuItemClick', item);
    },
    onRightAreaClick(item) {
      this.$emit('rightAreaClick', item);
    },
    selectedSubItemIndex(menuItem) {
      return menuItem.submenuItems?.findIndex((item) => (
        item.url_path === this.selectedItem?.url_path
        || item.entity_path === this.selectedItem?.entity_path
      ));
    },
    hasSelectedSubItem(menuItem) {
      return this.selectedSubItemIndex(menuItem) !== -1;
    },
    onExpandMenuItem(expanded, item) {
      if (expanded) {
        this.onMenuItemClick(item.submenuItems[0]);
        this.expandedMenuItems.push(item);
      } else {
        this.expandedMenuItems = this.expandedMenuItems.filter((menuItem) => menuItem !== item);
      }
    },
    isMenuItemExpanded(item) {
      return this.expandedMenuItems.includes(item);
    },
    routeChange(to, from, next = () => {}) {
      let pagePath = window.location.pathname + window.location.search; // in case of created state
      if (to?.path) {
        pagePath = `/app${to.path}`;
        if (to.query && to.query.length) {
          pagePath += `?${new URLSearchParams(to.query).toString()}`;
        }
      }
      this.mappedMenuItems.forEach((item) => {
        if (this.samePath(pagePath, item)) {
          this.selectedItem = item;
        } else if (item.submenuItems.length) {
          item.submenuItems.forEach((subItem) => {
            if (this.samePath(pagePath, subItem)) {
              this.selectedItem = subItem;
              this.expandedMenuItems.push(item);
            }
          });
        }
      });
      next();
    },
    samePath(pagePath, item) {
      return pagePath.includes(item.url_path) || item.entity_path?.some((path) => pagePath.includes(path));
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-wide{
  position: relative;
  width: 240px;
  overflow-y: hidden;
  height: 100%;
  text-align: start;
  display: flex;
  flex-direction: column;
  border-inline-end: 1px solid var(--gray-lighten-2);

  .quick-actions-container {
    flex: 0 0 var(--size-value15);
  }

  .powered-by-container {
    flex: 0 0 var(--size-value8);
    margin: 0;
  }
}

.logo {
  flex: 0 0 var(--size-value10);
  height: var(--size-value10);
  width: 174px;
  margin-inline-start: var(--size-value4);
  margin-top: var(--size-value4);
  cursor: pointer;

  &::v-deep {
    .VcImage {
      justify-content: start;

      .image {
       overflow: hidden;
      }
    }
  }
}

.quick-actions{
  margin-inline-start: var(--size-value4);
  margin-top: var(--size-value5);
}

.menu-items-group{
  margin-top: var(--size-value5);
  overflow-y: auto;
  flex: 1 1 0;

  ::v-deep {
    .right-icon {
      opacity: 0.72;
    }
  }
}

.menu-items-group::-webkit-scrollbar {
  width: 6px;
}
.menu-items-group::-webkit-scrollbar-thumb {
  background-color: var(--gray-darken-1);
  border-radius: 10px;
}
</style>
