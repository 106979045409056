import billingService from '@/modules/billing/services/billingService';

const state = {
  billingAccount: undefined,
};

const mutations = {
  setBillingAccount(state, value) {
    state.billingAccount = value;
  },
};

const actions = {
  async loadBillingAccount({ commit, state }) {
    if (!state.billingAccount) {
      const billingValue = await billingService.getBillingAccount();
      commit('setBillingAccount', billingValue);
    }
  },
};

const getters = {
  billingAccount: (state) => state.billingAccount,
  isValidBillingAccount: (state, _, globalGetters) => globalGetters.AppStore.billingApp && state.billingAccount && state.billingAccount.code !== 'error' && state.billingAccount.billing_app_account_data.validation_code === 'valid',
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
