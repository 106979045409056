import httpService from '@/modules/common/services/httpService';

const baseRoute = '/business/payments/v1/client_packages';

const fetchClientPackage = async (uid) => httpService.get(`${baseRoute}/${uid}`)
  .then((res) => res.data?.data?.client_package);

export default {
  fetchClientPackage,
};
