import AiError from '@/modules/aiContentGenerator/utils/errors/aiError';

export default class AiChatError extends AiError {
  constructor() {
    super({
      header: 'ai_content_generator.errors.init_chat.header',
      text: 'ai_content_generator.errors.init_chat.text',
    });
  }
}
