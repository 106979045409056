import outgoingPostMessageUtils from '@/utils/eventCommunicationUtils/outgoingPostMessageUtils';

class LoggerService {
  static POV_LOGGER_SEND_MESSAGE_EVENT = 'pov-logger-send-message';

  static POV_LOGGER_SEND_ERROR_EVENT = 'pov-logger-send-error';

  static log(label, message) {
    outgoingPostMessageUtils.post(LoggerService.POV_LOGGER_SEND_MESSAGE_EVENT, { label, message });
  }

  static error(label, params) {
    outgoingPostMessageUtils.post(LoggerService.POV_LOGGER_SEND_ERROR_EVENT, { label, params });
  }
}

export default LoggerService;
