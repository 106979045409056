import gatewayService from '@/modules/common/services/gatewayService';

const basePath = '/business/subscriptionsmng/v1/billing';
const getBillingAccount = async () => gatewayService.get(`${basePath}/accounts`)
  .then((res) => res.data.data.account)
  .catch((error) => {
    console.error(error);
    return { code: 'error' };
  });

export default {
  getBillingAccount,
};
