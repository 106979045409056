import paymentRequestService from '@/modules/paymentRequest/service/paymentRequestService';

const state = {
  paymentRequests: {},
  openPaymentRequests: {},
  paymentLink: null,
};

const getters = {
  getPaymentRequestByUid: (state) => (uid) => state.paymentRequests[uid],
  getOpenPaymentRequestsByMatterUid: (state) => (matterUid) => state.openPaymentRequests[matterUid],
  getPaymentLink: (state) => state.paymentLink,
};

const mutations = {
  setPaymentRequests(state, paymentRequests) {
    paymentRequests.forEach((paymentRequest) => {
      state.paymentRequests = { ...state.paymentRequests, [paymentRequest.uid]: paymentRequest };
    });
  },
  setOpenPaymentRequests(state, paymentRequests, matterUid) {
    state.openPaymentRequests = { ...state.openPaymentRequests, [matterUid]: paymentRequests };
  },
  setPaymentLink(state, link) {
    state.paymentLink = link;
  },
};

const actions = {
  async fetchPaymentRequestLink({ commit }, { paymentUid, clientUid, amount }) {
    const link = await paymentRequestService.fetchPaymentLink(paymentUid, clientUid, amount);
    commit('setPaymentLink', link);
  },
  async fetchPaymentRequest({ commit }, paymentStatusUid) {
    const paymentRequest = await paymentRequestService.fetchPaymentRequest(paymentStatusUid);
    commit('setPaymentRequests', [paymentRequest]);
  },
  // eslint-disable-next-line no-shadow
  async fetchPaymentRequests({ commit, getters }, { uids, forceFetch = true }) {
    const paymentRequestsToFetch = forceFetch ? uids : uids.filter((uid) => !getters.getPaymentRequestByUid(uid));
    if (paymentRequestsToFetch.length) {
      const paymentRequests = await Promise.all(
        paymentRequestsToFetch.map((uid) => paymentRequestService.fetchPaymentRequest(uid)),
      );
      commit('setPaymentRequests', paymentRequests);
    }
  },
  async fetchOpenPaymentRequests({ commit }, matterUid) {
    if (!matterUid) return;
    const paymentRequests = await paymentRequestService.fetchOpenPaymentRequests(matterUid);
    commit('setOpenPaymentRequests', paymentRequests, matterUid);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
