import saleService from '@/modules/payments/services/saleService';

const state = {
  sales: {},
};

const getters = {
  saleByUid: (state) => (uid) => state.sales[uid],
};

const mutations = {
  updateSales(state, updatedFields) {
    state.sales = { ...state.sales, [updatedFields.uid]: { ...state.sales[updatedFields.uid], ...updatedFields } };
  },
};

const actions = {
  async cancelSale({ commit }, { saleUid, payload }) {
    const cancelSaleResponse = await saleService.cancelSale(saleUid, payload);
    commit('updateSales', cancelSaleResponse.data.cart);
    return cancelSaleResponse;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
