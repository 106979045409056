import quickActionsService from '@/modules/layout/quickActions/services/quickActionsService';

const state = {
  quickActions: [],
  showDialogue: false,
};

const MAX_MAIN_ACTIONS = 6;

const mutations = {
  setQuickActions(state, quickActions) {
    state.quickActions = quickActions;
  },
  toggleShowDialogue(state) {
    state.showDialogue = !state.showDialogue;
  },
};

const getters = {
  quickActions: (state) => state.quickActions,
  showDialogue: (state) => state.showDialogue,
  mainActions: (state) => state.quickActions.filter((action) => action.type === 'usedIcons').slice(0, MAX_MAIN_ACTIONS),
  secondaryActions: (state) => state.quickActions,
};

const actions = {
  setQuickActions({ commit }, quickActions) {
    commit('setQuickActions', quickActions);
  },
  toggleShowDialogue({ commit }) {
    commit('toggleShowDialogue');
  },
  askForQuickActionsData() {
    quickActionsService.askForQuickActionsData();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
