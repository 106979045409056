<template>
  <div class="angular-placeholder">angular routing placeholder</div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AngularWrapper',
  created() {
    this.setIsAngularDisplayed(true);
  },
  methods: {
    ...mapActions('AngularStore', ['setIsAngularDisplayed']),
  },
  destroyed() {
    this.setIsAngularDisplayed(false);
  },
};
</script>

<style scoped>
.angular-placeholder {
  display: none;
}
</style>
