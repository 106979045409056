class LayoutConstants {
  static FRONTAGE_ACTION_TYPE = 'frontage';

  static POV_ACTION_TYPE = 'pov';

  static FRONTAGE_BUTTON_CLICK_ACTION = 'top-bar-button-clicked';

  static FRONTAGE_BOTTOM_SHEET_CLICK_ACTION = 'top-bar-bottom-sheet-action-clicked';

  static FRONTAGE_AVATAR_CLICK_ACTION = 'top-bar-avatar-clicked';

  static FRONTAGE_BACK_BUTTON_CLICK_ACTION = 'top-bar-back-button-clicked';

  static FRONTAGE_SYSTEM_NOTIFICATION_CLICK_ACTION = 'system-notification-clicked';

  static NAVIGATE_TO = 'navigate-to';

  static FIRE_ACTION = 'fire-action';

  static BASE_MATTER_TERM = 'clients';

  static BASE_MATTER_TERM_CAPITALIZED = 'Clients';

  static MATTER_TRANSLATIONS_KEYS = {
    clients: {
      navigation: 'common.navigation.clients',
      lowercase_singular: 'common.matter_terms.clients.lowercase_singular',
      lowercase_plural: 'common.matter_terms.clients.lowercase_plural',
      capitalized_singular: 'common.matter_terms.clients.capitalized_singular',
      capitalized_plural: 'common.matter_terms.clients.capitalized_plural',
      uppercase_singular: 'common.matter_terms.clients.uppercase_singular',
      uppercase_plural: 'common.matter_terms.clients.uppercase_plural',
    },
    patients: {
      navigation: 'common.navigation.matter_terms.patients',
      lowercase_singular: 'common.matter_terms.patients.lowercase_singular',
      lowercase_plural: 'common.matter_terms.patients.lowercase_plural',
      capitalized_singular: 'common.matter_terms.patients.capitalized_singular',
      capitalized_plural: 'common.matter_terms.patients.capitalized_plural',
      uppercase_singular: 'common.matter_terms.patients.uppercase_singular',
      uppercase_plural: 'common.matter_terms.patients.uppercase_plural',
    },
    students: {
      navigation: 'common.navigation.matter_terms.students',
      lowercase_singular: 'common.matter_terms.students.lowercase_singular',
      lowercase_plural: 'common.matter_terms.students.lowercase_plural',
      capitalized_singular: 'common.matter_terms.students.capitalized_singular',
      capitalized_plural: 'common.matter_terms.students.capitalized_plural',
      uppercase_singular: 'common.matter_terms.students.uppercase_singular',
      uppercase_plural: 'common.matter_terms.students.uppercase_plural',
    },
    pets: {
      navigation: 'common.navigation.matter_terms.pets',
      lowercase_singular: 'common.matter_terms.pets.lowercase_singular',
      lowercase_plural: 'common.matter_terms.pets.lowercase_plural',
      capitalized_singular: 'common.matter_terms.pets.capitalized_singular',
      capitalized_plural: 'common.matter_terms.pets.capitalized_plural',
      uppercase_singular: 'common.matter_terms.pets.uppercase_singular',
      uppercase_plural: 'common.matter_terms.pets.uppercase_plural',
    },
    cases: {
      navigation: 'common.navigation.matter_terms.cases',
      lowercase_singular: 'common.matter_terms.cases.lowercase_singular',
      lowercase_plural: 'common.matter_terms.cases.lowercase_plural',
      capitalized_singular: 'common.matter_terms.cases.capitalized_singular',
      capitalized_plural: 'common.matter_terms.cases.capitalized_plural',
      uppercase_singular: 'common.matter_terms.cases.uppercase_singular',
      uppercase_plural: 'common.matter_terms.cases.uppercase_plural',
    },
    projects: {
      navigation: 'common.navigation.matter_terms.projects',
      lowercase_singular: 'common.matter_terms.projects.lowercase_singular',
      lowercase_plural: 'common.matter_terms.projects.lowercase_plural',
      capitalized_singular: 'common.matter_terms.projects.capitalized_singular',
      capitalized_plural: 'common.matter_terms.projects.capitalized_plural',
      uppercase_singular: 'common.matter_terms.projects.uppercase_singular',
      uppercase_plural: 'common.matter_terms.projects.uppercase_plural',
    },
    properties: {
      navigation: 'common.navigation.matter_terms.properties',
      lowercase_singular: 'common.matter_terms.properties.lowercase_singular',
      lowercase_plural: 'common.matter_terms.properties.lowercase_plural',
      capitalized_singular: 'common.matter_terms.properties.capitalized_singular',
      capitalized_plural: 'common.matter_terms.properties.capitalized_plural',
      uppercase_singular: 'common.matter_terms.properties.uppercase_singular',
      uppercase_plural: 'common.matter_terms.properties.uppercase_plural',
    },
    jobs: {
      navigation: 'common.navigation.matter_terms.jobs',
      lowercase_singular: 'common.matter_terms.jobs.lowercase_singular',
      lowercase_plural: 'common.matter_terms.jobs.lowercase_plural',
      capitalized_singular: 'common.matter_terms.jobs.capitalized_singular',
      capitalized_plural: 'common.matter_terms.jobs.capitalized_plural',
      uppercase_singular: 'common.matter_terms.jobs.uppercase_singular',
      uppercase_plural: 'common.matter_terms.jobs.uppercase_plural',
    },
    accounts: {
      navigation: 'common.navigation.matter_terms.accounts',
      lowercase_singular: 'common.matter_terms.accounts.lowercase_singular',
      lowercase_plural: 'common.matter_terms.accounts.lowercase_plural',
      capitalized_singular: 'common.matter_terms.accounts.capitalized_singular',
      capitalized_plural: 'common.matter_terms.accounts.capitalized_plural',
      uppercase_singular: 'common.matter_terms.accounts.uppercase_singular',
      uppercase_plural: 'common.matter_terms.accounts.uppercase_plural',
    },
    opportunities: {
      navigation: 'common.navigation.matter_terms.opportunities',
      lowercase_singular: 'common.matter_terms.opportunities.lowercase_singular',
      lowercase_plural: 'common.matter_terms.opportunities.lowercase_plural',
      capitalized_singular: 'common.matter_terms.opportunities.capitalized_singular',
      capitalized_plural: 'common.matter_terms.opportunities.capitalized_plural',
      uppercase_singular: 'common.matter_terms.opportunities.uppercase_singular',
      uppercase_plural: 'common.matter_terms.opportunities.uppercase_plural',
    },
    vehicles: {
      navigation: 'common.navigation.matter_terms.vehicles',
      lowercase_singular: 'common.matter_terms.vehicles.lowercase_singular',
      lowercase_plural: 'common.matter_terms.vehicles.lowercase_plural',
      capitalized_singular: 'common.matter_terms.vehicles.capitalized_singular',
      capitalized_plural: 'common.matter_terms.vehicles.capitalized_plural',
      uppercase_singular: 'common.matter_terms.vehicles.uppercase_singular',
      uppercase_plural: 'common.matter_terms.vehicles.uppercase_plural',
    },
  };

  static APPLICATION_BASE_ROUTE = 'app.embedded';

  static APPLICATION_UPGRADE_ACTION = 'openUpgradeDialogue';

  static WAIT_TIME_FOR_ANGULAR_TO_LOAD = 1000 * 10;

  static MENU_DIRECTORY_WORK_AS = 'directory_work_as';

  static DIRECTORY_LOGIN_COOKIE = 'directory_login';

  static RESERVE_WITH_GOOGLE = 'reserve';

  static GOOGLE_INTEGRATION_POINT = 'old_integration_google';

  static UPGRADE_BUTTON_PACKAGES = ['trial', 'free_payments']

  static ROUTING_TYPES = {
    angular: 'angular',
    pov: 'pov',
  }

  static BOTTOM_NAV_ACTION_TYPES = {
    ANGULAR_NAVIGATION: 'angular-navigation',
    POV_NAVIGATION: 'pov-navigation',
    ANGULAR_ACTION: 'angular-action',
    POV_ACTION: 'pov-action',
  }
}

export default LayoutConstants;
