<template>
  <VcLayout v-if="currentState && currentComponent && isPermitted"
            column justify-start
            class="side-pane"
            :class="{ 'mobile': $vuetify.breakpoint.mdAndDown,
              'mobile__fullscreen': $vuetify.breakpoint.mdAndDown && !showBottomNav,
              'rtl': $vuetify.rtl,
              'angular-fullscreen': isFullscreen }">
    <VcSidepaneHeader :title="sidePaneTitle"
                      :showBackBtn="displayBack || $vuetify.breakpoint.mdAndDown"
                      :showCloseBtn="!$isMobile()"
                      @onCloseClicked="closeSidePane"
                      @onBackClicked="openPreviousSidePane"
                      dataQa="VcSidepaneHeader">
    </VcSidepaneHeader>
    <VcLayout column justify-start class="side-pane__entity-component">
      <component :uid="currentState.uid"
                 :is="currentComponent.component"
                 :key="currentState.type + currentState.uid"
                 :dataQa="currentState.type + currentState.uid"
                  @onClose="closeSidePane"/>
    </VcLayout>
  </VcLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import sidePaneComponentUtils from '@/modules/sidePane/utils/sidePaneComponentUtils';
import store from '@/store';

export default {
  name: 'SidePane',
  data() {
    return {
      componentMapping: {},
    };
  },
  created() {
    this.addI18nModule('side-pane-pov');
    this.componentMapping = sidePaneComponentUtils;
  },
  computed: {
    ...mapGetters('SidePaneStore', ['currentState', 'displayBack']),
    ...mapGetters('AngularStore', ['isFullscreen']),
    ...mapGetters('LayoutBottomNavStore', ['showBottomNav']),
    ...mapGetters('BusinessStore', ['attributes']),
    sidePaneTitle() {
      // eslint-disable-next-line localization/no-logic-in-translations
      return this.$t(this.currentComponent.title);
    },
    isPermitted() {
      const permittedByFeature = this.currentComponent.featureFlag ? this.$hasFeature(this.currentComponent.featureFlag) : true;
      const permittedByAttribute = this.currentComponent.attribute ? this.attributes[this.currentComponent.attribute] : true;
      return permittedByFeature && permittedByAttribute;
    },
    currentComponent() {
      return this.componentMapping[this.currentState.type];
    },
  },
  methods: {
    closeSidePane() {
      store.dispatch('SidePaneStore/close');
    },
    openPreviousSidePane() {
      store.dispatch('SidePaneStore/back');
    },
  },
};
</script>

<style lang="scss" scoped>
$side-pane-width: 344px;
$side-pane-margin-top: var(--vc-top-bar-height);
$side-pane-height: calc(100vh - #{$side-pane-margin-top});
$side-pane-transition-time: 0.3s;

$side-pane-mobile-bottom-height: 52px;
$side-pane-mobile-height: calc(100vh - #{$side-pane-mobile-bottom-height});

.side-pane {
  position: absolute;
  top: 0;
  right: 0;

  height: $side-pane-height;
  width: $side-pane-width;
  margin-top: $side-pane-margin-top;
  overflow-y: hidden;

  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);

  clip-path: inset(0px 0px 0px -20px);
  box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.12),
    2px 13px 19px 2px rgba(0, 0, 0, 0.14),
    0px 7px 8px -4px rgba(0, 0, 0, 0.20);

  transition: transform $side-pane-transition-time ease-out;
  &.angular-fullscreen {
    transition: transform $side-pane-transition-time ease-in;
    transform: translateX($side-pane-width);
  }

  &__entity-component {
    overflow: hidden;
    height: 100%;
  }

  &.mobile {
    $side-pane-width: 100%;
    margin: 0;
    width: $side-pane-width;
    height: $side-pane-mobile-height;
    &__fullscreen {
      height: 100vh;
    }

    &.angular-fullscreen {
      // angular mobile full screen set its layout z-index: 100
      // z-index: 101 to make sure side pane is on top of layout on transition
      z-index: 101;
      transition: transform $side-pane-transition-time ease-in;
      transform: translateX($side-pane-width);

      &.rtl {
        transform: translateX(-$side-pane-width);
      }
    }
  }

  &.rtl {
    right: auto;
    left: 0;
    box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.12),
      -2px 13px 19px 2px rgba(0, 0, 0, 0.14),
      0px 7px 8px -4px rgba(0, 0, 0, 0.2);
    clip-path: inset(0px -20px 0px 0px);

    &.angular-fullscreen {
      transition: transform $side-pane-transition-time ease-in;
      transform: translateX(-$side-pane-width);
    }
  }
}

</style>
