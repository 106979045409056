import productOrderService from '@/modules/productOrder/service/productOrderService';

const state = {
  productOrders: {},
};

const getters = {
  getProductOrderByUid: (state) => (uid) => state.productOrders[uid],
};

const mutations = {
  setProductOrders(state, productOrders) {
    productOrders.forEach((productOrder) => {
      state.productOrders = { ...state.productOrders, [productOrder.id]: productOrder };
    });
  },
};

const actions = {
  // eslint-disable-next-line no-shadow
  async fetchProductOrders({ commit, getters }, { uids, forceFetch = true }) {
    const productOrdersToFetch = forceFetch ? uids : uids.filter((uid) => !getters.getProductOrderByUid(uid));
    if (productOrdersToFetch.length) {
      const productOrders = await Promise.all(
        productOrdersToFetch.map((uid) => productOrderService.fetchProductOrder(uid)),
      );
      commit('setProductOrders', productOrders);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
