import productsService from '@/modules/products/service/productsService';

const PAGE_SIZE = 25;

const initialState = {
  products: [],
  loading: false,
  currentPageIndex: 1,
  completed: false,
  refreshProducts: false,
};

const state = { ...initialState };

const mutations = {
  addProducts(state, products) {
    state.products = state.products.concat(products);
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setCompleted(state, completed) {
    state.completed = completed;
  },
  increaseCurrentPageIndex(state) {
    state.currentPageIndex += 1;
  },
  clearData(state) {
    Object.assign(state, initialState);
  },
  setRefreshProducts(state, refreshProducts) {
    state.refreshProducts = refreshProducts;
  },
};

const actions = {
  /* eslint-disable no-empty-pattern */
  async createProduct({}, product) {
    const res = await productsService.createProduct({ product });
    return res;
  },
  async loadProducts({ state, dispatch }) {
    if (state.products.length && !state.refreshProducts) return;
    await dispatch('loadMoreProducts');
  },
  async loadMoreProducts({ commit, state, rootGetters }) {
    if (state.refreshProducts) {
      commit('clearData', false);
    }
    if (state.completed || state.loading) return;
    commit('setLoading', true);

    try {
      const businessId = rootGetters['AuthStore/businessUid'];
      const products = await productsService.fetchProducts(businessId, state.currentPageIndex, PAGE_SIZE);
      commit('addProducts', products);
      commit('increaseCurrentPageIndex');
      if (products.length < PAGE_SIZE) {
        commit('setCompleted', true);
      }
    } catch (e) {
      console.log('Error loading products', e); // eslint-disable-line no-console
    }

    commit('setLoading', false);
  },
  setRefreshProducts({ commit }, refreshProducts) {
    commit('setRefreshProducts', refreshProducts);
  },
};

const getters = {
  products: (state) => state.products,
  getProductByUid: (state) => (uid) => state.products.find((product) => product.id === uid),
  loading: (state) => state.loading,
  completed: (state) => state.completed,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
