const routes = [
  {
    path: 'calls_and_texting/settings',
    component: () => import(/* webpackChunkName: "CallsTextingSettings" */ './views/CallsTextingSettings.vue'),
    name: 'callsTextingSettings',
    props: (route) => ({ ...route.query, ...route.params }),
  },
];

export default routes;
