import AiError from '@/modules/aiContentGenerator/utils/errors/aiError';

export default class AiTimeoutError extends AiError {
  constructor() {
    super({
      header: 'ai_content_generator.errors.http_timeout.header',
      text: 'ai_content_generator.errors.http_timeout.text',
    });
  }
}
