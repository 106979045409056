const DISCOUNT_TYPES = {
  fixed: 'fixed',
  percentage: 'percentage',
};

const ACTIVITY_TYPES = {
  Meeting: 'Meeting',
  EventAttendance: 'EventAttendance',
  ClientBookingPackage: 'ClientBookingPackage',
  ProductOrder: 'ProductOrder',
};

const ENTITY_TYPES = {
  Service: 'Service',
  Product: 'Product',
  Custom: 'Custom',
};

const CHECKOUT_ITEM_TYPES = {
  ...ACTIVITY_TYPES,
  ...ENTITY_TYPES,
};

const MODALS = {
  ClientPicker: 'ClientPicker',
  PaymentWizard: 'PaymentWizard',
  QrCode: 'sale-qr-dialog',
  SendLinkSuccess: 'SendLinkSuccess',
  TakePayment: 'CloseBalance',
};

const CHECKOUT_ACTIONS = {
  FreeCheckout: 'freeCheckout',
  Record: 'record',
  Charge: 'charge',
  QrCode: 'qr',
  SendLink: 'send',
};

export {
  DISCOUNT_TYPES,
  ACTIVITY_TYPES,
  ENTITY_TYPES,
  CHECKOUT_ITEM_TYPES,
  MODALS,
  CHECKOUT_ACTIONS,
};
