import { render, staticRenderFns } from "./AngularWrapper.vue?vue&type=template&id=b282c8ce&scoped=true"
import script from "./AngularWrapper.vue?vue&type=script&lang=js"
export * from "./AngularWrapper.vue?vue&type=script&lang=js"
import style0 from "./AngularWrapper.vue?vue&type=style&index=0&id=b282c8ce&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b282c8ce",
  null
  
)

export default component.exports