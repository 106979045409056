import axios from 'axios';
import httpInterceptors from './httpInterceptorConfiguration';

const abortController = new AbortController();

const get = (service) => (route, params, paramsSerializer) => service.get(route, {
  params,
  paramsSerializer,
}).then((res) => res);

const post = (service) => (route, params) => service.post(route, params).then((res) => res);

const put = (service) => (route, params) => service.put(route, params).then((res) => res);

const remove = (service) => (route, params) => service.delete(route, params).then((res) => res);

const interceptors = (service) => service.interceptors;

function createHttpClient(basePath, credentials, config) {
  const baseURL = basePath || ((window.pov_config.NODE_ENV === 'development') ? window.pov_config.VUE_APP_API_URL : '/api2/');
  return axios.create({
    baseURL,
    withCredentials: credentials,
    ...config,
  });
}

const init = (basePath, credentials, config) => {
  const httpClient = createHttpClient(basePath, credentials, config);
  httpInterceptors.init(httpClient);
  return httpClient;
};

const create = (basePath, credentials = false, config = {}) => {
  const service = init(basePath, credentials, config);

  return {
    get: get(service),
    post: post(service),
    put: put(service),
    remove: remove(service),
    interceptors: interceptors(service),
    abortController,
  };
};

const coreService = create();

export default {
  ...coreService,
  create,
};
