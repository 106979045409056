import store from '@/store';
import outgoingPostMessageUtils from '@/utils/eventCommunicationUtils/outgoingPostMessageUtils';

const handleNavigation = (payload, router) => {
  const {
    name, path, params, queryParams,
  } = payload;

  let isGoingToAngular = false;

  if (name) {
    router.push({ name, params, query: queryParams });
  } else if (path) {
    let pathForPov = path;
    if (path.startsWith('/app/')) {
      pathForPov = path.replace('/app', '');
    }
    // Sometime Angular requests navigation without knowing if the route is Angular or not, so we need to check it here
    // Specifically, this is to serve app market where an app may lead to pov or to angular
    const goingTo = router.resolve(pathForPov);
    isGoingToAngular = goingTo.route.meta.isAngular;
    if (isGoingToAngular) {
      outgoingPostMessageUtils.post('deep-link', { path, queryParams });
    } else {
      router.push({ path: pathForPov, query: queryParams });
    }
  }

  if (!isGoingToAngular) {
    store.dispatch('AngularStore/setIsNavigatingToAngular', false);
  }
};

export default handleNavigation;
