import BottomNavUtils from '@/modules/layout/bottomNav/utils/bottomNavUtils';
import bottomNavItems from '@/modules/layout/bottomNav/bottomNavItems';

const state = {
  bottomNavConfig: {
    show: true,
    bottomNavTabs: bottomNavItems,
    features: [],
    permissions: [],
    currentIndex: undefined,
  },
  isLoading: true,
};

const mutations = {
  setShow(state, show) {
    state.bottomNavConfig.show = show;
  },
  setBottomNavTabs(state, tabs) {
    state.bottomNavConfig.bottomNavTabs = tabs;
    state.isLoading = false;
  },
  setCurrentIndex(state, newIndex) {
    if (newIndex < 0) {
      state.bottomNavConfig.currentIndex = undefined;
    } else {
      state.bottomNavConfig.currentIndex = newIndex;
    }
  },
  setInboxTabConversationCounts(state, counter) {
    const inboxTabIndex = state.bottomNavConfig.bottomNavTabs.findIndex((tab) => tab.name === 'inbox');
    state.bottomNavConfig.bottomNavTabs[inboxTabIndex].badgeValue = counter;
  },
};

const getters = {
  bottomNavTabs: (state) => state.bottomNavConfig.bottomNavTabs,
  showBottomNav: (state) => state.bottomNavConfig.show,
  currentIndex: (state) => state.bottomNavConfig.currentIndex,
  isLoading: (state) => state.isLoading,
};

const actions = {
  async createBottomNavTabs({ rootGetters, commit }) {
    const tabs = BottomNavUtils.getAggregatedTabs(rootGetters);
    commit('setBottomNavTabs', tabs);
  },
  setBottomNavConfig({ commit }, bottomNavConfig) {
    commit('setShow', bottomNavConfig.show);
  },
  setBottomNavStateChange({ commit }, stateName) {
    const index = BottomNavUtils.getTabIndexForAction(state, stateName);
    commit('setCurrentIndex', index);
  },
  async updateInboxTabConversationCounts({ rootGetters, dispatch, commit }) {
    const isInboxExist = state.bottomNavConfig.bottomNavTabs.some((tab) => tab.name === 'inbox');
    if (isInboxExist) {
      await dispatch({ type: 'ConversationStore/fetchConversationCounts' }, { root: true });
      const counter = rootGetters['ConversationStore/counts'];
      commit('setInboxTabConversationCounts', counter);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
