import bottomNavItemsOverrides from '@/modules/layout/bottomNav/bottomNavItemsOverrides';
import bottomNavItems from '@/modules/layout/bottomNav/bottomNavItems';
import LayoutConstants from '@/constansts/layoutConstants';
import LayoutUtils from '@/modules/layout/utils/layoutUtils';

class BottomNavUtils {
  static getTabsToOverrideList(rootGetters) {
    const tabOverrides = bottomNavItemsOverrides;
    return tabOverrides.filter((tab) => (
      LayoutUtils.isPassingFFCriteria(rootGetters, tab.allowByFFs, true)
      && LayoutUtils.isPassingFFCriteria(rootGetters, tab.hideByFFs, false)
      && LayoutUtils.shouldShowItemByPermission(rootGetters, tab.allowByPermissions, true)
      && LayoutUtils.shouldShowItemByPermission(rootGetters, tab.hideByPermissions, false)
    ));
  }

  static getTabByIndexOrOverride(tabsToOverride, defaultTab) {
    const overrideTab = tabsToOverride.find((tab) => tab.idToOverride === defaultTab.id);
    if (overrideTab) {
      return overrideTab;
    }
    return defaultTab;
  }

  static getAggregatedTabs(rootGetters) {
    const defaultTabs = BottomNavUtils.getDefaultTabs(rootGetters);
    const tabsToOverrideList = BottomNavUtils.getTabsToOverrideList(rootGetters);
    return defaultTabs.map((tab) => BottomNavUtils.getTabByIndexOrOverride(tabsToOverrideList, tab));
  }

  static tabHasAction(tab, action) {
    return tab.action === action || tab.extraActions?.includes(action);
  }

  static getTabIndexForAction(state, stateName) {
    return state.bottomNavConfig.bottomNavTabs.findIndex((tab) => this.tabHasAction(tab, stateName));
  }

  static getMattersTab(rootGetters, clientsTab) {
    if (rootGetters['BusinessStore/matterTerm'] !== LayoutConstants.BASE_MATTER_TERM) {
      const matterTermTranslation = rootGetters['BusinessStore/matterTermTranslation'];
      const label = matterTermTranslation?.navigation || LayoutConstants.BASE_MATTER_TERM_CAPITALIZED;
      const icon = rootGetters['BusinessStore/matterTermIcon'];
      return {
        ...clientsTab,
        label,
        icon,
      };
    }
    return clientsTab;
  }

  static getDefaultTabs(rootGetters) {
    const MATTER_TAB_NAME = 'clients';
    const tabs = bottomNavItems;
    const clientsTab = bottomNavItems.find((tab) => tab.name === MATTER_TAB_NAME);
    const mattersTab = BottomNavUtils.getMattersTab(rootGetters, clientsTab);
    return tabs.map((tab) => (tab.name === MATTER_TAB_NAME ? mattersTab : tab));
  }
}

export default BottomNavUtils;
