const state = {
  stateHistory: [],
};

const getters = {
  stateHistory: (state) => state.stateHistory,
  currentState: (state) => state.stateHistory[state.stateHistory.length - 1],
  displayBack: (state) => state.stateHistory.length > 1,
};

const mutations = {
  setStateHistory(state, stateHistory) {
    state.stateHistory = stateHistory;
  },
};

const actions = {
  newPane({ commit }, sidePane) {
    commit('setStateHistory', [sidePane]);
  },
  addPane({ commit }, sidePane) {
    // TODO: before using this, check Jira ticket - V-35993
    commit('setStateHistory', [...state.stateHistory, sidePane]);
  },
  back({ commit }) {
    state.stateHistory.pop();
    commit('setStateHistory', state.stateHistory);
  },
  close({ commit }) {
    commit('setStateHistory', []);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
