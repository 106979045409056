const getCurrency = (country, continent) => {
  if (country === 'united kingdom') {
    return 'GBP';
  }
  if (continent === 'europe') {
    return 'EUR';
  }

  return 'USD';
};

export default {
  getCurrency,
};
