import accountService from '@/modules/account/services/accountService';
import currencyUtil from '@/modules/currency/util/currencyUtil';

const state = {
  geoData: undefined,
};

const mutations = {
  setAccountGeoData(state, geoData) {
    state.geoData = geoData;
  },
};

const actions = {
  async getAccountCurrency({ commit, state }) {
    let { geoData } = state;
    if (!state.geoData) {
      geoData = await accountService.getGeoDataByIP();
      commit('setAccountGeoData', geoData);
    }
  },
};

const getters = {
  geoData: (state) => state.geoData,
  accountCurrency: (state) => (state.geoData ? currencyUtil.getCurrency(state.geoData.country, state.geoData.continent) : undefined),
  country: (state) => state.geoData.country,
  continent: (state) => state.geoData.continent,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
