const state = {
  layoutActionsDisabled: true,
};

const getters = {
  isLayoutActionsDisabled: (state) => state.layoutActionsDisabled,
};

const mutations = {
  setLayoutActionsDisabled(state, isDisabled) {
    state.layoutActionsDisabled = isDisabled;
  },
};

const actions = {
  async refreshLayoutData({ dispatch }) {
    await dispatch({ type: 'BusinessStore/fetchAttributes' }, { root: true });
    await dispatch({ type: 'BusinessStore/fetchBranding' }, { root: true });
    await dispatch({ type: 'LayoutBottomNavStore/createBottomNavTabs' }, { root: true });
    await dispatch({ type: 'MainMenuStore/aggregateMenuItemsForStaff' }, { root: true });
  },
  checkLayoutActionsDisabled({ rootGetters, commit }) {
    const isStaticPage = rootGetters['BusinessStore/hasFeature']('static_page');
    const isAccountBlocked = rootGetters['BusinessStore/attributes']?.account_blocked;
    commit('setLayoutActionsDisabled', isStaticPage || isAccountBlocked);
  },
  setDocumentTitle(_, title) {
    document.title = title;
  },
};

export default {
  namespaced: true,
  mutations,
  getters,
  state,
  actions,
};
