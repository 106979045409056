import BusinessDateFormats from '@/modules/common/filters/BusinessDateFormats';
import storageService from '@/modules/common/services/storageService';
import httpService from '@/modules/common/services/httpService';

const LOCAL_STORAGE_EXPIRY_TTL = 1000 * 60 * 60 * 12; // equals 12 hours in milliseconds
const DEFAULT_POLLING_TIMEOUT = 30000;
const DAY_IN_MILLISECONDS = 1000 * 3600 * 24;
const baseRoute = 'business/notificationscenter';
const notificationsRoute = 'v1/notifications';
const notificationsCounterRoute = 'v1/notificationscounters';
const typesRoute = 'v1/notificationstype';
const translationsRoute = 'v1/translations';
const notificationsMetadataRoute = 'v1/notificationsmetadata';

// TODO: replace this with import gatewayService from '@/modules/common/services/gatewayService';
const gatewayService = httpService.create(window.pov_config.VUE_APP_API_URL_GATEWAY);
const notificationsService = httpService.create(window.pov_config.VUE_APP_NOTIFICATION_CENTER_URL);

const pollingAbortController = notificationsService.abortController;
const extractedResponse = (response) => response?.data?.data || {};

const notificationsAbortSignal = (msTimeout = 0) => {
  setTimeout(() => pollingAbortController.abort(), msTimeout);
  return pollingAbortController.signal;
};

const getIsNewNotificationCounter = async (currentCounter, staffUid) => notificationsService.get(
  `${baseRoute}/${notificationsCounterRoute}/${currentCounter}/${staffUid}?filter[is_new][eq]=1`,
  {
    headers: {
      'Content-Security-Policy': "connect-src 'none'; script-src 'none'",
    },
    signal: notificationsAbortSignal(DEFAULT_POLLING_TIMEOUT),
  },
)
  .then((res) => extractedResponse(res).counters?.new_notifications_count);

const getNotifications = async (numberOfDays, perPage, page) => {
  // TODO: is this correct in terms of DST and timezones?
  const minDate = new Date(new Date().getTime() - DAY_IN_MILLISECONDS * numberOfDays).toISOString().split('T')[0];
  const getParams = {
    'filter[created_at][gte]': minDate,
    sort: 'created_at:desc',
    per_page: perPage,
    page,
  };
  return gatewayService.get(
    `${baseRoute}/${notificationsRoute}`,
    { ...getParams },
  ).then((res) => extractedResponse(res).notifications);
};

const getNotificationsTypes = async () => gatewayService.get(`${baseRoute}/${typesRoute}`)
  .then((res) => extractedResponse(res).notifications_types);

const getNotificationsMetadata = async () => gatewayService.get(`${baseRoute}/${notificationsMetadataRoute}`)
  .then((res) => extractedResponse(res).notifications_metadata);

const getNotificationsTranslations = async () => gatewayService.get(`${baseRoute}/${translationsRoute}`)
  .then((res) => extractedResponse(res).notifications_translations);

const fetchDataFromDB = async (localStorageKey, getDataFromDBfunc) => {
  const data = await getDataFromDBfunc();
  storageService.saveWithExpiry(localStorageKey, data, LOCAL_STORAGE_EXPIRY_TTL);
  return data;
};

const resetNotificationCounter = async () => gatewayService.put(
  `${baseRoute}/${notificationsRoute}/?filter[is_new][eq]=1`,
  {
    is_new: false,
  },
)
  .then((res) => res.data);

const setNotificationReadStatus = async (notificationUid, status) => gatewayService.put(
  `${baseRoute}/${notificationsRoute}/${notificationUid}`,
  {
    read: status,
  },
)
  .then((res) => res.data);

const setAllNotificationsAsRead = async () => gatewayService.put(
  `${baseRoute}/${notificationsRoute}?filter[read][eq]=0`,
  {
    read: true,
  },
)
  .then((res) => res.data);

const cancelRequest = () => {
  pollingAbortController.abort();
};
const buildBodyAppointmentBookingReminder = (notification) => {
  const timeRegEx = /^(2[0-3]|[01][0-9]):?([0-5][0-9])$/;

  const splitedBody = notification.text_sent.body.split(' ');
  const timeIndex = splitedBody.findIndex((curr) => timeRegEx.test(curr));
  const formattedTime = BusinessDateFormats.time(new Date(`2022 ${splitedBody[timeIndex]}`));
  splitedBody[timeIndex] = formattedTime;
  const body = splitedBody.join(' ');
  return body;
};

const buildTitleAppointmentBookingReminder = (notification) => {
  let title;

  const splittedTitle = notification.text_sent.title.split(' ');
  const res = BusinessDateFormats.time_at(new Date(`${splittedTitle[splittedTitle.length - 2]} ${splittedTitle[splittedTitle.length - 1]}`));
  const splitByComma = res.split(', ');
  const splitBySpace = res.split(' ');

  if (splitByComma.length > 1) {
    const formattedDate = `${splitByComma[0]}, ${splitByComma[1]}`;
    const newArray = splittedTitle.splice(0, splittedTitle.length - 2);
    newArray.push(formattedDate);
    title = newArray.join(' ');
  } else if (splitBySpace.length > 1) {
    const formattedDate = `${splitBySpace[0]} ${splitBySpace[1]} ${splitBySpace[2]}`;
    const newArray = splittedTitle.splice(0, splittedTitle.length - 2);
    newArray.push(formattedDate);
    title = newArray.join(' ');
  }

  return title;
};

const getCurrentEntityData = async (localStorageKey, getEntityDataFromDBFunc) => {
  let data = storageService.getWithExpiry(localStorageKey);
  if (!data) {
    data = await fetchDataFromDB(localStorageKey, getEntityDataFromDBFunc);
  }
  return data;
};

const isAllRelevantUidsExist = (currentEntityData, currentExistingUidsFromRelatedItems) => {
  const currentExistingUids = currentEntityData.map((data) => data.uid);
  const allUidsExists = currentExistingUidsFromRelatedItems.every((uid) => currentExistingUids.includes(uid));
  return allUidsExists;
};

const getAllRelevantDataForEntity = async (allRelatedItems, entityUidKey, localStorageKey, getEntityDataFromDBFunc) => {
  const currentExistingUidsFromRelatedItems = [...new Set(allRelatedItems.map((relatedItem) => relatedItem[entityUidKey]))];
  let currentEntityData = await getCurrentEntityData(localStorageKey, getEntityDataFromDBFunc);
  const isAllUidsExists = isAllRelevantUidsExist(currentEntityData, currentExistingUidsFromRelatedItems);
  if (!isAllUidsExists) {
    currentEntityData = await fetchDataFromDB(localStorageKey, getEntityDataFromDBFunc);
  }
  return currentEntityData;
};

const getAllRelevantMetadata = async (allNotifications) => getAllRelevantDataForEntity(allNotifications, 'notification_metadata_uid', 'notificationsMetadata', getNotificationsMetadata);
const getAllRelevantTypes = async (allMetadata) => getAllRelevantDataForEntity(allMetadata, 'notification_type_uid', 'notificationsTypes', getNotificationsTypes);

export default {
  getIsNewNotificationCounter,
  getNotifications,
  getNotificationsTypes,
  getNotificationsMetadata,
  getNotificationsTranslations,
  getAllRelevantMetadata,
  getAllRelevantTypes,
  resetNotificationCounter,
  cancelRequest,
  setNotificationReadStatus,
  setAllNotificationsAsRead,
  buildTitleAppointmentBookingReminder,
  buildBodyAppointmentBookingReminder,
};
