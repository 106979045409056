const state = {
  visible: false,
  launched: false,
};

const getters = {
  isVisible: (state) => state.visible,
  isLaunched: (state) => state.launched,
};

const mutations = {
  setVisibility: (state, visible) => {
    state.launched = true;
    state.visible = Boolean(visible);
  },
  toggleVisibility: (state) => {
    state.launched = true;
    state.visible = !state.visible;
  },
  setLaunched: (state, launched) => {
    state.launched = Boolean(launched);
  },
};

const actions = {
  setVisibility: ({ commit }, visible) => {
    commit('setVisibility', visible);
  },
  toggleVisibility: ({ commit }) => {
    commit('toggleVisibility');
  },
  setLaunched: ({ commit }, launched) => {
    commit('setLaunched', launched);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
