import Vue, { h } from 'vue';
import VueRouter from 'vue-router';
import outgoingPostMessageUtils from '@/utils/eventCommunicationUtils/outgoingPostMessageUtils';
import AngularWrapper from '@/modules/angularDelegator/components/AngularWrapper.vue';
import notificationsRoutes from '@/modules/notificationCenter/routes';
import callsTextingRoutes from '@/modules/callsTexting/routes';
import rolesAndPermissionsRoutes from '@/modules/rolesAndPermissions/routes';
import crmRoutes from '@/modules/crm/routes';
import paymentsRoutes from '@/modules/payments/routes';
import paymentSettingsRoutes from '@/modules/paymentSettings/routes';
import payGateProxyRoutes from '@/modules/payGateProxy/routes';
import addonsPurchased from '@/modules/addonsPurchased/routes';
import reserveWithGoogleRoutes from '@/modules/reserveWithGoogle/routes';
import embeddedApps from '@/modules/app/routes';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  ...notificationsRoutes,
  ...crmRoutes,
  ...rolesAndPermissionsRoutes,
  ...paymentsRoutes,
  ...paymentSettingsRoutes,
  ...addonsPurchased,
  ...payGateProxyRoutes,
  ...reserveWithGoogleRoutes,
  ...embeddedApps,
  {
    path: '/app_market',
    component: { render: () => h('router-view') },
    children: [
      ...callsTextingRoutes,
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'angular',
    component: AngularWrapper,
    meta: { isAngular: true },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/app',
  routes,
});

// Identify when the user clicks the back button
let previousRoute = null;
let popStateDetected = false;
window.addEventListener('popstate', () => {
  popStateDetected = true;
});

router.handleBack = ({ replace = false } = {}) => {
  if (previousRoute?.name) {
    if (replace) {
      router.replace(previousRoute.fullPath);
    } else {
      router.go(-1);
    }
  } else {
    outgoingPostMessageUtils.post('navigate-to', { route: 'app.dashboard' });
  }
};

router.beforeEach((to, from, next) => {
  previousRoute = from;
  const isItABackButton = popStateDetected;
  popStateDetected = false;

  const isAngularDisplayed = store.getters['AngularStore/isAngularDisplayed'];
  const isGoingToAngularPage = !!to.meta.isAngular;

  // Case of going back to Angular page from POV page
  if (isItABackButton && !isAngularDisplayed && isGoingToAngularPage) {
    outgoingPostMessageUtils.post('get-top-bar-config');
  }

  if (isItABackButton && isAngularDisplayed && isGoingToAngularPage && to.path !== from.path) {
    // Ask the iframe to go back
    outgoingPostMessageUtils.post('navigation-back');
  }
  next();
});

export default router;
