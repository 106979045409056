import { render, staticRenderFns } from "./AngularDelegator.vue?vue&type=template&id=221a213a&scoped=true"
import script from "./AngularDelegator.vue?vue&type=script&lang=js"
export * from "./AngularDelegator.vue?vue&type=script&lang=js"
import style0 from "./AngularDelegator.vue?vue&type=style&index=0&id=221a213a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "221a213a",
  null
  
)

export default component.exports