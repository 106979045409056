import matterService from '@/modules/matter/service/matterService';

const state = {
  matters: {},
};

const getters = {
  matterByUid: (state) => (uid) => state.matters[uid],
};

const mutations = {
  setMatter(state, matter) {
    state.matters = { ...state.matters, [matter.uid]: matter };
  },
};

const actions = {
  async getMatter({ commit }, uid) {
    const matter = await matterService.fetchMatter(uid);
    commit('setMatter', matter);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
