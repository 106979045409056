<template>
  <VcLayout  v-if="shouldDisplayPoweredBy" row justify-center
            :class="computedParentClasses">
    <VcPoweredBy
      v-for="item in poweredByArray"
      :key="item.image"
      class="powered-by-component"
      justify-center
      :poweredByText="item.text"
      :image="item.image"
      :size="item.size"
      :text-color="item.textColor">
    </VcPoweredBy>
  </VcLayout>
</template>

<script>
import intandemLogo from '@/assets/images/intandem-logo-yellow.png';
import vcitaLogo from '@/assets/images/vc-logo.svg';
import { mapGetters } from 'vuex';

const WHITE_COLOR_FORMATS = ['#ffffff', '#fff', '#ffffffff', 'rgb(255, 255, 255)'];
export default {
  name: 'PoweredByContainer',
  computed: {
    ...mapGetters('BusinessStore', ['attributes', 'primaryColor', 'logo', 'parentPoweredByLogo', 'directorySettings', 'inTandemPoweredByLogo', 'textColor']),
    hasParentDirectory() {
      return !!this.directorySettings.has_parent_directory;
    },
    poweredBySize() {
      return this.$isMobile() ? 'md' : 'sm';
    },
    isParentDirectoryPoweredByDarkColored() {
      return this.$isMobile() || WHITE_COLOR_FORMATS.includes(this.primaryColor.toLowerCase());
    },
    shouldDisplayPoweredBy() {
      return this.directorySettings.menu_powered_by || this.hasParentDirectory;
    },
    inTandemTranslation() {
      return this.$t('common.powered_by_intandem');
    },
    vcitaTranslation() {
      return this.$t('common.powered_by_vcita');
    },
    poweredByImage() {
      if (this.hasParentDirectory) {
        return this.inTandemPoweredByLogo;
      }
      return this.$hasFeature('intandem_enabled') ? intandemLogo : vcitaLogo;
    },
    poweredByTranslation() {
      return this.$hasFeature('intandem_enabled') ? this.inTandemTranslation : this.vcitaTranslation;
    },
    poweredByText() {
      return this.hasParentDirectory ? '' : this.poweredByTranslation;
    },
    poweredByData() {
      return {
        show: this.shouldDisplayPoweredBy,
        text: this.poweredByText,
        image: this.poweredByImage,
        size: this.poweredBySize,
        textColor: this.poweredByTextColor,
      };
    },
    poweredByTextColor() {
      return this.$isMobile() ? 'var(--black)' : this.textColor;
    },
    parentDirectoryPoweredData() {
      return {
        show: this.hasParentDirectory,
        text: '',
        image: this.parentPoweredByLogo,
        size: this.poweredBySize,
        textColor: this.poweredByTextColor,
      };
    },
    poweredByArray() {
      if (this.hasParentDirectory) {
        return [].concat(this.parentDirectoryPoweredData, this.poweredByData);
      }
      return [this.poweredByData];
    },
    computedParentClasses() {
      return [
        'my-3 powered-by',
        { 'dark-text': this.isParentDirectoryPoweredByDarkColored },
        { 'bright-text': !this.isParentDirectoryPoweredByDarkColored },
        { 'powered-by-mobile': this.$isMobile() }];
    },
  },
};

</script>

<style scoped lang="scss">
.powered-by {
  gap: var(--size-value1);
}
.powered-by-mobile {
  gap: var(--size-value2);
}
.dark-text{
  color: var(--black);
}
.bright-text{
  color: var(--white);
}
.powered-by-component {
  flex: 0 1 auto;

  &:last-child:not(:only-child) {
    &::before {
      content: '|';
      opacity: 0.2;
      margin-inline-end: var(--size-value1);

      .powered-by-mobile & {
        margin-inline-end: var(--size-value2);
      }
    }
  }
}

</style>
